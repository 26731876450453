import { useEffect, useMemo, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { yupResolver } from '@hookform/resolvers/yup';

import {
  ClientPortalChecks_IdDocumentCheck,
  ClientPortalChecks_Individual,
} from '@dotfile/shared/data-access-client-portal';
import { yup } from '@dotfile/shared/domain';

import { useUpdateReferenceData } from './use-update-reference-data';

export type UpdateReferenceDataFormValues = {
  firstName: string;
  lastName: string;
  birthDate: string | null;
};

/**
 * Similar to the hook with the same name in id-verification/
 */
export const useUpdateReferenceDataForm = (
  check: ClientPortalChecks_IdDocumentCheck,
  individual: ClientPortalChecks_Individual,
) => {
  const { t } = useTranslation();

  const isBirthDateRequired = !!individual.birthDate;
  const resolver = useMemo(() => {
    let birthDate = yup
      .string()
      .nullable()
      .absoluteDate()

      .label(
        t(`checks.id_document.update_reference_data.birth_date`, {
          ns: 'client-portal',
          defaultValue: 'Birth date',
        }),
      );

    if (isBirthDateRequired) {
      // Make birth date required when already set
      birthDate = birthDate.required(({ label }) =>
        t(`forms.validation.required`, {
          ns: 'client-portal',
          defaultValue: '{{label}} is a required field',
          label,
        }),
      );
    }

    return yupResolver(
      yup.object().shape({
        firstName: yup
          .string()
          .required(({ label }) =>
            t(`forms.validation.required`, {
              ns: 'client-portal',
              defaultValue: '{{label}} is a required field',
              label,
            }),
          )
          .label(
            t(`checks.id_document.update_reference_data.first_name`, {
              ns: 'client-portal',
              defaultValue: 'First name',
            }),
          ),
        lastName: yup
          .string()
          .required(({ label }) =>
            t(`forms.validation.required`, {
              ns: 'client-portal',
              defaultValue: '{{label}} is a required field',
              label,
            }),
          )
          .label(
            t(`checks.id_document.update_reference_data.last_name`, {
              ns: 'client-portal',
              defaultValue: 'Last name',
            }),
          ),
        birthDate,
      }),
    );
  }, [t, isBirthDateRequired]);

  const defaultValues: UpdateReferenceDataFormValues = {
    firstName: individual.firstName,
    lastName: individual.lastName,
    birthDate: individual.birthDate ?? null,
  };

  const methods = useForm<UpdateReferenceDataFormValues>({
    mode: 'all',
    criteriaMode: 'all',
    defaultValues,
    resolver,
  });

  const resetForm = () => {
    methods.reset(defaultValues);
  };

  const [isRightDocumentForIndividual, setIsRightDocumentForIndividual] =
    useState<'yes' | 'no' | undefined>(undefined);
  useEffect(() => {
    // Reset when check data changes like after uploading new files
    setIsRightDocumentForIndividual(undefined);
  }, [check.data.id]);

  const [updateReferenceData] = useUpdateReferenceData();
  const onSubmit: SubmitHandler<UpdateReferenceDataFormValues> = async (
    formData,
  ) => {
    const res = await updateReferenceData({ id: check.id, ...formData });

    if (res?.individual) {
      methods.reset({
        firstName: res.individual.firstName,
        lastName: res.individual.lastName,
        birthDate: res.individual.birthDate ?? null,
      });

      setIsRightDocumentForIndividual(undefined);
    }
  };

  const {
    formState: { isValid, isDirty, isSubmitting },
  } = methods;

  return {
    methods: methods,
    submitIsDisabled: !isValid || !isDirty,
    isSubmitting,
    reset: resetForm,
    handleSubmit: methods.handleSubmit(onSubmit),

    isBirthDateRequired,

    isRightDocumentForIndividual,
    setIsRightDocumentForIndividual,
  };
};
