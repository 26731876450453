import { DeepPartial } from 'ts-essentials';

import {
  CommunicationEventTypeEnum,
  WorkspaceSettingsCommunicationModel,
} from '../../../../shared/models';
import yup from '../../../../utils/yup-extended';
import {
  COMMUNICATION_MAX_REMINDER_MAX_COUNT,
  COMMUNICATION_MAX_REMINDER_PERIOD_IN_SECONDS,
  COMMUNICATION_MIN_REMINDER_MAX_COUNT,
  COMMUNICATION_MIN_REMINDER_PERIOD_IN_SECONDS,
  COMMUNICATION_SENDER_NAME_MAX_LENGTH,
  communicationSenderUserOptions,
} from '../../communication-hub';

export const communicationSettingsSchema = (): yup.SchemaOf<
  Omit<WorkspaceSettingsCommunicationModel, 'eventBatchWindowInSeconds'>
> =>
  yup
    .object()
    .required()
    .shape({
      senderName: yup
        .string()
        .optionalString()
        .max(COMMUNICATION_SENDER_NAME_MAX_LENGTH),
      senderUser: yup
        .string()
        .optionalString()
        .oneOf([null, ...communicationSenderUserOptions])
        .nullable(),
      eventTypes: yup
        .array()
        .of(yup.mixed().oneOf(Object.values(CommunicationEventTypeEnum))),
      reminderMaxCount: yup
        .number()
        .min(COMMUNICATION_MIN_REMINDER_MAX_COUNT)
        .max(COMMUNICATION_MAX_REMINDER_MAX_COUNT)
        .default(1),
      reminderPeriodInSeconds: yup
        .number()
        .min(COMMUNICATION_MIN_REMINDER_PERIOD_IN_SECONDS)
        .max(COMMUNICATION_MAX_REMINDER_PERIOD_IN_SECONDS)
        .default(3 * 60 * 60 * 24),
      customDomain: yup.object().shape({
        domainId: yup.string().nullable().defined(),
        domainName: yup.string().nullable().defined(),
        dkimHost: yup.string().nullable().defined(),
        dkimValue: yup.string().nullable().defined(),
        isDkimVerified: yup.boolean().default(false),
        returnPathDomain: yup.string().nullable().defined(),
        returnPathDomainCnameValue: yup.string().nullable().defined(),
        isReturnPathVerified: yup.boolean().default(false),
      }),
    })
    .strict();

export const validateWorkspaceSettingsCommunication = (
  communicationSettings: DeepPartial<WorkspaceSettingsCommunicationModel> = {},
): void => {
  communicationSettingsSchema().validateSync(communicationSettings, {
    abortEarly: false,
  });
};
