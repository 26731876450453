import { PropertyMappingTypeEnum } from '@dotfile/shared/data-access-client-portal';
import { PropertyTypeEnum, yup } from '@dotfile/shared/domain';

import { ValidationSchemaFn } from './types';

// @NOTE: Similar to custom property choices validation  but
// with isRequired
// handle default mapping

export const choicesValidationSchema: ValidationSchemaFn<
  typeof PropertyTypeEnum.choices
> = ({ isRequired, property, localizedValidation, mapping }) => {
  const options: (string | null)[] =
    property.settings?.options?.map((s) => s.key) ?? [];

  const allowMultiple = property.settings?.allowMultiple ?? false;

  let schema;
  if (mapping.type === PropertyMappingTypeEnum.custom) {
    schema = yup.array().uniqueItems().of(yup.string().oneOf(options));

    if (!allowMultiple) {
      // Custom single choice: only one element but still an array
      schema = schema.max(1);
    }
  }

  // mapping type default
  else if (allowMultiple) {
    schema = yup.array().uniqueItems().of(yup.string().oneOf(options));
  } else {
    schema = yup.string().oneOf(isRequired ? options : [...options, null]);
  }

  // Add required depending on settings
  if (isRequired) {
    schema = schema
      .required(localizedValidation.required)
      .min(1, localizedValidation.required)
      .typeError(localizedValidation.required);
  } else {
    schema = schema.nullable();
  }

  return schema.strict();
};
