import {
  IdVerificationDataModeEnum,
  IdVerificationVendorEnum,
} from '../../../shared/models';

export const idVerificationModeDefinition = {
  [IdVerificationDataModeEnum.liveness]: {
    label: 'VideoExpert (v1)',
    vendor: IdVerificationVendorEnum.ubble,
  },
  [IdVerificationDataModeEnum.auto]: {
    label: 'VideoAuto',
    vendor: IdVerificationVendorEnum.checkout,
  },
  [IdVerificationDataModeEnum.expert]: {
    label: 'VideoExpert (v2)',
    vendor: IdVerificationVendorEnum.checkout,
  },
  [IdVerificationDataModeEnum.certified]: {
    label: 'VideoCertified (PVID)',
    vendor: IdVerificationVendorEnum.checkout,
  },
  [IdVerificationDataModeEnum.default]: {
    label: 'Default',
    vendor: IdVerificationVendorEnum.veriff,
  },
};
