import { AlertOctagon } from 'lucide-react';
import { ReactNode } from 'react';

import {
  HStack,
  Icon,
  LucideIcon,
  Text,
  VStack,
} from '@dotfile/frontend/shared/design-system';
import {
  CheckResultEnum,
  DocumentAnalysisDetailedResultModel,
  documentAnalysisDetailedResultsDefinition,
  DocumentAnalysisEntity,
  DocumentAnalysisInformationModel,
} from '@dotfile/shared/domain';

import { ErrorBoundary } from '../../error-handler';
import { getAnalysisDetailedResults } from './get-analysis-detailed-results';
import { DocumentAnalysisData } from './types';

export type DocumentCheckAnalysisDetailedResultsProps = {
  entity: Partial<
    DocumentAnalysisEntity['company'] | DocumentAnalysisEntity['individual']
  >;
  documentData: DocumentAnalysisData;
  hideApproved?: boolean;
};

export const DocumentCheckAnalysisDetailedResults = ({
  entity,
  documentData,
  hideApproved = false,
}: DocumentCheckAnalysisDetailedResultsProps) => {
  const detailedResults = documentData?.detailedResults?.documentAnalysis;
  const information = documentData?.information?.documentAnalysis;
  const settings = documentData?.settings?.documentAnalysis;

  if (!detailedResults || !information || !settings?.parameters?.model)
    return null;

  const model = settings.parameters.model;
  const definition = getAnalysisDetailedResults(model);

  const documentAnalysisEntity = (
    'name' in (entity ?? {}) ? { company: entity } : { individual: entity }
  ) as DocumentAnalysisEntity;

  const results: {
    component: ReactNode;
    key: string;
    result: CheckResultEnum | null;
  }[] = Object.keys(definition).map((key) => {
    const k = key as keyof typeof definition;

    return {
      key,
      component:
        /**
         * We need to redefine definition function to accept
         * any DocumentAnalysisInformationModel as information.
         */
        (
          definition[k] as (data: {
            detailedResult: DocumentAnalysisDetailedResultModel;
            information: DocumentAnalysisInformationModel;
            entity: DocumentAnalysisEntity;
            dataUpdatedAt: string;
          }) => ReactNode
        )({
          detailedResult: detailedResults[k],
          information,
          dataUpdatedAt: documentData.updatedAt,
          entity: documentAnalysisEntity,
        }),
      result: detailedResults[k].result || null,
    };
  });

  return (
    <VStack width="full" gap={2}>
      {results
        .filter(
          (
            data,
          ): data is {
            component: ReactNode;
            key: string;
            result: CheckResultEnum;
          } => data.result !== null,
        )
        .filter(
          ({ result }) =>
            !(result === CheckResultEnum.approved && hideApproved),
        )
        .map(({ key, component, result }) => (
          <ErrorBoundary
            key={key}
            renderError={() => (
              <HStack key={key} width="full" px="2">
                <Icon mr="2" color="red.500" as={AlertOctagon} />
                <Text color="red.500" fontSize="sm">
                  Error rendering detailed results
                </Text>
              </HStack>
            )}
          >
            <HStack key={key} width="full" px="2">
              <LucideIcon
                boxSize={4}
                name={
                  documentAnalysisDetailedResultsDefinition[result].iconName
                }
                color={documentAnalysisDetailedResultsDefinition[result].color}
                mr="2"
              />
              {component}
            </HStack>
          </ErrorBoundary>
        ))}
    </VStack>
  );
};
