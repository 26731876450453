import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { yupResolver } from '@hookform/resolvers/yup';

import { ClientPortalChecks_Individual } from '@dotfile/shared/data-access-client-portal';
import {
  COLLABORATION_EMAIL_CUSTOM_MESSAGE_MAX_LENGTH,
  yup,
} from '@dotfile/shared/domain';

import { getLocalizedValidation } from '../utils/localize-validation';

export type InviteUserForm = {
  individualId: string | null;
  firstName: string;
  lastName: string;
  email: string;
  customMessage: string;
  _addCustomMessage: boolean;
};

export const useInviteUserForm = (
  selectedIndividual: Pick<
    ClientPortalChecks_Individual,
    'id' | 'firstName' | 'lastName' | 'email'
  > | null,
) => {
  const { t } = useTranslation();

  const defaultValues = useMemo(
    () =>
      selectedIndividual
        ? {
            individualId: selectedIndividual.id,
            firstName: selectedIndividual.firstName,
            lastName: selectedIndividual.lastName,
            email: selectedIndividual.email ?? '',
            customMessage: '',
            _addCustomMessage: false,
          }
        : {
            individualId: null,
            firstName: '',
            lastName: '',
            email: '',
            customMessage: '',
            _addCustomMessage: false,
          },
    [selectedIndividual],
  );
  const localizedValidation = getLocalizedValidation(t);
  const selectLabel = t(`collaboration.invite.select.label`, {
    ns: 'client-portal',
    defaultValue: 'Individual',
  });
  const firstNameLabel = t(`collaboration.invite.first_name.label`, {
    ns: 'client-portal',
    defaultValue: 'First name',
  });
  const lastNameLabel = t(`collaboration.invite.last_name.label`, {
    ns: 'client-portal',
    defaultValue: 'Last name',
  });
  const emailLabel = t(`collaboration.invite.email.label`, {
    ns: 'client-portal',
    defaultValue: 'Email',
  });
  const customMessageLabel = t(`collaboration.invite.custom_message.label`, {
    ns: 'client-portal',
    defaultValue: 'Custom message',
  });

  const methods = useForm<InviteUserForm>({
    defaultValues,
    mode: 'all',
    criteriaMode: 'all',
    resolver: yupResolver(
      yup.object().shape({
        individualId: yup
          .string()
          .nullable()
          .required(localizedValidation.required)
          .label(selectLabel),
        firstName: yup
          .string()
          .required(localizedValidation.required)
          .label(firstNameLabel),
        lastName: yup
          .string()
          .required(localizedValidation.required)
          .label(lastNameLabel),
        email: yup
          .string()
          .email(localizedValidation.email.invalid)
          .required(localizedValidation.required)
          .label(emailLabel),
        customMessage: yup
          .string()
          .optional()
          .max(
            COLLABORATION_EMAIL_CUSTOM_MESSAGE_MAX_LENGTH,
            localizedValidation.customMessage.max_length,
          )
          .label(customMessageLabel),

        // _addCustomMessage not validated by the schema
      }),
    ),
  });

  return {
    selectLabel,
    firstNameLabel,
    lastNameLabel,
    emailLabel,
    methods,
    defaultValues,
  };
};
