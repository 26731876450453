import { useMemo } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { yupResolver } from '@hookform/resolvers/yup';

import { yup } from '@dotfile/shared/domain';

import { useAddFiles } from './use-add-files';
import { usePollIdDocumentCheck } from './use-poll-id-document-check';

export type UploadFormValues = {
  frontUploadRef: string;
  backUploadRef: string | null;
};

export const uploadDefaultValues: UploadFormValues = {
  frontUploadRef: '',
  backUploadRef: '',
};

export const useUploadForm = (checkId: string, onClose: () => void) => {
  const { t } = useTranslation();

  const resolver = useMemo(() => {
    return yupResolver(
      yup.object().shape({
        frontUploadRef: yup
          .string()
          .required(({ label }) =>
            t(`forms.validation.required`, {
              ns: 'client-portal',
              defaultValue: '{{label}} is a required field',
              label,
            }),
          )
          .label(
            t(`checks.id_document.add_files.front`, {
              ns: 'client-portal',
              defaultValue: 'Front',
            }),
          ),
        backUploadRef: yup
          .string()
          .nullable()
          .label(
            t(`checks.id_document.add_files.back`, {
              ns: 'client-portal',
              defaultValue: 'Back',
            }),
          ),
      }),
    );
  }, [t]);

  const methods = useForm<UploadFormValues>({
    mode: 'all',
    criteriaMode: 'all',
    defaultValues: uploadDefaultValues,
    resolver,
  });

  const resetForm = () => {
    methods.reset(uploadDefaultValues);
  };

  const { start: startPolling, loading: pollingLoading } =
    usePollIdDocumentCheck(checkId, onClose);

  const [addFiles] = useAddFiles();
  const onSubmit: SubmitHandler<UploadFormValues> = async (formData) => {
    const { frontUploadRef, backUploadRef } = formData;
    const data = {
      frontUploadRef,
      backUploadRef,
    };

    const submitted = await addFiles({ id: checkId, data });
    if (submitted) {
      // Start polling ID Document check
      await startPolling();

      resetForm();
    }
  };

  const {
    formState: { isValid, isDirty, isSubmitting },
  } = methods;

  return {
    methods: methods,
    submitIsDisabled: !isValid || !isDirty,
    isSubmitting,
    reset: resetForm,
    handleSubmit: methods.handleSubmit(onSubmit),

    pollingLoading,
  };
};
