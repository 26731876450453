import { humanCase } from '@dotfile/shared/common';

import {
  CheckModel,
  CheckStatusEnum,
  CheckTypeEnum,
  EntityTypeEnum,
  ViewColumnMappingEntityEnum,
  ViewColumnMappingTypeEnum,
} from '../../../shared/models';
import { PropertyTypeEnum } from '../../../shared/properties';
import { ViewColumn } from './types';
import { ViewColumnBuilder } from './view-column-builder';

type CheckViewColumn = Omit<
  // @NOTE Also update the test snapshot when modifying the properties
  CheckModel,
  | 'workspace'
  | 'workspaceId'
  | 'individual'
  | 'individualId'
  | 'company'
  | 'companyId'
  | 'subtype'
  | 'amlData'
  | 'documentData'
  | 'idDocumentData'
  | 'idVerificationData'
  | 'createdAt'
  | 'updatedAt'
  | 'deletedAt'
> & {
  entityName: string;
  entityType: EntityTypeEnum;
};

export const checkViewColumnDefinition: Record<
  keyof CheckViewColumn,
  ViewColumn
> = new ViewColumnBuilder<CheckViewColumn>(ViewColumnMappingEntityEnum.check)
  .addViewColumn('id', PropertyTypeEnum.text, {
    canSort: false,
  })
  .addViewColumn('status', PropertyTypeEnum.choices, {
    settings: {
      allowMultiple: false,
      options: Object.values(CheckStatusEnum).map((key) => ({
        key,
        label: humanCase(key),
      })),
    },
  })
  .addViewColumn('type', PropertyTypeEnum.choices, {
    settings: {
      allowMultiple: false,
      options: Object.values(CheckTypeEnum).map((key) => ({
        key,
        label: humanCase(key),
      })),
    },
  })
  .addViewColumn('entityName', PropertyTypeEnum.text, {
    mapping: {
      type: ViewColumnMappingTypeEnum.computed,
    },
  })
  .addViewColumn('entityType', PropertyTypeEnum.choices, {
    settings: {
      allowMultiple: false,
      options: Object.values(EntityTypeEnum).map((key) => ({
        key,
        label: humanCase(key),
      })),
    },
    mapping: {
      type: ViewColumnMappingTypeEnum.computed,
    },
  })
  .addViewColumn('lastActivityAt', PropertyTypeEnum.date, {
    label: 'Last activity',
  })
  .addViewColumn('isInternal', PropertyTypeEnum.boolean, {
    label: 'Internal',
  })

  .getDefinition();

export const allCheckViewColumnDefinition = Object.values(
  checkViewColumnDefinition,
);
