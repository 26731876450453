import { PropertyTypeEnum } from '../../../shared/properties';

/**
 * Those property type are not yet supported in logic builder
 */
export const UNSUPPORTED_PROPERTY_TYPES: PropertyTypeEnum[] = [
  PropertyTypeEnum.address,
  PropertyTypeEnum.banking_information,
  PropertyTypeEnum.classifications,
  PropertyTypeEnum.unknown,
];
