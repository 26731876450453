import { TFunction } from 'i18next';

import { yup } from '@dotfile/shared/domain';

// Inspired from the yup locale structure but only with the validator used in the client-portal
// @see https://github.com/jquense/yup/blob/v0.32.11/src/locale.ts

/**
 * Parameter of the `message` parameter as a function for yup validators
 */
type MessageFnParam<
  Extra extends Record<string, unknown> = NonNullable<unknown>,
> = yup.MessageParams & Extra;

// Pass the namespace as prefix to make below function less verbose
const validationPrefix = 'forms.validation';
const datePrefix = 'forms.field.date';
const emailPrefix = 'forms.field.email';
const numericPrefix = 'forms.field.numeric';
const phoneNumberPrefix = 'forms.field.phone_number';
const urlPrefix = 'forms.field.url';
const addressPrefix = 'forms.field.address';
const bankingInformationPrefix = 'forms.field.banking_information';
const classificationsPrefix = 'forms.field.classifications';
const entityLegalFormPrefix = 'forms.field.entity_legal_form';

export const getLocalizedValidation = (t: TFunction) => ({
  required: (p: MessageFnParam) =>
    t(`${validationPrefix}.required`, {
      label: p.label,
      defaultValue: '{{label}} is a required field',
      ns: 'client-portal',
    }),
  min_length: (p: MessageFnParam & { min: number }) =>
    t(`${validationPrefix}.min_length`, {
      label: p.label,
      min: p.min,
      defaultValue: '{{label}} must be at least {{min}} characters',
      ns: 'client-portal',
    }),
  max_length: (p: MessageFnParam & { max: number }) =>
    t(`${validationPrefix}.max_length`, {
      label: p.label,
      max: p.max,
      defaultValue: '{{label}} must be at most {{max}} characters',
      ns: 'client-portal',
    }),
  not_unique: (p: MessageFnParam) =>
    t(`${validationPrefix}.not_unique`, {
      label: p.label,
      defaultValue: '{{label}} must have unique items',
      ns: 'client-portal',
    }),
  date: {
    invalid: (p: MessageFnParam) =>
      t(`${datePrefix}.invalid`, {
        label: p.label,
        defaultValue:
          '{{label}} must be a date following the pattern dd/mm/yyyy',
        ns: 'client-portal',
      }),
  },
  address: {
    street_address: () =>
      t(`${addressPrefix}.street_address.label`, {
        defaultValue: 'Street address',
        ns: 'client-portal',
      }),
    postal_code: () =>
      t(`${addressPrefix}.postal_code.label`, {
        defaultValue: 'Postal code',
        ns: 'client-portal',
      }),
    city: () =>
      t(`${addressPrefix}.city.label`, {
        defaultValue: 'City',
        ns: 'client-portal',
      }),
    country: () =>
      t(`${addressPrefix}.country.label`, {
        defaultValue: 'Country',
        ns: 'client-portal',
      }),
    state: () =>
      t(`${addressPrefix}.state.label`, {
        defaultValue: 'State',
        ns: 'client-portal',
      }),
  },
  banking_information: {
    bic: {
      label: () =>
        t(`${bankingInformationPrefix}.bic.label`, {
          defaultValue: 'BIC',
          ns: 'client-portal',
        }),
      invalid: (p: MessageFnParam) =>
        t(`${bankingInformationPrefix}.bic.invalid`, {
          label: p.label,
          defaultValue: '{{label}} is invalid',
          ns: 'client-portal',
        }),
      no_country: (p: MessageFnParam) =>
        t(`${bankingInformationPrefix}.bic.no_country`, {
          label: p.label,
          defaultValue: '{{label}} has no country',
          ns: 'client-portal',
        }),
      invalid_format: (p: MessageFnParam) =>
        t(`${bankingInformationPrefix}.bic.invalid_format`, {
          label: p.label,
          defaultValue: '{{label}} format invalid',
          ns: 'client-portal',
        }),
    },
    iban: {
      label: () =>
        t(`${bankingInformationPrefix}.iban.label`, {
          defaultValue: 'IBAN',
          ns: 'client-portal',
        }),
      invalid: (p: MessageFnParam) =>
        t(`${bankingInformationPrefix}.iban.invalid`, {
          label: p.label,
          defaultValue: '{{label}} is invalid',
          ns: 'client-portal',
        }),
      no_country: (p: MessageFnParam) =>
        t(`${bankingInformationPrefix}.iban.no_country`, {
          defaultValue: 'no country',
          ns: 'client-portal',
        }),
      invalid_length: (p: MessageFnParam) =>
        t(`${bankingInformationPrefix}.iban.invalid_length`, {
          defaultValue: 'length invalid',
          ns: 'client-portal',
        }),
      invalid_format: (p: MessageFnParam) =>
        t(`${bankingInformationPrefix}.iban.invalid_format`, {
          defaultValue: 'format invalid',
          ns: 'client-portal',
        }),
      invalid_checksum: (p: MessageFnParam) =>
        t(`${bankingInformationPrefix}.iban.invalid_checksum`, {
          defaultValue: 'checksum is not a number',
          ns: 'client-portal',
        }),
      qr_iban_not_allowed: (p: MessageFnParam) =>
        t(`${bankingInformationPrefix}.iban.qr_iban_not_allowed`, {
          defaultValue: 'QR IBAN not allowed',
          ns: 'client-portal',
        }),
    },
  },
  classifications: {
    label: () =>
      t(`${classificationsPrefix}.label`, {
        defaultValue: 'Classifications',
        ns: 'client-portal',
      }),
    min_element: (p: MessageFnParam<{ min: number }>) =>
      t(`${validationPrefix}.min_element`, {
        label: p.label,
        defaultValue: '{{label}} must have at least {{min}} element',
        min: p.min,
        ns: 'client-portal',
      }),
    type: {
      label: () =>
        t(`${classificationsPrefix}.type.label`, {
          defaultValue: 'Type',
          ns: 'client-portal',
        }),
    },
    code: {
      label: () =>
        t(`${classificationsPrefix}.code.label`, {
          defaultValue: 'Code',
          ns: 'client-portal',
        }),
    },
    description: {
      label: () =>
        t(`${classificationsPrefix}.description.label`, {
          defaultValue: 'Description',
          ns: 'client-portal',
        }),
    },
  },
  email: {
    invalid: (p: MessageFnParam) =>
      t(`${emailPrefix}.invalid`, {
        label: p.label,
        defaultValue: '{{label}} must be an email address',
        ns: 'client-portal',
      }),
  },
  entity_legal_form: {
    invalid: (p: MessageFnParam) =>
      t(`${entityLegalFormPrefix}.invalid`, {
        label: p.label,
        defaultValue:
          '{{label}} must be a valid entity legal form (ISO 20275) code',
        ns: 'client-portal',
      }),
  },
  phone_number: {
    invalid: (p: MessageFnParam) =>
      t(`${phoneNumberPrefix}.invalid`, {
        label: p.label,
        defaultValue: '{{label}} must be a phone number',
        ns: 'client-portal',
      }),
  },
  numeric: {
    number: (p: MessageFnParam) =>
      t(`${numericPrefix}.number`, {
        label: p.label,
        defaultValue: '{{label}} must be a number',
        ns: 'client-portal',
      }),
    max: (p: MessageFnParam<{ max: number }>) =>
      t(`${numericPrefix}.max`, {
        label: p.label,
        defaultValue: '{{label}} must be less than {{min}}',
        max: p.max,
        ns: 'client-portal',
      }),
    min: (p: MessageFnParam<{ min: number }>) =>
      t(`${numericPrefix}.min`, {
        label: p.label,
        defaultValue: '{{label}} must be more than {{min}}',
        min: p.min,
        ns: 'client-portal',
      }),
  },
  url: {
    invalid: (p: MessageFnParam) =>
      t(`${urlPrefix}.invalid`, {
        label: p.label,
        defaultValue: '{{label}} must be an URL',
        ns: 'client-portal',
      }),
  },
});

export type LocalizedValidation = ReturnType<typeof getLocalizedValidation>;
