export const FileTargetEnum = {
  // Main storage
  // ------------

  // User
  user_avatar: 'user_avatar',
  // Workspace
  workspace_logo: 'workspace_logo',

  // ClientPortalSetting
  client_portal_logo: 'client_portal_logo',
  client_portal_favicon: 'client_portal_favicon',
  client_portal_block_pdf: 'client_portal_block_pdf',
  client_portal_block_image: 'client_portal_block_image',
  client_portal_translation: 'client_portal_translation',

  // Document storage
  // -----------------

  // IdVerificationData
  id_verification_data_report: 'id_verification_data_report',
  id_verification_data_front: 'id_verification_data_front',
  id_verification_data_back: 'id_verification_data_back',
  id_verification_data_face: 'id_verification_data_face',
  id_verification_data_signature: 'id_verification_data_signature',
  // IdDocumentData
  id_document_data_front: 'id_document_data_front',
  id_document_data_back: 'id_document_data_back',
  // DocumentData
  document_data_file: 'document_data_file',
  // DocumentOrder
  document_order_file: 'document_order_file',
  // Import
  import_file: 'import_file',
  // Case report
  case_report_file: 'case_report_file',
  // Communication Hub
  communication_attachment: 'communication_attachment',
} as const;
export type FileTargetEnum =
  (typeof FileTargetEnum)[keyof typeof FileTargetEnum];
