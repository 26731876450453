import { useFormContext } from 'react-hook-form';

import { EntityLegalFormField } from '@dotfile/frontend/shared/components';
import { PropertyTypeEnum } from '@dotfile/shared/data-access-client-portal';
import { companyDefaultPropertiesDefinition } from '@dotfile/shared/domain';

import { mainCompanySelector, useFormDatastore } from '../../../context';
import { generateFieldFormName } from '../../../utils';
import { FieldProps } from './types';

export const FieldEntityLegalForm = ({
  field,
  property,
  wrapperProps,

  ...groupControlProps
}: FieldProps<typeof PropertyTypeEnum.entity_legal_form>) => {
  const { watch } = useFormContext();
  const mainCompany = useFormDatastore(mainCompanySelector);

  const formCountryKey = generateFieldFormName(
    companyDefaultPropertiesDefinition.country,
  );
  // @NOTE Look at country in the current form or mainCompany.country on the datastore if the EntityLegalFormField is not on the same step as the country
  const country = watch(formCountryKey) || mainCompany?.country;

  // @NOTE Unlike classification, the fallback to the country from the main company in the data store is not necessary here because
  // the EntityLegalFormField will be disabled when there is no company right

  // @TODO - E-4192 - Country subdivision / Entity legal form
  return (
    <EntityLegalFormField
      country={country ?? ''}
      {...groupControlProps}
      {...wrapperProps}
    />
  );
};
