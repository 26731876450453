import { match } from 'ts-pattern';

import {
  NumberInput as ChakraNumberInput,
  NumberInputField as ChakraNumberInputField,
  NumberInputProps as ChakraNumberInputProps,
} from '@chakra-ui/react';

import { Tooltip } from '../../overlay/tooltip/tooltip';
import { forwardRef } from '../../utils/component-factory';
import { InputGroup, InputRightElement } from '../input/input';
import { NumberInputStepper } from './number-input-stepper';
import {
  useNumberInputValue,
  UseNumberInputValueParam,
} from './use-number-input-value';
import { useTranslatedCurrency } from './use-translated-currency';

// @NOTE Chakra apply the isValidCharacter function on the slitted input string, character by character so it's not possible to validate that it start by a sign or that a dot is at most once in the string
// Same as chakra default implementation except that it does not allow exponent character (e and E)
const validCharacterRegex = /^[0-9+\-.]$/;
const isValidCharacter = (value: string) => validCharacterRegex.test(value);

// Same as above except it doesn't allow the character '.' to not allow decimal
const validCharacterIntegerOnlyRegex = /^[0-9+-]$/;
const isValidCharacterIntegerOnly = (value: string) =>
  validCharacterIntegerOnlyRegex.test(value);

export type NumberInputProps = Omit<
  ChakraNumberInputProps,
  'format' | 'onChange' | 'value'
> & {
  /**
   * Add an input right element with
   * - `number`: Increment/decrement stepper
   * - `'percent'`: A percent icon
   * - `'currency'`: The currency symbol with translated name in tooltip from the `currencyCode` props
   */
  format?: 'number' | 'percent' | 'currency';

  /**
   * 3 letter ISO4217 currency code
   * @see https://en.wikipedia.org/wiki/ISO_4217#List_of_ISO_4217_currency_codes
   */
  currencyCode?: string | null;

  /**
   * Prevent decimal value by disallowing to type the character `.`
   */
  // @NOTE Charka has a props `precision` but it only works with clampValueOnBlur and not change events
  integerOnly?: boolean;
} & UseNumberInputValueParam;

export const NumberInput = forwardRef(
  (
    { format, currencyCode, integerOnly, ...props }: NumberInputProps,
    ref,
  ): JSX.Element => {
    const currency = useTranslatedCurrency(currencyCode);

    const [value, handleChange] = useNumberInputValue(props);

    return (
      <InputGroup size={props.size} variant={props.variant}>
        <ChakraNumberInput
          // We use validation when value is out of min/max range or with unspecified precision
          // @see https://v2.chakra-ui.com/docs/components/number-input/usage#clamp-value-when-user-blurs-the-input
          clampValueOnBlur={false}
          isValidCharacter={
            integerOnly ? isValidCharacterIntegerOnly : isValidCharacter
          }
          width="100%"
          {...props}
          onChange={handleChange}
          value={value}
        >
          <ChakraNumberInputField fontSize="14px" color="gray.700" ref={ref} />

          {match(format)
            .with('number', () => (
              <InputRightElement>
                <NumberInputStepper />
              </InputRightElement>
            ))
            .with('percent', () => <InputRightElement>%</InputRightElement>)
            .with('currency', () => {
              if (!currency) {
                return null;
              }

              return (
                <InputRightElement>
                  <Tooltip label={currency.name}>
                    {currency.symbol ?? currencyCode}
                  </Tooltip>
                </InputRightElement>
              );
            })
            .otherwise(() => null)}
        </ChakraNumberInput>
      </InputGroup>
    );
  },
);
