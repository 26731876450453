import * as jsonLogic from 'json-logic-js';

// arrayContains, doesNotArrayContain
const arrayContains = (arr1: unknown, arr2: unknown) => {
  if (!Array.isArray(arr1) || !Array.isArray(arr2)) return false;
  if (!arr1 || !arr2) return false;
  if (arr1 === arr2) return true;
  return arr1.some((item: unknown) => arr2.includes(item));
};
jsonLogic.add_operation('arrayContains', arrayContains);
jsonLogic.add_operation(
  'doesNotArrayContain',
  (arr1, arr2) => !arrayContains(arr1, arr2),
);

// arrayContainsAll, doesNotArrayContainAll
const arrayContainsAll = (arr1: unknown, arr2: unknown) => {
  if (!Array.isArray(arr1) || !Array.isArray(arr2)) return false;
  if (!arr1 || !arr2) return false;
  if (arr1 === arr2) return true;
  return arr1.every((item: unknown) => arr2.includes(item));
};
jsonLogic.add_operation('arrayContainsAll', arrayContainsAll);
jsonLogic.add_operation(
  'doesNotArrayContainAll',
  (arr1, arr2) => !arrayContainsAll(arr1, arr2),
);

const arrayContainsExactly = (arr1: unknown, arr2: unknown) => {
  if (!arr1 || !arr2) return false;
  if (!Array.isArray(arr1) || !Array.isArray(arr2)) return false;
  if (arr1 === arr2) return true;
  if (arr1.length !== arr2.length) return false;
  return arr1.every((item: unknown) => arr2.includes(item));
};
jsonLogic.add_operation('arrayContainsExactly', arrayContainsExactly);

export default jsonLogic;
