import { CompanyDataVendorEnum } from '../../../../shared/models';
import { CountryCoverage } from '../country-coverage.interface';
import { COMPANIES_HOUSE_COUNTRIES } from './companies-house-countries.constant';
import { CREDITSAFE_COUNTRIES } from './creditsafe-countries.constant';
import { KOMPANY_COUNTRIES } from './kompany-countries.constant';
import { KYCKR_COUNTRIES } from './kyckr-countries.constant';
import { PAPPERS_COUNTRIES } from './pappers-countries.constant';

export const COUNTRY_COVERAGE: Record<
  CompanyDataVendorEnum,
  CountryCoverage[]
> = {
  [CompanyDataVendorEnum.pappers]: PAPPERS_COUNTRIES,
  [CompanyDataVendorEnum.companies_house]: COMPANIES_HOUSE_COUNTRIES,
  [CompanyDataVendorEnum.kompany]: KOMPANY_COUNTRIES,
  [CompanyDataVendorEnum.creditsafe]: CREDITSAFE_COUNTRIES,
  // @NOTE: only return available country having company profile
  [CompanyDataVendorEnum.kyckr]: KYCKR_COUNTRIES.filter((c) => c.priceTier),
  // @NOTE Kyckr is not here because it's not a full company data vendor (only document order so far)
  // @TODO - - Kyckr as Company data vendor - https://www.notion.so/dotfile/Kyckr-as-Company-data-vendor-36216f87e0774701943e35433909ba64
};

// Vendor countries coverage constants
export * from './pappers-countries.constant';
export * from './creditsafe-countries.constant';
export * from './kompany-countries.constant';
export * from './kyckr-countries.constant';
export * from './companies-house-countries.constant';
