import { useTranslation } from 'react-i18next';

import { HStack, Text } from '@dotfile/frontend/shared/design-system';
import { ClientPortalChecks_CheckInterface } from '@dotfile/shared/data-access-client-portal';

import { formatCheckTitle } from '../utils';
import { CheckStatusBadge } from './check-status-badge';

export const CheckModalHeader = ({
  check,
}: {
  check: ClientPortalChecks_CheckInterface;
}) => {
  const { t } = useTranslation();
  return (
    <HStack justifyContent="space-between">
      <Text color="black" fontSize="lg">
        {formatCheckTitle(check, t)}
      </Text>
      <CheckStatusBadge check={check} />
    </HStack>
  );
};
