import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Button,
  Divider,
  HStack,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Text,
  Tooltip,
  VStack,
} from '@dotfile/frontend/shared/design-system';
import { ClientPortalChecks_Individual } from '@dotfile/shared/data-access-client-portal';
import { ClientPortalCollaborationEnum } from '@dotfile/shared/domain';

import { useLatestClientPortalVersion } from '../../../../shared';
import { useCaseChecks } from '../../shared';
import { BusinessContactItem } from './business-contact-item';

export const BusinessContactListModalContent = ({
  onInviteUser,
  onEmailUser,
  onClose,
}: {
  onEmailUser: (
    businessContact: Pick<
      ClientPortalChecks_Individual,
      'id' | 'firstName' | 'lastName' | 'roles' | 'email' | 'isBeneficialOwner'
    >,
  ) => void;
  onInviteUser: () => void;
  onClose: () => void;
}) => {
  const { t } = useTranslation();

  const { data: latestVersionData } = useLatestClientPortalVersion();
  const { data } = useCaseChecks();

  const { businessContacts, nonBusinessContacts } = useMemo(
    () =>
      data?.case?.individuals.reduce<{
        businessContacts: ClientPortalChecks_Individual[];
        nonBusinessContacts: ClientPortalChecks_Individual[];
      }>(
        (acc, individual) => {
          if (individual.isBusinessContact) {
            return {
              ...acc,
              businessContacts: [...acc.businessContacts, individual],
            };
          }
          if (individual.isDelegator) {
            return acc;
          }
          return {
            ...acc,
            nonBusinessContacts: [...acc.nonBusinessContacts, individual],
          };
        },
        {
          businessContacts: [],
          nonBusinessContacts: [],
        },
      ) ?? {
        businessContacts: [],
        nonBusinessContacts: [],
      },
    [data],
  );
  const hasNonBusinessContactInCase = nonBusinessContacts.length > 0;
  const canUpdateExistingOnly =
    latestVersionData?.latestClientPortalVersion.setting.collaboration ===
    ClientPortalCollaborationEnum.existing_only;

  return (
    <ModalContent>
      <ModalHeader>
        {t('collaboration.access_verification_portal', {
          defaultValue: 'Access to the verification portal',
          ns: 'client-portal',
        })}
      </ModalHeader>
      <ModalBody>
        <VStack divider={<Divider />} align="start" spacing="2">
          <HStack justifyContent="space-between" width="full" px="4">
            <Text textTransform="uppercase">
              {businessContacts.length}{' '}
              {t('collaboration.people', {
                defaultValue: 'people',
                ns: 'client-portal',
                count: businessContacts.length,
              })}
            </Text>
            <Tooltip
              isDisabled={hasNonBusinessContactInCase || !canUpdateExistingOnly}
              label={t('collaboration.all_user_invited', {
                defaultValue: 'All listed individuals has been invited',
                ns: 'client-portal',
              })}
            >
              <Button
                isDisabled={
                  !hasNonBusinessContactInCase && canUpdateExistingOnly
                }
                onClick={onInviteUser}
              >
                {t('collaboration.invite_user', {
                  defaultValue: 'Invite user',
                  ns: 'client-portal',
                })}
              </Button>
            </Tooltip>
          </HStack>
          {businessContacts.map((businessContact) => (
            <BusinessContactItem
              key={businessContact.id}
              businessContact={businessContact}
              onEmailUser={() => onEmailUser(businessContact)}
            />
          ))}
        </VStack>
      </ModalBody>
      <ModalFooter>
        <Button variant="ghost" onClick={onClose}>
          {t('common.close', {
            defaultValue: 'Close',
            ns: 'client-portal',
          })}
        </Button>
      </ModalFooter>
    </ModalContent>
  );
};
