import fetch from 'cross-fetch';
import { GraphQLFormattedError } from 'graphql';
import { SubscriptionClient } from 'subscriptions-transport-ws';

import {
  ApolloClient,
  ApolloLink,
  from,
  fromPromise,
  HttpLink,
  HttpOptions,
  InMemoryCache,
  InMemoryCacheConfig,
  NormalizedCacheObject,
  Observable,
  ServerError,
  split,
} from '@apollo/client';
import { NetworkError } from '@apollo/client/errors';
import { onError } from '@apollo/client/link/error';
import { RetryLink } from '@apollo/client/link/retry';
import { WebSocketLink } from '@apollo/client/link/ws';
import { getMainDefinition } from '@apollo/client/utilities';

import { logAndAddError } from '../datadog';
import { AuthTokenService, BaseAuthToken } from '../features/auth';

export interface GraphQLClientOptions {
  environment: {
    production: boolean;
    baseAPI: string;
    subscriptionAPI?: string;
  };
  cacheConfig?: InMemoryCacheConfig;
  httpOptions?: HttpOptions;
  onRefreshAuthFailed?: (error: unknown) => Promise<void> | void;
  onNetworkServerError?: (error: ServerError) => void;
  onGraphQLError?: (
    apolloClient: ApolloClient<NormalizedCacheObject>,
    error: GraphQLFormattedError,
  ) => void;
}

export class GraphQLClient {
  wsClient?: SubscriptionClient;
  apolloClient: ApolloClient<NormalizedCacheObject>;

  workspaceId: string | null = null;
  clientPortalId: string | null = null;

  authToken: AuthTokenService<BaseAuthToken> | null = null;
  tokenPromise: Promise<BaseAuthToken | null> | null = null;

  onRefreshAuthFailed = (error: unknown): void => {
    logAndAddError(error);
  };
  onNetworkServerError = (serverError: ServerError): void => {
    const error = new Error(
      `NetworkError with status ${serverError.statusCode} operation: ${serverError.message}`,
    );
    error.cause = serverError;
    logAndAddError(error);
  };
  onGraphQLError = (
    apolloClient: ApolloClient<NormalizedCacheObject>,
    graphQLError: GraphQLFormattedError,
  ): void => {
    const error = new Error(`GraphQLError operation: ${graphQLError.message}`);
    error.cause = graphQLError;
    logAndAddError(error);
  };

  constructor(private readonly options: GraphQLClientOptions) {
    // Init AccessToken
    const authLink = this.authMiddleware();

    const httpLink = this.createHttpLink(options.httpOptions);
    let mainLinks: ApolloLink = httpLink;
    if (options.environment.subscriptionAPI) {
      this.wsClient = this.createSubscriptionClient();
      const wsLink = new WebSocketLink(this.wsClient);
      mainLinks = split(
        ({ query }) => {
          const definition = getMainDefinition(query);
          return (
            definition.kind === 'OperationDefinition' &&
            definition.operation === 'subscription'
          );
        },
        wsLink,
        httpLink,
      );
    }

    const errorLink = this.errorMiddleware();
    const retryLink = new RetryLink({
      attempts: {
        retryIf: (error, operation) => {
          const definition = operation.query.definitions[0];
          const isQuery =
            definition.kind === 'OperationDefinition' &&
            definition.operation === 'query';

          // Retrying mutation could lead to it being processed multiple times,
          // so we want to make sure we only retry queries.
          if (!isQuery) return false;

          return !!error;
        },
      },
    });

    this.apolloClient = new ApolloClient({
      link: from([errorLink, authLink, retryLink, mainLinks]),
      connectToDevTools: !this.options.environment.production,
      cache: new InMemoryCache(this.options.cacheConfig),
    });

    if (options.onRefreshAuthFailed) {
      this.onRefreshAuthFailed = options.onRefreshAuthFailed;
    }
    if (options.onNetworkServerError) {
      this.onNetworkServerError = options.onNetworkServerError;
    }
    if (options.onGraphQLError) {
      this.onGraphQLError = options.onGraphQLError;
    }
  }

  public setWorkspaceId(workspaceId: string | null): void {
    const prevWorkspaceId = this.workspaceId;

    this.workspaceId = workspaceId;

    if (prevWorkspaceId !== workspaceId) {
      this.resetCache();
    }
  }

  public setClientPortalId(clientPortalId: string | null): void {
    const prevClientPortalId = this.clientPortalId;

    this.clientPortalId = clientPortalId;

    if (prevClientPortalId !== clientPortalId) {
      this.resetCache();
    }
  }

  public setAuthToken(authToken: AuthTokenService<BaseAuthToken>): void {
    this.authToken = authToken;
  }

  public resetCache(): void {
    this.apolloClient.cache.reset();
  }

  public clear(): void {
    this.setWorkspaceId(null);
    this.setClientPortalId(null);
    this.resetCache();
  }

  public closeWsConnection(): void {
    if (!this.wsClient) {
      console.warn('No wsClient configured!');
      return;
    }
    this.wsClient.close(true);
    // @TODO - E-45 - Find a better way for this reconnect issue
    // Consider reconnect after the close for the use-case of Subscription event and
    // you're idle on the App but you sill want to receive updates from other users
    // Otherwise, it will reconnect only when a new request will be triggered (reconnect: true)
  }

  private createHttpLink(httpOptions?: HttpOptions) {
    return new HttpLink({
      uri: `${this.options.environment.baseAPI}/graphql`,
      fetch,
      ...httpOptions,
    });
  }

  private authMiddleware() {
    return new ApolloLink((operation, forward) => {
      if (this.workspaceId) {
        operation.setContext(({ headers = {} }) => ({
          headers: {
            ...headers,
            dotfile_workspace: this.workspaceId,
          },
        }));
      }

      if (this.clientPortalId) {
        operation.setContext(({ headers = {} }) => ({
          headers: {
            ...headers,
            dotfile_client_portal: this.clientPortalId,
          },
        }));
      }

      if (this.authToken?.getAccessToken()) {
        operation.setContext(({ headers = {} }) => ({
          headers: {
            ...headers,
            authorization: `Bearer ${this.authToken?.getAccessToken()}`,
          },
        }));
      }

      // This log is very useful to debug race condition with graphql queries
      // console.debug(
      //   `forward operation ${operation.operationName} for workspace '${this.workspaceId}'`
      // );
      return forward(operation);
    });
  }

  private createSubscriptionClient() {
    return new SubscriptionClient(
      `${this.options.environment.subscriptionAPI}/graphql`,
      {
        lazy: true,
        reconnect: true,
        connectionParams: () => {
          const accessToken = this.authToken?.getAccessToken();
          const workspaceId = this.workspaceId;
          const clientPortalId = this.clientPortalId;

          return {
            headers: {
              get authorization() {
                // Auth for Connect only
                return accessToken ? `Bearer ${accessToken}` : '';
              },
              get dotfile_workspace() {
                return workspaceId || '';
              },
              get dotfile_client_portal() {
                return clientPortalId || '';
              },
            },
          };
        },
      },
    );
  }

  private errorMiddleware() {
    // @TODO - E-69 - better handle of error
    return onError(({ graphQLErrors, networkError, operation, forward }) => {
      if (graphQLErrors) {
        for (const graphQLError of graphQLErrors) {
          // User access token has expired
          if (graphQLError.message.startsWith('Unauthorized')) {
            if (!this.authToken || !this.authToken.authState()) {
              // user is not authenticated, no point in refreshing the auth token
              this.onRefreshAuthFailed(graphQLError);
              return;
            }

            if (!this.tokenPromise) {
              // Solution on https://stackoverflow.com/a/69675721
              this.tokenPromise = this.authToken
                .refreshAccessToken()
                // eslint-disable-next-line promise/prefer-await-to-then
                .catch((error) => {
                  this.authToken?.clearAuthToken();
                  this.onRefreshAuthFailed(error);
                  // By returning null here, we can decide later in the
                  // returned observable to not forward the operation
                  return null;
                })
                // eslint-disable-next-line promise/prefer-await-to-then
                .finally(() => {
                  this.tokenPromise = null;
                });
            }

            return fromPromise(this.tokenPromise).flatMap((tokenOrNull) => {
              if (tokenOrNull == null) {
                // Return an empty observable to avoid retrying
                // the operation if we could re-auth the user.
                // The onRefreshAuthFailed callback is responsible for
                // what happens in this case
                return Observable.of();
              }
              return forward(operation);
            });
          } else {
            this.onGraphQLError(this.apolloClient, graphQLError);
          }
        }
      }

      if (networkError) {
        if (isServerError(networkError)) {
          this.onNetworkServerError(networkError);
        } else {
          const error = new Error(
            `NetworkError operation: ${networkError.message}`,
          );
          error.cause = networkError;
          logAndAddError(error);
        }
      }

      return;
    });
  }
}

function isServerError(
  networkError: NetworkError,
): networkError is ServerError {
  return !!networkError && networkError.name === 'ServerError';
}
