import { snakeCase } from 'lodash';
import { DeepPartial, StrictDeepPick } from 'ts-essentials';

import { humanCase } from '@dotfile/shared/common';

import {
  PropertyMappingEntityEnum,
  PropertyMappingTypeEnum,
} from '../../shared/models';
import { Property, PropertyTypeEnum } from '../../shared/properties';

/**
 * Build the properties definition for an entity
 *
 * It has a strong typing:
 * - the name of the properties depends on the model as first parameter
 * - the settings type will depends on the property type
 * - the definition will have a type with all the name of the property added (can `satisfy` a specific interface or subset of the model )
 */
export class DefaultPropertiesBuilder<
  TModel,
  TPropertyNames extends string = never,
> {
  private readonly properties: Record<string, Property<PropertyTypeEnum>> = {};

  constructor(private readonly entity: PropertyMappingEntityEnum) {}

  /**
   * Add a property on the definition
   * @param name Property name on the Typescript model
   * @param type Type
   * @param property.label Override label (default is human-case from the property name)
   * @param property.mapping.key Override mapping key (default is snake-case from the property name)
   * @param property.alwaysRequired Optionally mark this property as always required
   * @param property.settings Optional settings depending on the `type`
   *
   * @returns
   */
  public addProperty<
    const TName extends Exclude<keyof TModel & string, TPropertyNames>,
    TType extends PropertyTypeEnum,
  >(
    name: TName,
    type: TType,
    property?: DeepPartial<
      StrictDeepPick<
        Property<TType>,
        { label: true; mapping: { key: true }; alwaysRequired: true }
      >
    > &
      Pick<Property<TType>, 'settings'>,
  ): DefaultPropertiesBuilder<TModel, TPropertyNames | TName> {
    const key = property?.mapping?.key ?? snakeCase(name);
    const label = property?.label ?? humanCase(key);

    this.properties[name] = {
      type,
      mapping: {
        entity: this.entity,
        type: PropertyMappingTypeEnum.default,
        key,
      },
      label,

      alwaysRequired: property?.alwaysRequired ?? false,
    };

    if (property?.settings) {
      this.properties[name].settings = property.settings;
    }

    return this as DefaultPropertiesBuilder<TModel, TPropertyNames | TName>;
  }

  public getDefinition(): Record<TPropertyNames, Property<PropertyTypeEnum>> {
    return this.properties;
  }
}
