import { VendorEnum } from '../../../shared/models';

export const vendorDefinition: Record<
  VendorEnum,
  {
    name: string;
    // @NOTE: For now, logoSrc is hosted in assets folder of console-app.
    // This is not ideal and may be changed in a forseeable future
    // @TODO - E-2912 - Store generic assets in https://assets.dotfile.com
    logoSrc: string | undefined;
  }
> = {
  [VendorEnum.comply_advantage]: {
    name: 'ComplyAdvantage',
    logoSrc: '/assets/comply-advantage.png',
  },
  [VendorEnum.ubble]: {
    name: 'Ubble',
    logoSrc: '/assets/ubble.jpg',
  },
  [VendorEnum.checkout]: {
    name: 'Checkout',
    logoSrc: '/assets/checkout.png',
  },
  [VendorEnum.veriff]: {
    name: 'Veriff',
    logoSrc: '/assets/veriff.svg',
  },
  [VendorEnum.kompany]: {
    name: 'Kompany',
    logoSrc: '/assets/kompany.png',
  },
  [VendorEnum.pappers]: {
    name: 'Pappers',
    logoSrc: '/assets/pappers.png',
  },
  [VendorEnum.kyckr]: {
    name: 'Kyckr',
    logoSrc: '/assets/kyckr.png',
  },
  [VendorEnum.creditsafe]: {
    name: 'Creditsafe',
    logoSrc: '/assets/creditsafe.svg',
  },
  [VendorEnum.open_ai]: {
    name: 'OpenAI',
    logoSrc: undefined,
  },
  [VendorEnum.mistral_ai]: {
    name: 'MistralAI',
    logoSrc: undefined,
  },
  [VendorEnum.companies_house]: {
    name: 'Companies House',
    logoSrc: '/assets/companies-house.png',
  },
  [VendorEnum.postmark]: {
    name: 'Postmark',
    logoSrc: undefined,
  },
};
