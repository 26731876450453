/**
 * @doc https://www.notion.so/Permissions-tables-023baaeda4744d2487b31103894c3502
 */
export const PermissionActionEnum = {
  read: 'read',
  create: 'create',
  update: 'update',
  delete: 'delete',
  run_template: 'run_template',
  update_template: 'update_template',
  update_risk_level: 'update_risk_level',
  update_status: 'update_status',
  update_assignee: 'update_assignee',
  update_metadata: 'update_metadata',
  share_link: 'share_link',
  confirm_periodic_review: 'confirm_periodic_review',
  download_documents: 'download_documents',
  review: 'review',
  review_hit: 'review_hit',
  update_monitoring: 'update_monitoring',
  update_expiration_date: 'update_expiration_date',
  add_files: 'add_files',
  // Company data vendor
  purchase_creditsafe: 'purchase_creditsafe',
  purchase_kompany: 'purchase_kompany',
  purchase_pappers: 'purchase_pappers',
  purchase_kyckr: 'purchase_kyckr',
  purchase_companies_house: 'purchase_companies_house',
  // Communication
  send: 'send',
} as const;
export type PermissionActionEnum =
  (typeof PermissionActionEnum)[keyof typeof PermissionActionEnum];
