import { AlertCircleIcon, EditIcon, TrashIcon } from 'lucide-react';
import { Trans, useTranslation } from 'react-i18next';

import {
  Button,
  DeleteDialog,
  forwardRef,
  HStack,
  Icon,
  Stack,
  StackProps,
  Text,
  useDisclosure,
  useIsSmallScreen,
  VStack,
} from '@dotfile/frontend/shared/design-system';
import { CaseRelationRoleEnum } from '@dotfile/shared/data-access-client-portal';

import {
  BeneficialOwnerPill,
  BusinessContactPill,
  LegalRepresentativePill,
  ShareholderPill,
} from '../../../shared';
import { FormDatastoreIndividual, useFormDatastore } from '../shared';

export type IndividualItemProps = {
  index: number;
  individual: FormDatastoreIndividual;
  hasMissingInformation: boolean;
  onEdit: () => void;
} & StackProps;

export const IndividualItem = forwardRef(
  (
    {
      hasMissingInformation,
      index,
      individual,
      onEdit,
      ...stackProps
    }: IndividualItemProps,
    ref,
  ) => {
    const { t } = useTranslation();
    const isSmallScreen = useIsSmallScreen();

    const removeDisclosure = useDisclosure();
    const { deleteIndividualData } = useFormDatastore();

    const isBeneficialOwner = individual.isBeneficialOwner;
    const isLegalRepresentative = individual.roles?.includes(
      CaseRelationRoleEnum.legal_representative,
    );
    const isBusinessContact = individual.isBusinessContact;
    const isExistingBusinessContact =
      individual.isBusinessContact && individual.id;
    const isShareholder = individual.roles?.includes(
      CaseRelationRoleEnum.shareholder,
    );
    // Delegator is not displayed here because it is selected in a following step

    const individualFullName = [individual.firstName, individual.lastName]
      .filter(Boolean)
      .join(' ');

    return (
      <Stack
        flexDirection={isSmallScreen ? 'column' : 'row'}
        alignItems={isSmallScreen ? 'stretch' : 'center'}
        justifyContent="space-between"
        p="4"
        spacing="4"
        borderRadius="lg"
        borderWidth="1px"
        borderColor="gray.200"
        ref={ref}
        {...stackProps}
      >
        <VStack alignItems="start">
          <HStack>
            <Text color="black">{individualFullName}</Text>
            {hasMissingInformation && (
              <>
                <Icon color="orange.700" as={AlertCircleIcon} />
                <Text color="orange.700">
                  {t('forms.missing_information', {
                    ns: 'client-portal',
                    defaultValue: 'Missing information',
                  })}
                </Text>
              </>
            )}
          </HStack>
          {(isBeneficialOwner ||
            isLegalRepresentative ||
            isBusinessContact ||
            isShareholder) && (
            <HStack>
              {isBeneficialOwner && <BeneficialOwnerPill />}
              {isLegalRepresentative && <LegalRepresentativePill />}
              {isBusinessContact && <BusinessContactPill />}
              {isShareholder && <ShareholderPill />}
            </HStack>
          )}
        </VStack>

        <HStack spacing="4">
          <Button
            leftIcon={<Icon as={EditIcon} />}
            colorScheme="coal"
            variant="ghost"
            onClick={onEdit}
          >
            {t('common.edit', {
              ns: 'client-portal',
              defaultValue: 'Edit',
            })}
          </Button>

          {!isExistingBusinessContact && (
            <Button
              leftIcon={<Icon as={TrashIcon} />}
              colorScheme="red"
              variant="ghost"
              onClick={removeDisclosure.onOpen}
            >
              {t('common.remove', {
                ns: 'client-portal',
                defaultValue: 'Remove',
              })}
            </Button>
          )}
          <DeleteDialog
            {...removeDisclosure}
            closeOnOverlayClick={false}
            onDelete={async () => {
              deleteIndividualData(index);
              removeDisclosure.onClose();
            }}
            noCloseCross
            variant="dialog-white"
            header={t('forms.individuals_edit.remove_dialog.title', {
              ns: 'client-portal',
              defaultValue: 'Remove individual',
            })}
            cancelText={t('forms.dialog.remove.cancel', {
              ns: 'client-portal',
              defaultValue: 'No, keep it',
            })}
            deleteText={t('forms.dialog.remove.confirm', {
              ns: 'client-portal',
              defaultValue: 'Yes, remove it',
            })}
            questionText={t('forms.dialog.remove.label', {
              ns: 'client-portal',
              defaultValue: 'Do you confirm the remove?',
            })}
          >
            <Trans
              i18nKey="forms.individuals_edit.remove_dialog.body"
              ns="client-portal"
              defaults="You are about to remove <b>{{label}}</b>"
              tOptions={{
                label: individualFullName,
              }}
            />
          </DeleteDialog>
        </HStack>
      </Stack>
    );
  },
);
