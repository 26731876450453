// Use yup-extended to have countryCode2
import { isValidAbsoluteDate } from '@dotfile/shared/common';

import {
  CaseRelationRoleEnum,
  IndividualModel,
} from '../../../../shared/models';
import yup from '../../../../utils/yup-extended';
import { addressSchema } from '../../address';
import { bankingInformationSchema } from '../../banking-information';
import { caseRelationSchema } from '../../case-relation';

export const individualSchema = ({
  omitCaseId,
  percentageNoValueLabel = 'null',
}: {
  omitCaseId?: boolean;

  // This is not optimal and should be improved
  // @TODO - E-1343 - Yup umbrella issue
  /**
   * Allow to change the wording for no value for ownership percentage
   * - `'null'` for API
   * - `'empty'` for UI
   */
  percentageNoValueLabel?: 'null' | 'empty';
} = {}): yup.SchemaOf<
  Omit<
    IndividualModel,
    | 'id'
    | 'createdAt'
    | 'updatedAt'
    | 'deletedAt'
    | 'lastActivityAt'
    | 'isRelevant'
    | 'case'
    | 'checks'
    | 'customPropertyValues'
    | 'relations'
    | 'workspace'
    | 'workspaceId'
    | 'caseId' // omitted because it can be optional in the validation schema
  >
> => {
  const caseId = omitCaseId
    ? yup.string() // no constraint on uuid otherwise there is an error when it's empty
    : yup.string().required().uuid();

  return yup
    .object({
      caseId,
      isBeneficialOwner: yup.boolean().default(false).required(),
      isBusinessContact: yup.boolean().default(false).required(),
      isDelegator: yup.boolean().default(false).required(),
      roles: yup
        .array(yup.mixed().oneOf(Object.values(CaseRelationRoleEnum)))
        .strict()
        .optional()
        .default([]),
      firstName: yup.string().required(),
      middleName: yup.string().optionalString(),
      lastName: yup.string().required(),
      maidenName: yup.string().optionalString(),
      email: yup
        .string()
        .optionalString()
        .email()
        .when('isBusinessContact', {
          is: true,
          then: (schema) =>
            schema
              .required('email is required when individual is business contact')
              .nullable(false)
              .typeError(
                'email is required when individual is business contact',
              ),
        }),
      address: addressSchema(),
      birthDate: yup
        .string()
        .optionalString()
        .absoluteDate()
        .test(
          'min-birth-date',
          'The birth date must be strictly after 1899-12-31',
          (value) => {
            if (value == null) return true;
            // It is the responsibility of previous tests in the chain to check the format
            // For this check, we only validate the birth date condition is the format
            // is valid.
            if (!isValidAbsoluteDate(value)) return true;
            const [year] = value.split('-');
            return Number(year) >= 1900;
          },
        ),
      birthPlace: yup.string().optionalString(),
      birthCountry: yup.string().optionalString().countryCode2(),
      bankingInformation: bankingInformationSchema(),
      taxIdentificationNumber: yup.string().optionalString(),
      socialSecurityNumber: yup.string().optionalString(),
      position: yup.string().optionalString(),
      phoneNumber: yup.string().optionalString().phoneNumber(),
      ownershipPercentage: yup
        .number()
        .strict()
        .typeError(
          `ownershipPercentage must be a number or ${percentageNoValueLabel}`,
        )
        .min(0)
        .max(100)
        .optional()
        .nullable()
        .default(null),
      votingRightsPercentage: yup
        .number()
        .strict()
        .typeError(
          `votingRightsPercentage must be a number or ${percentageNoValueLabel}`,
        )
        .min(0)
        .max(100)
        .optional()
        .nullable()
        .default(null),
      relations: yup
        .array(
          caseRelationSchema({ percentageNoValueLabel })
            .pick([
              'roles',
              'position',
              'ownershipPercentage',
              'votingRightsPercentage',
            ])
            .concat(
              yup.object({
                sourceCompanyId: yup.string().uuid().optional(),
              }),
            ),
        )
        .default([])
        .when('$withCompany', {
          is: true,
          then: (schema) => schema.optional(),
          otherwise: (schema) => schema.max(0),
        })
        .optional(),
    })
    .label('individual')
    .test(
      'is-not-both-business-contact-and-delegator',
      '${path} cannot be both business contact and delegator',
      (value) => {
        return !value.isBusinessContact || !value.isDelegator;
      },
    )
    .noUnknown()
    .defined();
};

// TODO - E-3828 - to remove and update schema
export const individualSchemaOptionalCustomProperty = yup.object({
  customProperties: yup.object().optional(),
});
