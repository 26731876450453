import { toDate } from 'date-fns-tz';
import parsePhoneNumber from 'libphonenumber-js';
import * as yup from 'yup';
import Lazy from 'yup/lib/Lazy';
import { AssertsShape, ObjectShape, TypeOfShape } from 'yup/lib/object';
import { AnyObject, Maybe, Optionals } from 'yup/lib/types';
import { Asserts, TypeOf } from 'yup/lib/util/types';

import {
  ABSOLUTE_DATE_FORMAT,
  isValidAbsoluteDate,
} from '@dotfile/shared/common';

import { COUNTRIES } from '../shared/country';
import { getEntityLegalFormByCode } from '../shared/entity-legal-form';

// string countryCode2 ---------------------------------------------------------------
const COUNTRY_CODE_2_ERROR_MESSAGE =
  '${path} must be a valid ISO31661 Alpha2 code';
function isValidCountryCode(value: string) {
  return (
    value?.length === 2 &&
    COUNTRIES.some((country) => country.code === value.toUpperCase())
  );
}

yup.addMethod<yup.StringSchema>(
  yup.string,
  'countryCode2',
  function countryCode2(message?: yup.Message) {
    return this.test(
      'countryCode2',
      message ?? COUNTRY_CODE_2_ERROR_MESSAGE,
      (value) => {
        if (
          typeof value === 'undefined' ||
          value === null
          // empty string are considered invalid for countryCode2
        ) {
          // required / nullable is not the responsibility of countryCode2
          // so always consider it valid
          return true;
        }

        return !!value && isValidCountryCode(value);
      },
    );
  },
);

// string absoluteDate ---------------------------------------------------------------
const ABSOLUTE_DATE_ERROR_MESSAGE = `\${path} must be a \`date\` following the pattern \`${ABSOLUTE_DATE_FORMAT}\``;
yup.addMethod<yup.StringSchema>(
  yup.string,
  'absoluteDate',
  function absoluteDate(message?: yup.Message) {
    return this.test(
      'absoluteDate',
      message ?? ABSOLUTE_DATE_ERROR_MESSAGE,
      (value) => {
        if (
          typeof value === 'undefined' ||
          value === null
          // empty string are considered invalid for absoluteDate
        ) {
          // required / nullable is not the responsibility of absoluteDate
          // so always consider it valid
          return true;
        }

        return !!value && isValidAbsoluteDate(value);
      },
    );
  },
);

// string phoneNumber ---------------------------------------------------------------
const PHONE_NUMBER_ERROR_MESSAGE = '${path} must be a valid E.164 phone number';
yup.addMethod<yup.StringSchema>(
  yup.string,
  'phoneNumber',
  function phoneNumber(message?: yup.Message) {
    return this.test(
      'phoneNumber',
      message ?? PHONE_NUMBER_ERROR_MESSAGE,
      (value) => {
        if (typeof value === 'undefined' || value === null || value === '') {
          // required / nullable is not the responsibility of phoneNumber
          // so always consider it valid
          return true;
        }

        const parsed = parsePhoneNumber(value ?? '');

        return !!parsed && !!value && parsed.isValid();
      },
    );
  },
);

// string isUnique ---------------------------------------------------------------
const IS_UNIQUE_ERROR_MESSAGE = '${path} must be unique';
yup.addMethod<yup.StringSchema>(
  yup.string,
  'isUnique',
  function isUnique(
    checkUniquenessFn: (value: string) => Promise<boolean>,
    message?: yup.Message,
  ) {
    return this.test(
      'isUnique',
      message ?? IS_UNIQUE_ERROR_MESSAGE,
      async (value) => {
        if (typeof value === 'undefined' || value === null || value === '') {
          // required / nullable is not the responsibility of isUnique
          // so always consider it valid
          return true;
        }

        const unique = await checkUniquenessFn(value);

        return unique;
      },
    );
  },
);

// number isUnique ---------------------------------------------------------------
yup.addMethod<yup.NumberSchema>(
  yup.number,
  'isUnique',
  function isUnique(
    checkUniquenessFn: (value: number) => Promise<boolean>,
    message?: yup.Message,
  ) {
    return this.test(
      'isUnique',
      message ?? IS_UNIQUE_ERROR_MESSAGE,
      async (value) => {
        if (typeof value === 'undefined' || value === null) {
          return true;
        }

        const unique = await checkUniquenessFn(value);

        return unique;
      },
    );
  },
);

// string isoDateTime ---------------------------------------------------------------
// @TODO - E-3320 - Yup upgrade to v1
// Might be possible to replace with native datetime (added in yup v1)
// https://github.com/jquense/yup?tab=readme-ov-file#stringdatetimeoptions-message-string--function-allowoffset-boolean-precision-number
const ISO_DATE_TIME_ERROR_MESSAGE =
  '${path} must be a valid date time in format ISO 8601 (`yyyy-MM-ddTHH:mm:ss.S+X`)';
//
/**
 * Use a regex to validate that the string match the format of an ISO 8601 like `yyyy-MM-ddTHH:mm:ss.S+X` with
 * - optional timezone
 * - optional millisecond
 * - allow space as separator (see https://www.rfc-editor.org/rfc/rfc3339#section-5.6)
 */
const isoDateFormatRegex =
  /^\d{4}-\d{2}-\d{2}(T| )\d{2}:\d{2}:\d{2}(\.\d{1,6})?(([+-]\d\d:\d\d)|Z)?$/i;
function isValidIsoDateTime(value: string) {
  try {
    return (
      // First validate the overall format
      isoDateFormatRegex.test(value) &&
      // toDate will parse the date and convert to UTC
      // @see https://www.npmjs.com/package/date-fns-tz#todate
      toDate(value).toISOString() === new Date(value).toISOString()
    );
  } catch {
    return false;
  }
}

// string isoDateTime ---------------------------------------------------------------
yup.addMethod<yup.StringSchema>(
  yup.string,
  'isoDateTime',
  function isoDateTime(message?: yup.Message) {
    return this.test(
      'isoDateTime',
      message ?? ISO_DATE_TIME_ERROR_MESSAGE,
      (value) => {
        if (
          typeof value === 'undefined' ||
          value === null
          // empty string are considered invalid for isoDateTime
        ) {
          // required / nullable is not the responsibility of isoDateTime
          // so always consider it valid
          return true;
        }

        return !!value && isValidIsoDateTime(value);
      },
    );
  },
);

// array uniqueItems ---------------------------------------------------------------
const UNIQUE_ITEMS_ERROR_MESSAGE = '${path} must have unique items';
yup.addMethod(
  yup.array,
  'uniqueItems',
  function (mapper = (a: unknown) => a, message = UNIQUE_ITEMS_ERROR_MESSAGE) {
    return this.test('uniqueItems', message, (value) => {
      if (typeof value === 'undefined' || value === null) {
        return true;
      }

      return (
        !!value &&
        value.map(mapper).filter((v) => v !== undefined).length ===
          new Set(value.map(mapper).filter((v) => v !== undefined)).size
      );
    });
  },
);

// string optionalString ---------------------------------------------------------------
yup.addMethod<yup.StringSchema<string | undefined | null>>(
  yup.string,
  'optionalString',
  function optionalString() {
    return this.transform((value) =>
      typeof value === 'string' && value.trim() === ''
        ? null
        : typeof value === 'string'
          ? value.trim()
          : null,
    ).nullable();
  },
);

// object noExtraProperty ---------------------------------------------------------------
// @NOTE since the noUnknown is not apply if the schema is not strict, we have make our own implementation of this
// @see https://github.com/jquense/yup?tab=readme-ov-file#objectnounknownonlyknownkeys-boolean--true-message-string--function-schema
const NO_EXTRA_PROPERTY_ERROR_MESSAGE =
  '${path} field has unspecified keys: ${extraProps}';
yup.addMethod(
  yup.object,
  'noExtraProperty',
  function (message = NO_EXTRA_PROPERTY_ERROR_MESSAGE) {
    return this.test('noExtraProperty', message, (value, ctx) => {
      if (typeof value === 'undefined' || value === null) {
        return true;
      }

      const definedProps = Object.keys(this.fields);
      const valueProps = Object.keys(value);

      const extraProps = valueProps.filter((k) => !definedProps.includes(k));

      return extraProps.length > 0
        ? ctx.createError({
            message,
            params: { extraProps: extraProps.join(', ') },
          })
        : true;
    });
  },
);

// object exactlyOneOfProperties ---------------------------------------------------------------
const EXACTLY_ONE_OF_PROPERTIES_ERROR_MESSAGE =
  '${path} must have a value for exactly one of these properties: ${properties}';
yup.addMethod<yup.AnyObjectSchema>(
  yup.object,
  'exactlyOneOfProperties',
  function (
    properties: string[],
    message: string = EXACTLY_ONE_OF_PROPERTIES_ERROR_MESSAGE,
  ) {
    return this.test({
      name: 'exactlyOneOfProperties',
      message,
      exclusive: true,
      params: { properties: properties.join(', ') },
      test: (value) =>
        value == null ||
        properties.map((f) => value[f]).filter((val) => !!val).length === 1,
    });
  },
);

// string hexColor ---------------------------------------------------------------
const HEXADECIMAL_COLOR_ERROR_MESSAGE =
  '${path} must be a valid hexadecimal color';
yup.addMethod<yup.StringSchema>(
  yup.string,
  'hexColor',
  function hexColor(message?: yup.Message) {
    return this.test(
      'hexColor',
      message ?? HEXADECIMAL_COLOR_ERROR_MESSAGE,
      (value) => {
        if (typeof value === 'undefined' || value === null) {
          return true;
        }

        const hexColorRegexp = /^#([0-9a-f]{3,4}|[0-9a-f]{6}|[0-9a-f]{8})$/i;
        return hexColorRegexp.test(value);
      },
    );
  },
);

// string entityLegalForm ---------------------------------------------------------------
const ENTITY_LEGAL_FORM_ERROR_MESSAGE =
  '${path} must be a valid entity legal form (ISO 20275) code';
yup.addMethod<yup.StringSchema>(
  yup.string,
  'entityLegalForm',
  function entityLegalForm(countryName = 'country', message?: yup.Message) {
    // @TODO - E-4192 - Country subdivision / Entity legal form
    return this.when([countryName], (country, schema) => {
      return schema.test(
        'entityLegalForm',
        message ?? ENTITY_LEGAL_FORM_ERROR_MESSAGE,
        (value: string | null) => {
          if (!value || getEntityLegalFormByCode(value, country)) {
            return true;
          }

          return false;
        },
      );
    });
  },
);

// Yup extend Typing ----------------------------------------------------------
// yup typing has a lot of any
/* eslint-disable @typescript-eslint/no-explicit-any */
declare module 'yup' {
  // Add methods --------------------------------------------------------------
  interface StringSchema<
    TType extends Maybe<string> = string | undefined,
    TContext extends AnyObject = AnyObject,
    TOut extends TType = TType,
  > extends yup.BaseSchema<TType, TContext, TOut> {
    countryCode2(message?: Message): StringSchema<TType, TContext>;
    absoluteDate(message?: Message): StringSchema<TType, TContext>;
    phoneNumber(message?: Message): StringSchema<TType, TContext>;
    optionalString(message?: Message): StringSchema<string | null, TContext>;
    isUnique(
      checkUniquenessFn: (value: string) => Promise<boolean>,
      message?: Message,
    ): StringSchema<TType, TContext>;
    isoDateTime(message?: Message): StringSchema<TType, TContext>;
    hexColor(message?: Message): StringSchema<TType, TContext>;
    entityLegalForm(
      countryName?: string,
      message?: Message,
    ): StringSchema<TType, TContext>;
  }

  interface ArraySchema<
    T extends yup.AnySchema | Lazy<any, any>,
    C extends AnyObject = AnyObject,
    TIn extends Maybe<TypeOf<T>[]> = TypeOf<T>[] | undefined,
    TOut extends Maybe<Asserts<T>[]> = Asserts<T>[] | Optionals<TIn>,
  > extends yup.BaseSchema<TIn, C, TOut> {
    uniqueItems(
      mapper?: (v: TypeOf<T>) => unknown,
      message?: Message,
    ): ArraySchema<T, C, TIn, TOut>;
  }

  interface ObjectSchema<
    TShape extends ObjectShape,
    TContext extends AnyObject = AnyObject,
    TIn extends Maybe<TypeOfShape<TShape>> = TypeOfShape<TShape>,
    TOut extends Maybe<AssertsShape<TShape>> =
      | AssertsShape<TShape>
      | Optionals<TIn>,
  > extends yup.BaseSchema<TIn, TContext, TOut> {
    noExtraProperty(
      message?: Message,
    ): ObjectSchema<TShape, TContext, TIn, TOut>;
    exactlyOneOfProperties(
      properties: string[],
      message?: Message,
    ): ObjectSchema<TShape, TContext, TIn, TOut>;
  }

  interface NumberSchema<
    TType extends Maybe<number> = number | undefined,
    TContext extends AnyObject = AnyObject,
    TOut extends TType = TType,
  > extends yup.BaseSchema<TType, TContext, TOut> {
    isUnique(
      checkUniquenessFn: (value: number) => Promise<boolean>,
      message?: Message,
    ): NumberSchema<TType, TContext>;
  }

  // Add typing not exported by yup for message -------------------------------
  type SchemaSpec<TDefault> = {
    coerce: boolean;
    nullable: boolean;
    optional: boolean;
    default?: TDefault | (() => TDefault);
    abortEarly?: boolean;
    strip?: boolean;
    strict?: boolean;
    recursive?: boolean;
    label?: string | undefined;
    meta?: any;
  };
  interface MessageParams {
    path: string;
    value: any;
    originalValue: any;
    label: string;
    type: string;
  }

  type Message<Extra extends Record<string, unknown> = any> =
    | string
    | ((params: Extra & MessageParams) => unknown)
    | Record<PropertyKey, unknown>;
}
/* eslint-enable @typescript-eslint/no-explicit-any */

export default yup;
