import { isAxiosError } from 'axios';
import {
  createContext,
  PropsWithChildren,
  useContext,
  useEffect,
  useMemo,
} from 'react';

import { datadogRum } from '@datadog/browser-rum';
import { useSuspenseQuery } from '@tanstack/react-query';

import {
  useEnvironmentContext,
  useGraphQLClient,
  useHttpClient,
} from '@dotfile/frontend/shared/common';

import { extractMaybeClientPortalId } from './helpers';

const ClientPortalIdContext = createContext<string | null | undefined>(
  undefined,
);

export const useClientPortalId = () => {
  const context = useContext(ClientPortalIdContext);
  if (context === undefined) {
    throw new Error(
      `value for ClientPortalIdContext was not initialized. Make sure the ClientPortalIdProvider is set up.`,
    );
  }

  return context;
};

export const ClientPortalIdProvider = ({ children }: PropsWithChildren) => {
  const isDefaultDomain =
    useEnvironmentContext().baseApp === window.location.origin;

  const clientPortalIdFromUrl = useMemo(() => {
    const { pathname } = window.location;
    return extractMaybeClientPortalId(pathname);
  }, []);

  const httpClient = useHttpClient();
  const { data } = useSuspenseQuery({
    queryKey: ['resolve-client-portal'],
    queryFn: async () => {
      if (isDefaultDomain) {
        return { clientPortalId: null };
      }

      try {
        return (
          await httpClient.get<{ clientPortalId: string }>(
            'custom-domains/resolve',
          )
        ).data;
      } catch (error) {
        if (isAxiosError(error) && error.status === 404) {
          // Avoid handling not found portal (for instance because of offline) in error boundary
          return { clientPortalId: null };
        } else {
          throw error;
        }
      }
    },
    staleTime: Infinity,
  });

  const clientPortalId = clientPortalIdFromUrl ?? data.clientPortalId;

  useEffect(() => {
    // Set client portal id in Datadog context when available
    // @see https://docs.datadoghq.com/real_user_monitoring/browser/advanced_configuration/?tab=npm#global-context
    if (clientPortalId) {
      datadogRum.setGlobalContextProperty('client-portal-id', clientPortalId);
    }
  }, [clientPortalId]);

  return (
    <ClientPortalIdContext.Provider value={clientPortalId}>
      {children}
    </ClientPortalIdContext.Provider>
  );
};

export const SyncClientPortalId = ({ children }: PropsWithChildren) => {
  const clientPortalId = useClientPortalId();

  // sync directly in the clients
  useGraphQLClient().setClientPortalId(clientPortalId);
  useHttpClient().setClientPortalId(clientPortalId);

  return children;
};
