import {
  FormDatastoreCompany,
  FormDatastoreIndividual,
} from '../../../context';

/**
 * Validate that the data store contains all the required data to allow to save for later and create
 * case, companies and individuals:
 * - all individuals must have a first and last name OR only one individual (for KYC)
 * - all companies must have a name
 *
 * @returns
 */
export const canSaveForLater = ({
  individuals,
  companies,
}: Partial<{
  individuals: FormDatastoreIndividual[];
  companies: FormDatastoreCompany[];
}>): boolean => {
  if (individuals && individuals.some((i) => !i.firstName || !i.lastName)) {
    return false;
  }

  if (companies && companies.some((c) => !c.name)) {
    return false;
  }

  return true;
};
