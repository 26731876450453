import { TFunction } from 'i18next';

import { yup } from '@dotfile/shared/domain';

// Inspired from the yup locale structure but only with the validator used in the client-portal
// @see https://github.com/jquense/yup/blob/v0.32.11/src/locale.ts

/**
 * Parameter of the `message` parameter as a function for yup validators
 */
type MessageFnParam<
  Extra extends Record<string, unknown> = NonNullable<unknown>,
> = yup.MessageParams & Extra;

// Pass the namespace as prefix to make below function less verbose
const validationPrefix = 'collaboration.invite.validation';
const emailPrefix = 'collaboration.invite.email';
const customMessagePrefix = 'collaboration.invite.custom_message';

export const getLocalizedValidation = (t: TFunction) => ({
  required: (p: MessageFnParam) =>
    t(`${validationPrefix}.required`, {
      label: p.label,
      defaultValue: '{{label}} is a required field',
      ns: 'client-portal',
    }),
  email: {
    invalid: (p: MessageFnParam) =>
      t(`${emailPrefix}.invalid`, {
        label: p.label,
        defaultValue: '{{label}} must be an email address',
        ns: 'client-portal',
      }),
  },
  customMessage: {
    max_length: (p: MessageFnParam & { max: number }) =>
      t(`${customMessagePrefix}.too_long`, {
        label: p.label,
        // This do not indicate the exact limit since it is computed including the html tag so does not match
        // the character actually typed
        defaultValue: '{{label}} is too long',
        ns: 'client-portal',
      }),
  },
});

export type LocalizedValidation = ReturnType<typeof getLocalizedValidation>;
