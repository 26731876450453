import {
  IdVerificationDataModeEnum,
  IdVerificationVendorEnum,
} from '../../../../shared/models';
import yup from '../../../../utils/yup-extended';
import { defaultIdVerificationSettings } from '../default-settings';
import { IdVerificationCheckInput } from '../id-verification-check-input';
import { idVerificationVendorDefinition } from '../id-verification-vendor-definition';

export const idVerificationSettingsSchema = (): yup.SchemaOf<
  IdVerificationCheckInput['settings']
> => {
  return yup
    .object({
      vendor: yup
        .mixed()
        .when('$legacyUbble', {
          is: (value: boolean) => !value,
          then: (schema) => schema.oneOf([IdVerificationVendorEnum.checkout]),
          otherwise: (schema) =>
            schema.oneOf(Object.values(IdVerificationVendorEnum)),
        })
        .default(IdVerificationVendorEnum.checkout),
      mode: yup
        .mixed()
        .when('vendor', (vendor: IdVerificationVendorEnum, schema) =>
          vendor
            ? schema.oneOf(
                idVerificationVendorDefinition[vendor]?.availableModes ?? [],
              )
            : schema,
        )
        .default(IdVerificationDataModeEnum.expert),
      automaticApproval: yup
        .boolean()
        .default(defaultIdVerificationSettings.automaticApproval),
      automaticRejection: yup
        .boolean()
        .default(defaultIdVerificationSettings.automaticRejection),
      redirectUrl: yup
        .string()
        .url()
        .default(defaultIdVerificationSettings.redirectUrl),
    })
    .noUnknown()
    .defined();
};
