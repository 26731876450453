import { format, isValid, parse } from 'date-fns';

export const ABSOLUTE_DATE_FORMAT = 'yyyy-MM-dd';
const REFERENCE_DATE = new Date(0, 0, 0, 0, 0, 0, 0);

export function isValidAbsoluteDate(str: string): boolean {
  try {
    return isValid(parseAbsoluteDate(str));
  } catch (err) {
    return false;
  }
}

export function parseAbsoluteDate(str: string): Date {
  const date = parse(str, ABSOLUTE_DATE_FORMAT, REFERENCE_DATE);
  return date;
}

export function stringifyAbsoluteDate(date: Date): string {
  return format(date, ABSOLUTE_DATE_FORMAT);
}
