import {
  validateBIC,
  validateIBAN,
  ValidationErrorsBIC,
  ValidationErrorsIBAN,
} from 'ibantools';
import { get } from 'lodash';

import { normalize } from '@dotfile/shared/common';
import { PropertyTypeEnum, yup } from '@dotfile/shared/domain';

import { ValidationSchemaFn } from './types';

const ibanErrorsDefinition: Record<
  Exclude<ValidationErrorsIBAN, ValidationErrorsIBAN.NoIBANProvided>,
  | 'no_country'
  | 'invalid_length'
  | 'invalid_format'
  | 'invalid_checksum'
  | 'qr_iban_not_allowed'
> = {
  [ValidationErrorsIBAN.NoIBANCountry]: 'no_country',
  [ValidationErrorsIBAN.WrongBBANLength]: 'invalid_length',
  [ValidationErrorsIBAN.WrongBBANFormat]: 'invalid_format',
  [ValidationErrorsIBAN.ChecksumNotNumber]: 'invalid_checksum',
  [ValidationErrorsIBAN.WrongIBANChecksum]: 'invalid_checksum',
  [ValidationErrorsIBAN.WrongAccountBankBranchChecksum]: 'invalid_checksum',
  [ValidationErrorsIBAN.QRIBANNotAllowed]: 'qr_iban_not_allowed',
};

const bicErrorsDefinition: Record<
  Exclude<ValidationErrorsBIC, ValidationErrorsBIC.NoBICProvided>,
  'no_country' | 'invalid_format'
> = {
  [ValidationErrorsBIC.NoBICCountry]: 'no_country',
  [ValidationErrorsBIC.WrongBICFormat]: 'invalid_format',
};

export const bankingInformationValidationSchema: ValidationSchemaFn<
  typeof PropertyTypeEnum.banking_information
> = ({ isRequired, localizedValidation }) => {
  const bic = yup
    .string()
    .transform(
      (value) =>
        value && normalize(value, { removeWhiteSpace: true }).toUpperCase(),
    )
    .optionalString()
    .min(8, localizedValidation.min_length)
    .max(12, localizedValidation.max_length)
    .test('BIC', function (value) {
      const { createError, path } = this;
      const { valid, errorCodes } = validateBIC(value ?? '');

      return (
        !value ||
        valid ||
        createError({
          path,
          message: (p) =>
            `${localizedValidation.banking_information.bic.invalid(
              p,
            )}: ${errorCodes
              .map((error) => get(bicErrorsDefinition, error))
              .filter(Boolean)
              .map((errorDefinition) => {
                return localizedValidation.banking_information.bic[
                  errorDefinition
                ](p);
              })
              .join(', ')}`,
        })
      );
    })
    .label(localizedValidation.banking_information.bic.label());

  const iban = yup
    .string()
    .transform(
      (value) =>
        value && normalize(value, { removeWhiteSpace: true }).toUpperCase(),
    )
    .optionalString()
    .min(15, localizedValidation.min_length)
    .max(34, localizedValidation.max_length)
    .test('IBAN', function (value, context) {
      const { valid, errorCodes } = validateIBAN(value ?? '');

      return (
        !value ||
        valid ||
        context.createError({
          path: context.path,
          message: (p) =>
            `${localizedValidation.banking_information.iban.invalid(p)}: ${[
              ...new Set(
                errorCodes
                  .map((error) => get(ibanErrorsDefinition, error))
                  .filter(Boolean),
              ),
            ]
              .map((errorDefinition) => {
                return localizedValidation.banking_information.iban[
                  errorDefinition
                ](p);
              })
              .join(', ')}`,
        })
      );
    })
    .label(localizedValidation.banking_information.iban.label());

  const schema = yup
    .object({
      bic: isRequired
        ? bic.required(localizedValidation.required)
        : bic.nullable(),
      iban: isRequired
        ? iban.required(localizedValidation.required)
        : iban.nullable(),
    })
    .defined();

  return schema;
};
