import { Plus } from 'lucide-react';
import { useFieldArray, useFormContext } from 'react-hook-form';

import {
  Button,
  Grid,
  Icon,
  useIsMobile,
  VStack,
} from '@dotfile/frontend/shared/design-system';

import { CompanyClassificationField } from './company-classification-field';
import { CompanyClassificationLabels } from './types';

export const CompanyClassificationsField = ({
  name,
  country,
  labels = {},
}: {
  name: string;
  country?: string;
  labels?: Partial<CompanyClassificationLabels>;
}) => {
  const isMobile = useIsMobile();
  const { control } = useFormContext();
  const {
    fields: fieldArray,
    remove,
    append,
    update,
  } = useFieldArray({
    control,
    name,
  });

  return (
    <VStack align="flex-start" width="full" spacing="4">
      {fieldArray?.length ? (
        <Grid
          width="full"
          rowGap="2"
          columnGap="4"
          alignItems="bottom"
          gridTemplateColumns={isMobile ? '70% auto' : '25% 25% 40% auto'}
        >
          {fieldArray?.map(({ id }, index) => (
            <CompanyClassificationField
              key={id}
              name={name}
              remove={remove}
              update={update}
              index={index}
              country={country}
              labels={{
                type: 'Type',
                code: 'Code',
                description: 'Description',
                delete: 'Delete',
                notUnique: 'Not unique',
                ...labels,
              }}
            />
          ))}
        </Grid>
      ) : null}

      <Button
        onClick={() => {
          append({ type: '', code: '' }, { shouldFocus: false });
        }}
        variant="ghost"
        leftIcon={<Icon as={Plus} />}
      >
        Add classification
      </Button>
    </VStack>
  );
};
