import { IndividualModel } from '../../../../shared/models';

export const fullNameHelper = (
  individual: Partial<
    Pick<
      IndividualModel,
      'firstName' | 'middleName' | 'lastName' | 'maidenName'
    >
  >,
): string => {
  return `${individual.firstName} ${
    individual.middleName ? `${individual.middleName} ` : ''
  }${individual.lastName}${
    individual.maidenName ? ` (born ${individual.maidenName})` : ''
  }`;
};
