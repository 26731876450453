import {
  CheckResultEnum,
  ClientPortalChecks_IdDocumentCheck,
} from '@dotfile/shared/data-access-client-portal';

import {
  CheckCard,
  CheckDescription,
  CheckHeading,
  CheckReview,
  PendingStatuses,
  UploadButton,
} from '../shared';
import { useCheckModalWithQueryParam } from '../shared/hooks/use-modal-with-query-param';
import { DataComparisonAlert } from './components';

type IdDocumentCheckProps = {
  check: ClientPortalChecks_IdDocumentCheck;
};

export const IdDocumentCheck = ({ check }: IdDocumentCheckProps) => {
  const { onOpen } = useCheckModalWithQueryParam(check.id);

  const showDataComparisonAlert =
    check.data.detailedResults.document.dataComparison ===
      CheckResultEnum.rejected && PendingStatuses.includes(check.status);

  return (
    <CheckCard
      heading={<CheckHeading check={check} />}
      description={<CheckDescription check={check} />}
      action={<UploadButton check={check} onClick={onOpen} />}
      review={
        <>
          {showDataComparisonAlert && <DataComparisonAlert />}

          <CheckReview check={check} />
        </>
      }
    />
  );
};
