import { humanCase } from '@dotfile/shared/common';

import {
  CompanyModel,
  CompanyTypeEnum,
  ViewColumnMappingEntityEnum,
  ViewColumnMappingTypeEnum,
} from '../../../shared/models';
import '../../../shared/properties';
import {
  companyDefaultPropertiesDefinition,
  PropertyTypeEnum,
} from '../../../shared/properties';
import { ViewColumn } from './types';
import { ViewColumnBuilder } from './view-column-builder';

type CompanyViewColumn = Omit<
  // @NOTE Also update the test snapshot when modifying the properties
  CompanyModel,
  | 'caseId'
  | 'case'
  | 'workspaceId'
  | 'workspace'
  | 'vendorIds'
  | 'checks'
  | 'documentOrders'
  | 'vendorData'
  | 'customPropertyValues'
  | 'relations'
  | 'address'
  | 'classifications'
  | 'bankingInformation'
  | 'createdAt'
  | 'updatedAt'
  | 'checks'
  | 'deletedAt'
> & {
  checks: unknown;
};

export const companyViewColumnDefinition: Record<
  keyof CompanyViewColumn,
  ViewColumn
> = new ViewColumnBuilder<CompanyViewColumn>(
  ViewColumnMappingEntityEnum.company,
)
  .addViewColumn('id', PropertyTypeEnum.text, {
    canSort: false,
  })
  .addViewColumn('isRelevant', PropertyTypeEnum.boolean, {
    label: 'Relevant',
  })
  .addViewColumn('lastActivityAt', PropertyTypeEnum.date, {
    label: 'Last activity',
  })
  .addViewColumn('name', companyDefaultPropertiesDefinition.name)
  .addViewColumn(
    'commercialName',
    companyDefaultPropertiesDefinition.commercialName,
  )
  .addViewColumn(
    'registrationNumber',
    companyDefaultPropertiesDefinition.registrationNumber,
  )
  .addViewColumn(
    'registrationDate',
    companyDefaultPropertiesDefinition.registrationDate,
  )
  .addViewColumn('type', PropertyTypeEnum.choices, {
    settings: {
      allowMultiple: false,
      options: Object.values(CompanyTypeEnum).map((key) => ({
        key,
        label: humanCase(key),
      })),
    },
  })
  .addViewColumn('status', companyDefaultPropertiesDefinition.status)
  .addViewColumn('country', companyDefaultPropertiesDefinition.country)

  .addViewColumn('legalForm', companyDefaultPropertiesDefinition.legalForm)
  .addViewColumn(
    'entityLegalForm',
    companyDefaultPropertiesDefinition.entityLegalForm,
  )
  .addViewColumn(
    'taxIdentificationNumber',
    companyDefaultPropertiesDefinition.taxIdentificationNumber,
  )
  .addViewColumn(
    'employerIdentificationNumber',
    companyDefaultPropertiesDefinition.employerIdentificationNumber,
  )
  .addViewColumn(
    'shareCapital',
    companyDefaultPropertiesDefinition.shareCapital,
  )
  .addViewColumn('websiteUrl', companyDefaultPropertiesDefinition.websiteUrl)
  .addViewColumn('checks', PropertyTypeEnum.unknown, {
    mapping: {
      type: ViewColumnMappingTypeEnum.relation,
    },
  })

  .getDefinition();

export const allCompanyViewColumnDefinition = Object.values(
  companyViewColumnDefinition,
);
