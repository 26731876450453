import {
  IdVerificationDataModeEnum,
  IdVerificationVendorEnum,
} from '../../../shared/models';

export const idVerificationVendorDefinition = {
  [IdVerificationVendorEnum.ubble]: {
    label: 'Ubble',
    availableModes: [IdVerificationDataModeEnum.liveness],
  },
  [IdVerificationVendorEnum.checkout]: {
    label: 'Checkout',
    availableModes: [
      IdVerificationDataModeEnum.auto,
      IdVerificationDataModeEnum.expert,
      IdVerificationDataModeEnum.certified,
    ],
  },
  [IdVerificationVendorEnum.veriff]: {
    label: 'Veriff',
    availableModes: [IdVerificationDataModeEnum.default],
  },
};
