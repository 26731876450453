import { useFormContext } from 'react-hook-form';

import { GroupController } from '@dotfile/frontend/shared/components';
import { AbsoluteDatePicker } from '@dotfile/frontend/shared/design-system';
import { PropertyTypeEnum } from '@dotfile/shared/data-access-client-portal';

import { FieldProps } from './types';

export const FieldDate = ({
  field,
  property,

  wrapperProps,
  ...groupControlProps
}: FieldProps<typeof PropertyTypeEnum.date>) => {
  const { control } = useFormContext();

  return (
    <GroupController
      control={control}
      render={(field) => <AbsoluteDatePicker width="100%" {...field} />}
      {...groupControlProps}
      {...wrapperProps}
    />
  );
};
