import { useCallback } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { GroupController } from '@dotfile/frontend/shared/components';
import {
  CountrySelect,
  SelectOnChangeValue,
} from '@dotfile/frontend/shared/design-system';
import {
  PropertyMappingTypeEnum,
  PropertyTypeEnum,
} from '@dotfile/shared/data-access-client-portal';

import { FieldProps } from './types';

export const FieldCountries = ({
  field,
  property,

  wrapperProps,
  ...groupControlProps
}: FieldProps<typeof PropertyTypeEnum.countries>) => {
  const { control, setValue } = useFormContext();
  const { t } = useTranslation();

  const isMulti = property.settings?.allowMultiple ?? false;
  const shouldValueBeAnArray =
    isMulti || field.mapping.type === PropertyMappingTypeEnum.custom;

  const handleChange = useCallback(
    (option: SelectOnChangeValue) => {
      const newValues =
        option === null || (Array.isArray(option) && option.length === 0)
          ? null
          : shouldValueBeAnArray
            ? (Array.isArray(option) ? option : [option]).map((o) => o.value)
            : (Array.isArray(option) ? option[0] : option).value;

      setValue(groupControlProps.name, newValues, {
        shouldDirty: true,
        shouldValidate: true,
        shouldTouch: true,
      });
    },
    [groupControlProps.name, setValue, shouldValueBeAnArray],
  );

  return (
    <GroupController
      control={control}
      render={({ value, ...f }) => (
        <CountrySelect
          {...f}
          onChange={handleChange}
          defaultValue={!value ? null : value}
          isMulti={isMulti}
          placeholder={t('forms.field.countries.placeholder', {
            ns: 'client-portal',
            defaultValue: 'Select ...',
          })}
          required={false}
        />
      )}
      {...groupControlProps}
      {...wrapperProps}
    />
  );
};
