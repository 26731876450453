import { uniqBy } from 'lodash';

import {
  PermissionActionEnum,
  PermissionResourceEnum,
} from '../../../shared/models';
import type {
  BasePermissionDefinition,
  PermissionCategoryDefinition,
} from './type';

export const permissionMatrix: PermissionCategoryDefinition[] = [
  {
    label: 'Case',
    permissions: [
      {
        action: PermissionActionEnum.create,
        resource: PermissionResourceEnum.case,
        label: 'Can create',
      },
      {
        action: PermissionActionEnum.update,
        resource: PermissionResourceEnum.case,
        label: 'Can edit information',
      },
      {
        action: PermissionActionEnum.update_status,
        resource: PermissionResourceEnum.case,
        label: 'Can edit status',
      },
      {
        action: PermissionActionEnum.update_assignee,
        resource: PermissionResourceEnum.case,
        label: 'Can edit assignee',
      },
      {
        action: PermissionActionEnum.update_metadata,
        resource: PermissionResourceEnum.case,
        label: 'Can edit metadata',
      },
      {
        action: PermissionActionEnum.update_template,
        resource: PermissionResourceEnum.case,
        label: 'Can attach and edit template',
      },
      {
        action: PermissionActionEnum.run_template,
        resource: PermissionResourceEnum.case,
        label: 'Can re-run template',
      },
      {
        action: PermissionActionEnum.create,
        resource: PermissionResourceEnum.case_tag,
        label: 'Can add tag to case',
      },
      {
        action: PermissionActionEnum.delete,
        resource: PermissionResourceEnum.case_tag,
        label: 'Can remove tag from case',
      },
      {
        action: PermissionActionEnum.delete,
        resource: PermissionResourceEnum.case,
        label: 'Can delete',
      },
      {
        action: PermissionActionEnum.download_documents,
        resource: PermissionResourceEnum.case,
        label: 'Can download files and case report',
      },
      {
        action: PermissionActionEnum.share_link,
        resource: PermissionResourceEnum.case,
        label: 'Can share Client Portal/onboarding link',
      },
      {
        action: PermissionActionEnum.update_risk_level,
        resource: PermissionResourceEnum.case,
        label: 'Can update case risk level',
      },
      {
        action: PermissionActionEnum.confirm_periodic_review,
        resource: PermissionResourceEnum.case,
        label: 'Can confirm periodic review of a case',
      },
    ],
    subCategories: [
      {
        label: 'Company structure graph',
        permissions: [
          {
            action: PermissionActionEnum.create,
            resource: PermissionResourceEnum.case_relation,
            label: 'Can create relation',
          },
          {
            action: PermissionActionEnum.update,
            resource: PermissionResourceEnum.case_relation,
            label: 'Can update relation',
          },
          {
            action: PermissionActionEnum.delete,
            resource: PermissionResourceEnum.case_relation,
            label: 'Can delete relation',
          },
        ],
      },
      {
        label: 'Note',
        permissions: [
          {
            action: PermissionActionEnum.create,
            resource: PermissionResourceEnum.note,
            label: 'Can create a note',
          },
          {
            action: PermissionActionEnum.update,
            resource: PermissionResourceEnum.note,
            label: 'Can update a note',
          },
          {
            action: PermissionActionEnum.delete,
            resource: PermissionResourceEnum.note,
            label: 'Can delete a note',
          },
          {
            action: PermissionActionEnum.create,
            resource: PermissionResourceEnum.note_comment,
            label: 'Can comment a note',
          },
        ],
      },
      {
        label: 'Communication',
        permissions: [
          {
            action: PermissionActionEnum.send,
            resource: PermissionResourceEnum.communication,
            label: 'Can send a communication',
          },
        ],
      },
    ],
  },
  {
    label: 'Check',
    subCategories: [
      {
        label: 'AML',
        permissions: [
          {
            action: PermissionActionEnum.create,
            resource: PermissionResourceEnum.check_aml,
            label: 'Can start new or restart check',
          },
          {
            action: PermissionActionEnum.review_hit,
            resource: PermissionResourceEnum.check_aml,
            label: 'Can confirm/ignore hits',
          },
          {
            action: PermissionActionEnum.review,
            resource: PermissionResourceEnum.check_aml,
            label: 'Can approve/reject check',
          },
          {
            action: PermissionActionEnum.delete,
            resource: PermissionResourceEnum.check_aml,
            label: 'Can delete check',
          },
          {
            action: PermissionActionEnum.update_monitoring,
            resource: PermissionResourceEnum.check_aml,
            label: 'Can update monitoring',
          },
        ],
      },
      {
        label: 'ID Verification',
        permissions: [
          {
            action: PermissionActionEnum.create,
            resource: PermissionResourceEnum.check_id_verification,
            label: 'Can start new or restart check',
          },
          {
            action: PermissionActionEnum.review,
            resource: PermissionResourceEnum.check_id_verification,
            label: 'Can approve/reject check',
          },
          {
            action: PermissionActionEnum.delete,
            resource: PermissionResourceEnum.check_id_verification,
            label: 'Can delete check',
          },
        ],
      },
      {
        label: 'ID Document',
        permissions: [
          {
            action: PermissionActionEnum.create,
            resource: PermissionResourceEnum.check_id_document,
            label: 'Can start new or restart check',
          },
          {
            action: PermissionActionEnum.add_files,
            resource: PermissionResourceEnum.check_id_document,
            label: 'Can upload new files',
          },
          {
            action: PermissionActionEnum.review,
            resource: PermissionResourceEnum.check_id_document,
            label: 'Can approve/reject check',
          },
          {
            action: PermissionActionEnum.delete,
            resource: PermissionResourceEnum.check_id_document,
            label: 'Can delete check',
          },
        ],
      },
      {
        label: 'Document',
        permissions: [
          {
            action: PermissionActionEnum.create,
            resource: PermissionResourceEnum.check_document,
            label: 'Can start new or restart check',
          },
          {
            action: PermissionActionEnum.add_files,
            resource: PermissionResourceEnum.check_document,
            label: 'Can upload new files',
          },
          {
            action: PermissionActionEnum.update_expiration_date,
            resource: PermissionResourceEnum.check_document,
            label: 'Can set and edit expiration date',
          },
          {
            action: PermissionActionEnum.review,
            resource: PermissionResourceEnum.check_document,
            label: 'Can approve/reject check',
          },
          {
            action: PermissionActionEnum.delete,
            resource: PermissionResourceEnum.check_document,
            label: 'Can delete check',
          },
        ],
      },
    ],
  },
  {
    label: 'Company',
    permissions: [
      {
        action: PermissionActionEnum.create,
        resource: PermissionResourceEnum.company,
        label: 'Can create',
      },
      {
        action: PermissionActionEnum.update,
        resource: PermissionResourceEnum.company,
        label: 'Can edit information',
      },
      {
        action: PermissionActionEnum.create,
        resource: PermissionResourceEnum.document_order,
        label: 'Can purchase official documents',
      },
      {
        action: PermissionActionEnum.delete,
        resource: PermissionResourceEnum.company,
        label: 'Can delete',
      },
    ],
    subCategories: [
      {
        label: 'Company data',
        permissions: [
          {
            action: PermissionActionEnum.purchase_creditsafe,
            resource: PermissionResourceEnum.company_data,
            label: 'Can purchase Creditsafe data',
          },
          {
            action: PermissionActionEnum.purchase_kompany,
            resource: PermissionResourceEnum.company_data,
            label: 'Can purchase Kompany data',
          },
          {
            action: PermissionActionEnum.purchase_pappers,
            resource: PermissionResourceEnum.company_data,
            label: 'Can purchase Pappers data',
          },
          {
            action: PermissionActionEnum.purchase_kyckr,
            resource: PermissionResourceEnum.company_data,
            label: 'Can purchase Kyckr data',
          },
          {
            action: PermissionActionEnum.purchase_companies_house,
            resource: PermissionResourceEnum.company_data,
            label: 'Can purchase Companies House data',
          },
        ],
      },
    ],
  },
  {
    label: 'Individual',
    permissions: [
      {
        action: PermissionActionEnum.create,
        resource: PermissionResourceEnum.individual,
        label: 'Can create',
      },
      {
        action: PermissionActionEnum.update,
        resource: PermissionResourceEnum.individual,
        label: 'Can edit information',
      },
      {
        action: PermissionActionEnum.delete,
        resource: PermissionResourceEnum.individual,
        label: 'Can delete',
      },
    ],
  },
  {
    label: 'Template',
    permissions: [
      {
        action: PermissionActionEnum.create,
        resource: PermissionResourceEnum.template,
        label: 'Can create',
      },
      {
        action: PermissionActionEnum.update,
        resource: PermissionResourceEnum.template,
        label: 'Can edit information',
      },
      {
        action: PermissionActionEnum.delete,
        resource: PermissionResourceEnum.template,
        label: 'Can delete',
      },
    ],
  },
  {
    label: 'Analytics dashboard',
    permissions: [
      {
        action: PermissionActionEnum.read,
        resource: PermissionResourceEnum.analytics_dashboard,
        label: 'Can access',
      },
    ],
  },
];

export const basePermissionDefinition: BasePermissionDefinition[] =
  permissionMatrix.flatMap((category) => {
    let permissions: BasePermissionDefinition[] = [];
    if (category.permissions) {
      permissions = permissions.concat(
        category.permissions.map((p) => ({
          action: p.action,
          resource: p.resource,
        })),
      );
    }

    if (category.subCategories) {
      permissions = permissions.concat(
        category.subCategories.flatMap((subCategory) =>
          subCategory.permissions.map((p) => ({
            action: p.action,
            resource: p.resource,
          })),
        ),
      );
    }

    return permissions;
  });

// Check uniqueness of role/action/resource
if (
  uniqBy(
    basePermissionDefinition,
    (definition) => `${definition.action}-${definition.resource}`,
  ).length !== basePermissionDefinition.length
) {
  // If this error happen too often, maybe improve the error message to include the duplicate values
  throw new Error(
    'There is duplicate permission definition on the same `action`/`resource`',
  );
}
