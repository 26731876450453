import { PropsWithChildren } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

import {
  AppLoader,
  GenericErrorScreen,
  useInitI18n,
  useInitTheme,
} from '../../shared';
import { useLatestClientPortalVersion } from '../hooks';
import { redirectToCustomDomain } from './redirect-to-custom-domain';

export const LoadLatestClientPortalVersion = ({
  children,
}: PropsWithChildren) => {
  const { t } = useTranslation();

  const { data, error, loading } = useLatestClientPortalVersion();
  const { favicon } = data?.latestClientPortalVersion.setting.styling ?? {};

  redirectToCustomDomain(
    data?.latestClientPortalVersion?.clientPortal?.customDomain ?? null,
  );

  useInitI18n(data?.latestClientPortalVersion);

  useInitTheme(data?.latestClientPortalVersion?.setting.styling);

  if (error) {
    // @TODO - E-69 - better handle of error
    // Better detect offline or not found client portal
    if (error.message?.includes('offline')) {
      return (
        <GenericErrorScreen
          withMainLayout
          error={t('errors.offline.message', {
            ns: 'client-portal',
            defaultValue:
              'This portal is not available for submission, please reach your contact to get a new link.',
          })}
        />
      );
    } else if (error.message?.includes('404')) {
      // Client portal was not found
      return (
        <GenericErrorScreen
          withMainLayout
          error={t('errors.not_accessible.message', {
            ns: 'client-portal',
            defaultValue:
              'This page is not accessible, please reach your contact to get a new link.',
          })}
        />
      );
    } else {
      return <GenericErrorScreen withMainLayout error={error} />;
    }
  }

  if (loading || !data) {
    return <AppLoader />;
  }

  return (
    <>
      <Helmet>
        <title>{data.latestClientPortalVersion.clientPortal.name}</title>
        {favicon?.publicUrl && (
          <link rel="icon" type="image/x-icon" href={favicon.publicUrl} />
        )}
      </Helmet>
      {children}
    </>
  );
};
