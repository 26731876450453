import { Helmet } from 'react-helmet-async';

import { Environment } from '@dotfile/frontend/shared/common';

import { generateCspContent } from './generate-csp-content';
import { generatePermissionsPolicyContent } from './generate-permissions-policy-content';

export type CSPHeadersProps = {
  environment: Pick<
    Environment,
    'baseApp' | 'baseAPI' | 'baseMainStorage' | 'analyticsDataPlane' | 'stage'
  >;
  nonce: string;
  extraConnectSrc?: string;
  extraScriptSrc?: string;
  extraStyleSrc?: string;
  allowBlob?: boolean;
  allowDatadogRUM?: boolean;
  allowKnock?: boolean;
  allowGoogle?: boolean;
  allowMetabase?: boolean;
  allowUbble?: boolean;
  allowMonacoEditor?: boolean;
  allowParagon?: boolean;
  allowApproximated?: boolean;
  allowHyperline?: boolean;
  allowVeriff?: boolean;
};

/**
 * CSP Headers for modern browser security
 * @doc https://developer.mozilla.org/en-US/docs/Web/HTTP/CSP
 * @doc https://cheatsheetseries.owasp.org/cheatsheets/Content_Security_Policy_Cheat_Sheet.html
 * @doc https://csp-evaluator.withgoogle.com/
 * @note need to allow pdfjs to load, this should be package with the App @TODO - E-208
 *
 * CONSIDERATION with helmet: CSP are loaded when the main.js is loaded since it is managed by Helmet.
 * Thus the main.js script is injected and loaded and the strict-dynamic policy apply.
 * Since main.js is loaded, with strict-dynamic, every script loaded by main.js are authorized.
 * Also strict-dynamic is only for CSPv3 so to be retro-compatible for older browser version, we also put host whitelist.
 *
 * Monaco editor still don't support nonce for CSP
 * @see https://github.com/microsoft/monaco-editor/issues/271#issuecomment-1855991375
 * @see https://github.com/suren-atoyan/monaco-react/issues/583
 * @see https://github.com/suren-atoyan/monaco-react/issues/477#issuecomment-2199557034
 * @see https://github.com/grafana/grafana/pull/87295
 *
 * Script
 * - strict-dynamic: https://content-security-policy.com/strict-dynamic/
 * - PDFjs
 * - Google Auth
 * - unsafe-inline is required and ignored by browsers supporting nonces/hashes to be backward compatible with older browsers
 *
 * Worker/Child
 * - PDFjs create some blob script in the web worker using blob:, that's why we need to enable them
 *
 * Frame:
 * - Metabase for analytics
 * - Ubble for Ubble/Checkout iframe
 * - Veriff iframe
 *
 * Connect:
 * - Enable knock (https and wss)
 * - Enable Rudderstack DataPlane
 * - Enable GraphQL API
 * - Enable Datadog RUM (+ bugsnag internally use by datadog RUM)
 *
 * Style:
 * - Style nonce using emotion cache see: https://github.com/chakra-ui/chakra-ui/issues/3294
 * - We also need to pass the sha256 of the inline style for the base app loader in the index.html (static 'sha256-Q61bDQPgXhXJM0KRBMX+u6YXfcEtPAxqo0SHvNG3uoU=')
 * - The detectResizeElement for react-virtualized (AutoSizer) (static 'sha256-deDIoPlRijnpfbTDYsK+8JmDfUBmpwpnb0L/SUV8NeU='), see https://github.com/bvaughn/react-virtualized/blob/master/docs/usingAutoSizer.md#applying-content-security-policy
 * - The react-color inlined styles (static)
 *   - 'sha256-LA4KTjHIvt/e0fK4wBIK0x4Rx0vUv3/rVZ6n+vpT+GM='
 *   - 'sha256-d8+FRLATLC2M5M7tg4DHE7TNYGW0kr6ijwsDc7+G6DI='
 * - /!\ If we change the loader style we will need to change it here (we don't this very often)
 * - Monaco editor not supporting nonce https://cdn.jsdelivr.net/npm/monaco-editor@0.43.0/min/vs/editor/editor.main.css, if using monaco editor
 * must set style-src to 'unsafe-inline'
 * - `main.css` relative to the base app in dev
 *
 * Font:
 * - Monaco editor not supporting nonce https://cdn.jsdelivr.net/npm/monaco-editor@0.43.0/min/vs/base/browser/ui/codicons/codicon/codicon.ttf
 *
 * Others:
 * require-trusted-types-for 'script'; -> not working with Google Auth, no workaround
 * https://github.com/w3c/trusted-types
 * https://github.com/w3c/trusted-types/blob/main/explainer.md
 */
export const CSPHeaders = (props: CSPHeadersProps) => {
  return (
    <Helmet>
      <meta
        httpEquiv="Content-Security-Policy"
        content={generateCspContent(props)}
      />
      <meta
        httpEquiv="Permissions-Policy"
        content={generatePermissionsPolicyContent(props)}
      />
    </Helmet>
  );
};
