import { omit } from 'lodash';
import { ChevronDown, Edit } from 'lucide-react';
import { useMemo, useRef } from 'react';
import {
  FieldValues,
  UseFieldArrayUpdate,
  useFormContext,
} from 'react-hook-form';

import {
  HStack,
  Icon,
  IconButton,
  Input,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
  Text,
  useDisclosure,
} from '@dotfile/frontend/shared/design-system';
import {
  companyClassificationCoverage,
  CompanyClassificationTypeEnum,
} from '@dotfile/shared/domain';

import { GroupController } from '../../../input';

export type CompanyClassificationTypeFieldProps = {
  index: number;
  update: UseFieldArrayUpdate<FieldValues, string>;
  isManual: boolean;
  setManual: (manual: boolean) => void;
  label: string;
  name?: string;
  country?: string;
};

const typeLabel: Record<CompanyClassificationTypeEnum, string> = {
  [CompanyClassificationTypeEnum.ISIC]: 'ISIC',
  [CompanyClassificationTypeEnum.SIC]: 'SIC',
  [CompanyClassificationTypeEnum.NACE]: 'NACE',
  [CompanyClassificationTypeEnum.NAF]: 'APE/NAF',
  [CompanyClassificationTypeEnum.SIC_UK]: 'SIC (UK)',
};

export const CompanyClassificationTypeField = ({
  index,
  update,
  name,
  isManual,
  country,
  setManual,
  label,
}: CompanyClassificationTypeFieldProps) => {
  const inputTypeRef = useRef<HTMLInputElement | null>(null);
  const { control, setValue, trigger } = useFormContext();
  const prefix = useMemo(() => `${name}.${index}`, [index, name]);

  const availableClassifications = useMemo(
    () =>
      Object.entries(companyClassificationCoverage)
        .filter(([key, countries]) => !country || countries.includes(country))
        .map(([key]) => key) as CompanyClassificationTypeEnum[],
    [country],
  );
  const { onOpen, onClose, isOpen } = useDisclosure();

  return (
    <GroupController
      control={control}
      name={`${prefix}.type`}
      label={label}
      isRequired
      render={(field) => (
        <Menu placement="bottom-end" onClose={onClose} isOpen={isOpen}>
          <HStack
            spacing={0}
            _focusWithin={{ borderColor: 'gray.800' }}
            border="1px solid"
            borderRadius="md"
            borderColor={isOpen ? 'gray.800' : 'gray.100'}
            minHeight="40px"
            boxSizing="border-box"
          >
            <Input
              {...(isManual ? field : {})}
              tabIndex={isManual ? undefined : -1}
              px="4"
              placeholder={isManual ? 'Type' : 'Select'}
              onClick={isManual ? undefined : onOpen}
              ref={(ref) => {
                if (isManual) {
                  field.ref(ref);
                }
                inputTypeRef.current = ref;
              }}
              variant="unstyled"
              isReadOnly={!isManual}
              value={field.value}
              onChange={field.onChange}
            />
            <MenuButton
              {...(isManual ? {} : omit(field, ['isInvalid']))}
              as={IconButton}
              onClick={onOpen}
              borderLeft="1px solid"
              borderLeftColor="gray.100"
              borderRadius={0}
              aria-label="actions"
              icon={<Icon as={ChevronDown} />}
              variant="inline"
            />
          </HStack>
          <MenuList>
            <MenuOptionGroup type="radio" value={field.value}>
              {availableClassifications.map((type) => (
                <MenuItemOption
                  key={type}
                  alignItems="center"
                  value={type}
                  isChecked={type === field.value}
                  onClick={() => {
                    setManual(false);
                    update(index, {
                      type,
                      code: '',
                      description: '',
                    });
                    trigger(`${prefix}.type`);
                    onClose();
                  }}
                >
                  {typeLabel[type]}
                </MenuItemOption>
              ))}
            </MenuOptionGroup>
            <MenuDivider />
            <MenuItem
              onClick={() => {
                setManual(true);
                // @NOTE: Using update here will trigger a full rerender and will not trigger manual input
                (['type', 'code', 'description'] as const).forEach((field) => {
                  setValue(`${prefix}.${field}`, '', {
                    shouldDirty: true,
                    shouldTouch: true,
                  });
                });
                onClose();
                inputTypeRef.current?.focus();
              }}
            >
              <HStack as="p" spacing="1" alignItems="center">
                <Icon as={Edit} />
                <Text as="span">Fill manually</Text>
              </HStack>
            </MenuItem>
          </MenuList>
        </Menu>
      )}
    />
  );
};
