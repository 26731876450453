import { TFunction } from 'i18next';

import { DocumentTypeEnum } from '../../../shared/models';

export type DocumentTypeDefinition = {
  type: DocumentTypeEnum | null;
  label: (t: TFunction) => string;
};

const articles_of_association: DocumentTypeDefinition = {
  type: DocumentTypeEnum.articles_of_association,
  label: (t: TFunction) =>
    t('check.document.document_type.articles_of_association', {
      ns: 'domain',
      defaultValue: 'Articles of association',
    }),
};

const registration_certificate: DocumentTypeDefinition = {
  type: DocumentTypeEnum.registration_certificate,
  label: (t: TFunction) =>
    t('check.document.document_type.registration_certificate', {
      ns: 'domain',
      defaultValue: 'Registration certificate',
    }),
};

const ubo_registry_extract: DocumentTypeDefinition = {
  type: DocumentTypeEnum.ubo_registry_extract,
  label: (t: TFunction) =>
    t('check.document.document_type.ubo_registry_extract', {
      ns: 'domain',
      defaultValue: 'UBO registry extract',
    }),
};

const financial_statements: DocumentTypeDefinition = {
  type: DocumentTypeEnum.financial_statements,
  label: (t: TFunction) =>
    t('check.document.document_type.financial_statements', {
      ns: 'domain',
      defaultValue: 'Financial statements',
    }),
};

const bank_details: DocumentTypeDefinition = {
  type: DocumentTypeEnum.bank_details,
  label: (t: TFunction) =>
    t('check.document.document_type.bank_details', {
      ns: 'domain',
      defaultValue: 'Bank details',
    }),
};

const proof_of_insurance: DocumentTypeDefinition = {
  type: DocumentTypeEnum.proof_of_insurance,
  label: (t: TFunction) =>
    t('check.document.document_type.proof_of_insurance', {
      ns: 'domain',
      defaultValue: 'Proof of insurance',
    }),
};

const id_document: DocumentTypeDefinition = {
  type: DocumentTypeEnum.id_document,
  label: (t: TFunction) =>
    t('check.document.document_type.id_document', {
      ns: 'domain',
      defaultValue: 'Identity document',
    }),
};

const driving_license: DocumentTypeDefinition = {
  type: DocumentTypeEnum.driving_license,
  label: (t: TFunction) =>
    t('check.document.document_type.driving_license', {
      ns: 'domain',
      defaultValue: 'Driving license',
    }),
};

const proof_of_address: DocumentTypeDefinition = {
  type: DocumentTypeEnum.proof_of_address,
  label: (t: TFunction) =>
    t('check.document.document_type.proof_of_address', {
      ns: 'domain',
      defaultValue: 'Proof of address',
    }),
};

const visa: DocumentTypeDefinition = {
  type: DocumentTypeEnum.visa,
  label: (t: TFunction) =>
    t('check.document.document_type.visa', {
      ns: 'domain',
      defaultValue: 'Visa',
    }),
};

const social_security_card: DocumentTypeDefinition = {
  type: DocumentTypeEnum.social_security_card,
  label: (t: TFunction) =>
    t('check.document.document_type.social_security_card', {
      ns: 'domain',
      defaultValue: 'Social security card',
    }),
};

const birth_certificate: DocumentTypeDefinition = {
  type: DocumentTypeEnum.birth_certificate,
  label: (t: TFunction) =>
    t('check.document.document_type.birth_certificate', {
      ns: 'domain',
      defaultValue: 'Birth certificate',
    }),
};

const vehicle_registration_certificate: DocumentTypeDefinition = {
  type: DocumentTypeEnum.vehicle_registration_certificate,
  label: (t: TFunction) =>
    t('check.document.document_type.vehicle_registration_certificate', {
      defaultValue: 'Vehicle registration certificate',
      ns: 'domain',
    }),
};

const criminal_record_extract: DocumentTypeDefinition = {
  type: DocumentTypeEnum.criminal_record_extract,
  label: (t: TFunction) =>
    t('check.document.document_type.criminal_record_extract', {
      ns: 'domain',
      defaultValue: 'Criminal record extract',
    }),
};

const tax_notice: DocumentTypeDefinition = {
  type: DocumentTypeEnum.tax_notice,
  label: (t: TFunction) =>
    t('check.document.document_type.tax_notice', {
      ns: 'domain',
      defaultValue: 'Tax notice',
    }),
};

const defaultType: DocumentTypeDefinition = {
  type: null,
  label: (t: TFunction) =>
    t('check.document.document_type.unknown', {
      ns: 'domain',
      defaultValue: 'Unknown',
    }),
};

const mapDefinition = (definition: DocumentTypeDefinition) => definition.type;

const companyDocumentTypeDefinitions = [
  articles_of_association,
  registration_certificate,
  ubo_registry_extract,
  financial_statements,
  bank_details,
  proof_of_address,
  proof_of_insurance,
];

export const companyDocumentTypes = companyDocumentTypeDefinitions.map(
  mapDefinition,
) as DocumentTypeEnum[];

const individualDocumentTypeDefinitions = [
  id_document,
  driving_license,
  proof_of_address,
  visa,
  social_security_card,
  birth_certificate,
  vehicle_registration_certificate,
  bank_details,
  criminal_record_extract,
  tax_notice,
];

export const individualDocumentTypes = individualDocumentTypeDefinitions.map(
  mapDefinition,
) as DocumentTypeEnum[];

export const documentTypeDefinition = (
  type: string,
): DocumentTypeDefinition => {
  return (
    [
      ...new Set([
        ...individualDocumentTypeDefinitions,
        ...companyDocumentTypeDefinitions,
      ]),
    ]?.find((v) => v.type == type) || defaultType
  );
};
