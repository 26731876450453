import { ClientPortalStepTypeEnum } from '../../../../shared/models';
import {
  companyDefaultPropertiesDefinition,
  individualDefaultPropertiesDefinition,
  Property,
  PropertyTypeEnum,
} from '../../../../shared/properties';

export const defaultStepPropertiesDefinition: {
  [P in ClientPortalStepTypeEnum]: {
    properties: Property<PropertyTypeEnum>[];
  };
} = {
  company_search: {
    properties: [],
  },
  company_edit: {
    properties: [
      companyDefaultPropertiesDefinition.name,
      {
        ...companyDefaultPropertiesDefinition.registrationNumber,
        // Set has required by default
        alwaysRequired: true,
      },
      {
        ...companyDefaultPropertiesDefinition.country,
        // Set has required by default
        alwaysRequired: true,
      },
      companyDefaultPropertiesDefinition.address,
    ],
  },
  affiliated_companies_edit: {
    properties: [
      companyDefaultPropertiesDefinition.name,
      companyDefaultPropertiesDefinition.registrationNumber,
      companyDefaultPropertiesDefinition.country,
    ],
  },
  business_contact: {
    // @TODO - E-4124 - Client portal: configure blocks for Business contact step
    properties: [
      individualDefaultPropertiesDefinition.firstName,
      individualDefaultPropertiesDefinition.lastName,
      {
        ...individualDefaultPropertiesDefinition.email,
        // Business contact required an email
        alwaysRequired: true,
      },
    ],
  },
  individuals_edit: {
    properties: [
      individualDefaultPropertiesDefinition.firstName,
      individualDefaultPropertiesDefinition.lastName,
      individualDefaultPropertiesDefinition.email,
    ],
  },
  individual_edit: {
    properties: [
      individualDefaultPropertiesDefinition.firstName,
      individualDefaultPropertiesDefinition.lastName,
      {
        ...individualDefaultPropertiesDefinition.email,
        // KYC: email is always required because only one individual which is the business contact
        alwaysRequired: true,
      },
    ],
  },
  custom: { properties: [] },
};
