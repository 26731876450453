import { get } from 'lodash';
import { useEffect, useLayoutEffect, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ElementOf } from 'ts-essentials';

import { yupResolver } from '@hookform/resolvers/yup';

import { GroupController } from '@dotfile/frontend/shared/components';
import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Grid,
  GridItem,
  HStack,
  Input,
  Radio,
  RadioGroup,
  SelectMenu,
  Text,
  useIsSmallScreen,
  VStack,
} from '@dotfile/frontend/shared/design-system';
import { CaseRelationRoleEnum } from '@dotfile/shared/data-access-client-portal';
import { yup } from '@dotfile/shared/domain';

import {
  FullContainer,
  SupportButton,
  useLatestClientPortalVersion,
} from '../../../shared';
import {
  businessContactSelector,
  FormDatastoreIndividual,
  StepFooter,
  StepHeader,
  StepProps,
  SUBMIT_BUTTON_DATA_ATTRIBUTE,
  useFormDatastore,
  useFormDrawerState,
  useHandleSaveForLater,
} from '../shared';
import { getLocalizedValidation } from '../shared/utils/validation/localize-validation';
import { IndividualFormDrawer } from './individual-form-drawer';
import { IndividualItem } from './individual-item';

const NEW_INDIVIDUAL = 'NEW_INDIVIDUAL';

type FormValues = {
  businessContactIndex: string;
  email: string;
  isLegalRepresentative: boolean;
  delegatorIndex: string;
};

export const BusinessContactStep = ({ step, onSubmitStep }: StepProps) => {
  const { t } = useTranslation();

  const { data } = useLatestClientPortalVersion();
  const supportEmail = data?.latestClientPortalVersion.setting.supportEmail;

  const localizedValidation = getLocalizedValidation(t);
  const isSmallScreen = useIsSmallScreen();

  const requestDelegator = step.config.requestDelegator ?? false;

  const drawerState = useFormDrawerState();

  const { setBusinessContact, patchIndividualData } = useFormDatastore();
  const individuals = useFormDatastore((state) => state.data.individuals);
  const businessContact = useFormDatastore(businessContactSelector);
  const businessContactIndex = individuals?.findIndex(
    (i) => i.isBusinessContact,
  );
  const delegatorIndex = individuals?.findIndex((i) => i.isDelegator);
  const isBusinessContactLocked = businessContact && !!businessContact.id;

  const emailLabel = t(`steps.business_contact.blocks.email.label`, {
    ns: 'dynamic',
    defaultValue: 'Email',
  });

  // Form
  const methods = useForm<FormValues>({
    defaultValues: {
      businessContactIndex:
        businessContactIndex !== -1 ? businessContactIndex?.toString() : '',
      email: businessContact?.email ?? '',
      isLegalRepresentative: requestDelegator
        ? (businessContact?.roles?.includes(
            CaseRelationRoleEnum.legal_representative,
          ) ?? false)
        : false,
      delegatorIndex: delegatorIndex !== -1 ? delegatorIndex?.toString() : '',
    },
    mode: 'all',
    criteriaMode: 'all',
    resolver: yupResolver(
      yup
        .object()
        .shape({
          businessContactIndex: yup
            .string()
            .required(localizedValidation.required)
            .label(
              t(`forms.business_contact.select.label`, {
                ns: 'client-portal',
                defaultValue: 'Business contact',
              }),
            ),
          email: yup
            .string()
            .email(localizedValidation.email.invalid)
            .required(localizedValidation.required)
            .label(emailLabel),
          isLegalRepresentative: yup.boolean(),
          delegatorIndex: requestDelegator
            ? yup
                .string()
                .label(
                  t(`forms.business_contact.delegator.label`, {
                    ns: 'client-portal',
                    defaultValue: 'Grantor',
                  }),
                )
                .when('isLegalRepresentative', {
                  is: false,
                  then: (schema) =>
                    schema.required(localizedValidation.required),
                })
            : yup.string(),
        })
        .required(),
    ),
  });
  const { handleSubmit, control, watch, setValue, trigger } = methods;
  const newBusinessContactIndex = watch('businessContactIndex');
  const isLegalRepresentative = watch('isLegalRepresentative');
  const newDelegatorIndex = watch('delegatorIndex');

  // Business Contact Select
  const individualOptions: {
    value: string;
    individual: Partial<FormDatastoreIndividual> | null;
  }[] = useMemo(
    () => [
      ...(individuals
        ?.filter((i) => !i.isDelegator)
        .map((individual, index) => ({
          value: index.toString(),
          individual,
        })) || []),
      {
        value: NEW_INDIVIDUAL,
        individual: null,
      },
    ],
    [individuals],
  );
  const onSelectBusinessContact = ({ value }: { value: string }) => {
    if (value === NEW_INDIVIDUAL) {
      // Open drawer to add new Individual Business contact
      drawerState.handleAdd();
    } else {
      setValue('businessContactIndex', value, {
        shouldDirty: true,
        shouldValidate: true,
      });

      // Trigger email to remove error if new business contact already has an email
      const index = isNaN(Number(value)) ? -1 : Number(value);
      const individual = individuals?.[index];
      if (individual && individual.email) trigger('email');
    }
  };

  // When businessContact change, update email accordingly
  useLayoutEffect(
    // layout effect to avoid a glitch where the alert could be displayed before a render with the updated value of isLegalRepresentative
    () => {
      if (individuals && newBusinessContactIndex) {
        const { email, roles } = individuals[Number(newBusinessContactIndex)];
        setValue('email', email ?? '');
        setValue(
          'isLegalRepresentative',
          roles?.includes(CaseRelationRoleEnum.legal_representative) ?? false,
        );
      }
    },
    [newBusinessContactIndex, setValue, individuals],
  );

  // Delegator Select
  const legalRepresentativeOptions: {
    value: string;
    individual: Partial<FormDatastoreIndividual> | null;
  }[] = useMemo(
    () => [
      ...(
        individuals?.map((individual, index) => ({
          value: index.toString(),
          individual,
        })) || []
      ).filter(
        ({ individual }, index) =>
          individual.roles?.includes(
            CaseRelationRoleEnum.legal_representative,
          ) &&
          !individual.isBusinessContact &&
          index.toString() !== newBusinessContactIndex,
      ),
    ],
    [individuals, newBusinessContactIndex],
  );
  // EdgeCase: If the business contact is the same has the delegator, reset the delegator
  useEffect(() => {
    if (newBusinessContactIndex === newDelegatorIndex) {
      setValue('delegatorIndex', '');
    }
  }, [newBusinessContactIndex, newDelegatorIndex, setValue]);

  const [handleSaveForLater, saveForLaterState] = useHandleSaveForLater();
  return (
    <>
      <FullContainer
        as="form"
        noValidate
        onSubmit={handleSubmit(async (formValues, event) => {
          const businessContactIndexNumber = Number(
            formValues.businessContactIndex,
          );

          setBusinessContact(businessContactIndexNumber);
          patchIndividualData(
            { email: formValues.email },
            businessContactIndexNumber,
          );

          // If business Contact is also LegalRepresentative
          if (requestDelegator) {
            if (formValues.isLegalRepresentative && individuals) {
              const existingRole =
                individuals[businessContactIndexNumber].roles ?? [];

              if (
                !existingRole.includes(
                  CaseRelationRoleEnum.legal_representative,
                )
              ) {
                patchIndividualData(
                  {
                    roles: [
                      ...existingRole,
                      CaseRelationRoleEnum.legal_representative,
                    ],
                  },
                  businessContactIndexNumber,
                );
              }
            }

            // Set delegator individual
            if (
              formValues.isLegalRepresentative === false &&
              formValues.delegatorIndex
            ) {
              patchIndividualData(
                {
                  isDelegator: true,
                },
                Number(formValues.delegatorIndex),
              );
            }
          }

          // If Next button, also call onSubmitStep for navigation, otherwise Save for later
          if (
            // Submit on ENTER key
            get(event?.nativeEvent, 'submitter', null) === null ||
            // Submit on click on Next button
            get(
              event?.nativeEvent,
              'submitter',
              document.createElement('button'),
            ).getAttribute('data-submit') === SUBMIT_BUTTON_DATA_ATTRIBUTE.next
          ) {
            onSubmitStep();
          } else {
            handleSaveForLater();
          }
        })}
        footer={<StepFooter saveForLaterState={saveForLaterState} />}
      >
        <StepHeader />

        <FormProvider {...methods}>
          <Grid
            templateColumns="repeat(2, 1fr)"
            gap={isSmallScreen ? '2' : '4'}
          >
            <GridItem colSpan={isSmallScreen ? 2 : 1}>
              <GroupController
                isRequired
                key="businessContactIndex"
                name="businessContactIndex"
                label={t(`forms.business_contact.select.label`, {
                  ns: 'client-portal',
                  defaultValue: 'Business contact',
                })}
                control={control}
                render={(field) => (
                  <SelectMenu
                    {...field}
                    buttonProps={{
                      w: '100%',
                      isDisabled: isBusinessContactLocked,
                    }}
                    variant="select"
                    onChange={onSelectBusinessContact}
                    options={individualOptions}
                    // @NOTE: option require to explicitly define typing, inferred will resolve to {value: Type} from SelectMenu
                    renderOption={(
                      option: ElementOf<typeof individualOptions>,
                    ) => <IndividualItem individual={option.individual} />}
                  >
                    {field.value && individuals ? (
                      <IndividualItem
                        individual={individuals[Number(field.value)]}
                      />
                    ) : (
                      <Text align="start">
                        {t(`forms.business_contact.select.placeholder`, {
                          ns: 'client-portal',
                          defaultValue: 'Select the business contact',
                        })}
                      </Text>
                    )}
                  </SelectMenu>
                )}
              />
            </GridItem>
            <GridItem colSpan={isSmallScreen ? 2 : 1}>
              <GroupController
                isRequired
                key="email"
                name="email"
                label={emailLabel}
                control={control}
                render={(field) => (
                  <Input
                    type="email"
                    {...field}
                    isDisabled={isBusinessContactLocked}
                  />
                )}
              />
            </GridItem>
            {isBusinessContactLocked && (
              <GridItem colSpan={2}>
                <Text>
                  {t(
                    `forms.business_contact.contact_support_to_change_business_contact`,
                    {
                      ns: 'client-portal',
                      defaultValue:
                        'Contact support to request a change of business contact or email address.',
                    },
                  )}
                  {supportEmail && (
                    <SupportButton
                      supportEmail={supportEmail}
                      variant="link"
                      ml="0.5"
                    />
                  )}
                </Text>
              </GridItem>
            )}
            {requestDelegator && newBusinessContactIndex && (
              <>
                <GridItem colSpan={2}>
                  <GroupController
                    key="isLegalRepresentative"
                    name="isLegalRepresentative"
                    label={t(
                      `forms.business_contact.is_legal_representative.label`,
                      {
                        ns: 'client-portal',
                        defaultValue:
                          'Is the business contact a legal representative of the company?',
                      },
                    )}
                    control={control}
                    render={({ isInvalid, ...field }) => (
                      <RadioGroup
                        {...field}
                        value={field.value ? 'true' : 'false'}
                        onChange={(nextValue) => {
                          field.onChange(nextValue === 'true');
                        }}
                      >
                        <VStack alignItems="start">
                          <Radio value="true">
                            {t(
                              `forms.business_contact.is_legal_representative.yes`,
                              {
                                ns: 'client-portal',
                                defaultValue:
                                  'Yes, it is a legal representative',
                              },
                            )}
                          </Radio>
                          <Radio value="false">
                            {t(
                              `forms.business_contact.is_legal_representative.no`,
                              {
                                ns: 'client-portal',
                                defaultValue: 'No, it is not',
                              },
                            )}
                          </Radio>
                        </VStack>
                      </RadioGroup>
                    )}
                  />
                </GridItem>
                {isLegalRepresentative === false && (
                  <GridItem colSpan={2}>
                    {legalRepresentativeOptions.length <= 0 ? (
                      <Alert status="error">
                        <HStack>
                          <AlertIcon />
                          <VStack gap={0} align="stretch" width="full">
                            <AlertTitle>
                              {t(
                                'forms.business_contact.no_legal_representative_alert.title',
                                {
                                  ns: 'client-portal',
                                  defaultValue: 'Missing legal representative',
                                },
                              )}
                            </AlertTitle>
                            <AlertDescription>
                              {t(
                                'forms.business_contact.no_legal_representative_alert.description',
                                {
                                  ns: 'client-portal',
                                  defaultValue:
                                    'You need to have at least one legal representative to be selected and continue.',
                                },
                              )}
                            </AlertDescription>
                          </VStack>
                        </HStack>
                      </Alert>
                    ) : (
                      <GroupController
                        isRequired
                        name="delegatorIndex"
                        key="delegatorIndex"
                        label={t(`forms.business_contact.delegator.label`, {
                          ns: 'client-portal',
                          defaultValue: 'Grantor',
                        })}
                        helper={t(`forms.business_contact.delegator.helper`, {
                          ns: 'client-portal',
                          defaultValue:
                            'Select the legal representative granting power to the business contact',
                        })}
                        control={control}
                        render={(field) => (
                          <SelectMenu
                            {...field}
                            onChange={({ value }: { value: string }) => {
                              setValue('delegatorIndex', value, {
                                shouldDirty: true,
                                shouldValidate: true,
                              });
                            }}
                            buttonProps={{
                              w: '100%',
                            }}
                            variant="select"
                            options={legalRepresentativeOptions}
                            // @NOTE: option require to explicitly define typing, inferred will resolve to {value: Type} from SelectMenu
                            renderOption={(
                              option: ElementOf<
                                typeof legalRepresentativeOptions
                              >,
                            ) => (
                              <IndividualItem individual={option.individual} />
                            )}
                          >
                            {field.value && individuals ? (
                              <IndividualItem
                                individual={individuals[Number(field.value)]}
                              />
                            ) : (
                              <Text align="start">
                                {t(
                                  `forms.business_contact.delegator.placeholder`,
                                  {
                                    ns: 'client-portal',
                                    defaultValue:
                                      'Select a legal representative',
                                  },
                                )}
                              </Text>
                            )}
                          </SelectMenu>
                        )}
                      />
                    )}
                  </GridItem>
                )}
              </>
            )}
          </Grid>
        </FormProvider>
      </FullContainer>
      <IndividualFormDrawer
        isOpen={drawerState.isOpen}
        onClose={drawerState.handleClose}
        onNewBusinessContact={(index) => {
          setValue('businessContactIndex', index.toString());

          // Revalidate
          trigger();
        }}
        step={step}
      />
    </>
  );
};
