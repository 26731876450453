import yup from '../../../../utils/yup-extended';
import { defaultIdDocumentSettings } from '../default-settings';
import { IdDocumentCheckInput } from '../id-document-check-input';

export const idDocumentDataSchema = (): yup.SchemaOf<
  IdDocumentCheckInput['data']
> => {
  return yup
    .object({
      frontUploadRef: yup.string().required(),
      backUploadRef: yup.string().ensure().optional().nullable(),
    })
    .noUnknown()
    .defined();
};

export const idDocumentSettingsSchema = (): yup.SchemaOf<
  IdDocumentCheckInput['settings']
> => {
  return yup
    .object({
      automaticApproval: yup
        .boolean()
        .default(defaultIdDocumentSettings.automaticApproval),
      automaticRejection: yup
        .boolean()
        .default(defaultIdDocumentSettings.automaticRejection),
    })
    .noUnknown()
    .defined();
};
