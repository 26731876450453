import { CirclePlusIcon } from 'lucide-react';
import { useCallback, useEffect, useMemo } from 'react';
import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ElementOf } from 'ts-essentials';

import { GroupController } from '@dotfile/frontend/shared/components';
import {
  Alert,
  AlertIcon,
  AlertTitle,
  Button,
  Grid,
  GridItem,
  HStack,
  Icon,
  Input,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  SelectMenu,
  Text,
  useIsMobile,
  useToast,
  VStack,
} from '@dotfile/frontend/shared/design-system';
import { nameFormat } from '@dotfile/shared/common';
import { ClientPortalChecks_Individual } from '@dotfile/shared/data-access-client-portal';
import { ClientPortalCollaborationEnum } from '@dotfile/shared/domain';

import {
  FormattedTrans,
  useLatestClientPortalVersion,
} from '../../../../shared';
import { useCaseChecks } from '../../shared';
import {
  useInviteUserForm,
  useSaveBusinessContact,
  useSendCollaborationEmail,
} from '../hooks';
import { InviteCustomMessage } from './invite-custom-message';

const NEW_INDIVIDUAL = 'new_individual';

export const InviteUserModalContent = ({
  onClose,
  businessContact,
}: {
  onClose: () => void;
  businessContact: Pick<
    ClientPortalChecks_Individual,
    'id' | 'firstName' | 'lastName' | 'email'
  > | null;
}) => {
  const { t } = useTranslation();
  const toast = useToast();
  const isMobile = useIsMobile();

  const { data: latestVersionData } = useLatestClientPortalVersion();
  const { data } = useCaseChecks();
  const [saveBusinessContact] = useSaveBusinessContact();
  const [sendCollaborationEmail] = useSendCollaborationEmail();

  const {
    emailLabel,
    firstNameLabel,
    lastNameLabel,
    selectLabel,
    methods,
    defaultValues,
  } = useInviteUserForm(businessContact);
  const {
    control,
    watch,
    handleSubmit,
    setValue,
    setFocus,
    resetField,
    formState: { isSubmitting },
  } = methods;

  const canAddNewIndividual =
    latestVersionData?.latestClientPortalVersion.setting.collaboration ===
    ClientPortalCollaborationEnum.existing_and_add_new;

  const { nonBusinessContacts, nonBusinessContactOptions } = useMemo(() => {
    const nonBusinessContacts =
      data?.case?.individuals.filter(
        (individual) => !individual.isBusinessContact,
      ) ?? [];

    let nonBusinessContactOptions: {
      value: string;
      individual: ClientPortalChecks_Individual | null;
    }[] = canAddNewIndividual
      ? [
          {
            value: NEW_INDIVIDUAL,
            individual: null,
          },
        ]
      : [];
    nonBusinessContactOptions = nonBusinessContactOptions.concat(
      ...nonBusinessContacts.map((individual) => ({
        value: individual.id,
        individual,
      })),
    );

    return { nonBusinessContacts, nonBusinessContactOptions };
  }, [data, canAddNewIndividual]);

  const onSelectNonBusinessContact = useCallback(
    ({ value }: { value: string }) => {
      setValue('individualId', value, {
        shouldDirty: true,
        shouldValidate: true,
      });
      resetField('email', {
        keepError: false,
        keepDirty: false,
        keepTouched: false,
      });

      const individual = nonBusinessContacts.find(
        (individual) => individual.id === value,
      );
      if (individual) {
        setValue('firstName', individual?.firstName ?? '');
        setValue('lastName', individual?.lastName ?? '');
        setValue('email', individual?.email ?? '');
        setTimeout(() => setFocus('email'), 0);
      } else {
        setValue('firstName', defaultValues.firstName);
        setValue('lastName', defaultValues.lastName);
        setValue('email', defaultValues.email);
        setTimeout(() => setFocus('firstName'), 0);
      }
    },
    [
      defaultValues.email,
      defaultValues.firstName,
      defaultValues.lastName,
      nonBusinessContacts,
      resetField,
      setFocus,
      setValue,
    ],
  );

  // Auto-select add new individual when it's the only option
  useEffect(() => {
    if (
      canAddNewIndividual &&
      nonBusinessContactOptions.length === 1 &&
      !isSubmitting &&
      !businessContact
    ) {
      onSelectNonBusinessContact({ value: NEW_INDIVIDUAL });
    }
  }, [
    nonBusinessContactOptions.length,
    onSelectNonBusinessContact,
    canAddNewIndividual,
    isSubmitting,
    businessContact,
  ]);

  const individualId = watch('individualId');
  const firstName = watch('firstName');
  const lastName = watch('lastName');
  const isNewIndividual = individualId === NEW_INDIVIDUAL;
  const isSelectedIndividualDelegator =
    (individualId &&
      individualId !== NEW_INDIVIDUAL &&
      nonBusinessContacts.find((individual) => individual.id === individualId)
        ?.isDelegator) ||
    false;

  const onSubmit = handleSubmit(async (formValues) => {
    const { individualId, _addCustomMessage, customMessage, ...rest } =
      formValues;

    let businessContactId;
    if (!businessContact || formValues.email !== businessContact.email) {
      businessContactId = await saveBusinessContact({
        ...rest,
        id: individualId === NEW_INDIVIDUAL ? null : individualId,
      });
    }

    if (businessContact || businessContactId) {
      const sent = await sendCollaborationEmail({
        individualId: (businessContact?.id || businessContactId) as string,
        customMessage: _addCustomMessage ? customMessage : null,
      });
      if (sent) {
        const name = nameFormat(formValues.firstName, formValues.lastName);
        const tKeyPrefix = businessContact
          ? 'collaboration.email_sent'
          : 'collaboration.invite_sent';
        toast({
          status: 'success',
          title: t(`${tKeyPrefix}.title`, {
            defaultValue: 'Invite sent',
            ns: 'client-portal',
          }),
          description: (
            <FormattedTrans
              i18nKey={`${tKeyPrefix}.description`}
              defaultValue={`An invitation email just has been sent to ${name} (${formValues.email})`}
              ns="client-portal"
              values={{ name, email: formValues.email }}
            />
          ),
        });

        onClose();
      }
    }
  });

  return (
    <FormProvider {...methods}>
      <ModalContent as="form" onSubmit={onSubmit}>
        <ModalHeader>
          {t('collaboration.invite_user_to_collaborate', {
            defaultValue: 'Invite user to collaborate',
            ns: 'client-portal',
          })}
        </ModalHeader>
        <ModalBody>
          <VStack width="full" align="start" spacing="4">
            <VStack align="start" spacing="0">
              <Text color="black" fontSize="lg">
                {t('collaboration.share_access_portal', {
                  defaultValue: 'Share access to portal',
                  ns: 'client-portal',
                })}
              </Text>
              <Text>
                {t('collaboration.send_link_to_complete_checks', {
                  defaultValue: 'Share access to portal',
                  ns: 'client-portal',
                })}
              </Text>
            </VStack>

            <Grid
              width="full"
              rowGap="2"
              columnGap="4"
              gridTemplateColumns={{
                lg: individualId ? '1fr 1fr' : 'auto',
                base: 'auto',
              }}
            >
              {!isNewIndividual && (
                <GridItem>
                  <GroupController
                    isRequired={!businessContact}
                    name="individualId"
                    label={selectLabel}
                    control={control}
                    render={(field) =>
                      businessContact ? (
                        <Text color="black" height="10" py="2.5">
                          {nameFormat(firstName, lastName)}
                        </Text>
                      ) : (
                        <SelectMenu
                          {...field}
                          buttonProps={{ width: 'full' }}
                          value={field.value ?? undefined}
                          variant="select"
                          onChange={onSelectNonBusinessContact}
                          options={nonBusinessContactOptions}
                          renderOption={(
                            option: ElementOf<typeof nonBusinessContactOptions>,
                          ) =>
                            option.value === NEW_INDIVIDUAL ? (
                              <HStack>
                                <Icon as={CirclePlusIcon} />
                                <Text color="black" align="start">
                                  {t(`collaboration.invite.select.add`, {
                                    ns: 'client-portal',
                                    defaultValue: 'Add new individual',
                                  })}
                                </Text>
                              </HStack>
                            ) : (
                              <Text color="black">
                                {nameFormat(
                                  option.individual?.firstName,
                                  option.individual?.lastName,
                                )}
                              </Text>
                            )
                          }
                        >
                          {field.value && field.value !== NEW_INDIVIDUAL ? (
                            <Text color="black" align="start">
                              {nameFormat(firstName, lastName)}
                            </Text>
                          ) : (
                            <Text align="start">
                              {t(`collaboration.invite.select.placeholder`, {
                                ns: 'client-portal',
                                defaultValue: 'Select individual',
                              })}
                            </Text>
                          )}
                        </SelectMenu>
                      )
                    }
                  />
                </GridItem>
              )}

              {isNewIndividual && (
                <>
                  <GridItem colSpan={isMobile ? 2 : 1}>
                    <GroupController
                      isRequired
                      name="firstName"
                      label={firstNameLabel}
                      control={control}
                      render={(field) => (
                        <Input {...field} value={field.value ?? ''} />
                      )}
                    />
                  </GridItem>
                  <GridItem colSpan={isMobile ? 2 : 1}>
                    <GroupController
                      isRequired
                      name="lastName"
                      label={lastNameLabel}
                      control={control}
                      render={(field) => (
                        <Input {...field} value={field.value ?? ''} />
                      )}
                    />
                  </GridItem>
                </>
              )}

              {individualId && (
                <GridItem colSpan={isNewIndividual ? 2 : 1}>
                  <GroupController
                    isRequired
                    name="email"
                    label={emailLabel}
                    control={control}
                    render={(field) => (
                      <Input {...field} value={field.value ?? ''} />
                    )}
                  />
                </GridItem>
              )}
            </Grid>

            {isSelectedIndividualDelegator && (
              <Alert status="warning">
                <AlertIcon />
                <AlertTitle>
                  {t('collaboration.invite.user_is_delegator', {
                    defaultValue:
                      'Selected individual is delegator and cannot be business contact as well',
                    ns: 'client-portal',
                  })}
                </AlertTitle>
              </Alert>
            )}

            <InviteCustomMessage />
          </VStack>
        </ModalBody>
        <ModalFooter>
          <HStack>
            <Button variant="ghost" onClick={onClose}>
              {t('common.cancel', {
                defaultValue: 'Cancel',
                ns: 'client-portal',
              })}
            </Button>
            <Button
              type="submit"
              isLoading={isSubmitting}
              isDisabled={isSelectedIndividualDelegator}
              onClick={onSubmit}
            >
              {businessContact
                ? t('collaboration.send_link_by_email', {
                    defaultValue: 'Send link by email',
                    ns: 'client-portal',
                  })
                : t('collaboration.invite_user', {
                    defaultValue: 'Invite user',
                    ns: 'client-portal',
                  })}
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </FormProvider>
  );
};
