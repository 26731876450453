import {
  IndividualModel,
  ViewColumnMappingEntityEnum,
  ViewColumnMappingTypeEnum,
} from '../../../shared/models';
import {
  individualDefaultPropertiesDefinition,
  PropertyTypeEnum,
} from '../../../shared/properties';
import { ViewColumn } from './types';
import { ViewColumnBuilder } from './view-column-builder';

type IndividualViewColumn = Omit<
  // @NOTE Also update the test snapshot when modifying the properties
  IndividualModel,
  | 'caseId'
  | 'case'
  | 'workspaceId'
  | 'workspace'
  | 'checks'
  | 'customPropertyValues'
  | 'bankingInformation'
  | 'address'
  | 'relations'
  | 'createdAt'
  | 'updatedAt'
  | 'deletedAt'
> & {
  name: string;
  checks: unknown;
};

export const individualViewColumnDefinition: Record<
  keyof IndividualViewColumn,
  ViewColumn
> = new ViewColumnBuilder<IndividualViewColumn>(
  ViewColumnMappingEntityEnum.individual,
)
  .addViewColumn('id', PropertyTypeEnum.text, {
    canSort: false,
  })
  .addViewColumn('lastActivityAt', PropertyTypeEnum.date, {
    label: 'Last activity',
  })
  .addViewColumn('isBusinessContact', PropertyTypeEnum.boolean, {
    label: 'Business contact',
  })
  .addViewColumn('isBeneficialOwner', PropertyTypeEnum.boolean, {
    label: 'Beneficial owner',
  })
  .addViewColumn('isDelegator', PropertyTypeEnum.boolean, {
    label: 'Delegator',
  })
  .addViewColumn('isRelevant', PropertyTypeEnum.boolean, {
    label: 'Relevant',
  })
  .addViewColumn('roles', individualDefaultPropertiesDefinition.roles)

  .addViewColumn('firstName', individualDefaultPropertiesDefinition.firstName)
  .addViewColumn('middleName', individualDefaultPropertiesDefinition.middleName)
  .addViewColumn('lastName', individualDefaultPropertiesDefinition.lastName)
  .addViewColumn('maidenName', individualDefaultPropertiesDefinition.maidenName)
  .addViewColumn('name', PropertyTypeEnum.text, {
    mapping: {
      type: ViewColumnMappingTypeEnum.computed,
    },
  })

  .addViewColumn('email', individualDefaultPropertiesDefinition.email)
  .addViewColumn('birthDate', individualDefaultPropertiesDefinition.birthDate)
  .addViewColumn('birthPlace', individualDefaultPropertiesDefinition.birthPlace)
  .addViewColumn(
    'birthCountry',
    individualDefaultPropertiesDefinition.birthCountry,
  )

  .addViewColumn(
    'taxIdentificationNumber',
    individualDefaultPropertiesDefinition.taxIdentificationNumber,
  )
  .addViewColumn(
    'socialSecurityNumber',
    individualDefaultPropertiesDefinition.socialSecurityNumber,
  )
  .addViewColumn(
    'phoneNumber',
    individualDefaultPropertiesDefinition.phoneNumber,
  )

  .addViewColumn('position', individualDefaultPropertiesDefinition.position)
  .addViewColumn(
    'ownershipPercentage',
    individualDefaultPropertiesDefinition.ownershipPercentage,
  )
  .addViewColumn(
    'votingRightsPercentage',
    individualDefaultPropertiesDefinition.votingRightsPercentage,
  )
  .addViewColumn('checks', PropertyTypeEnum.unknown, {
    mapping: {
      type: ViewColumnMappingTypeEnum.relation,
    },
  })

  .getDefinition();

export const allIndividualViewColumnDefinition = Object.values(
  individualViewColumnDefinition,
);
