import { humanCase } from '@dotfile/shared/common';

import {
  CompanyModel,
  CompanyStatusEnum,
  PropertyMappingEntityEnum,
} from '../../shared/models';
import { COMPANY_NAME_MAX_LENGTH } from './constants';
import { DefaultPropertiesBuilder } from './default-properties-builder';
import { Property, PropertyTypeEnum } from './types';

export const companyDefaultPropertiesDefinition: Record<
  keyof Omit<
    // @NOTE Also update the test snapshot when modifying the properties
    CompanyModel,
    | 'caseId'
    | 'case'
    | 'workspaceId'
    | 'workspace'
    | 'lastActivityAt' // @NOTE not exposed yet, could be later for the rule engine
    | 'type' // @NOTE not exposed yet, could be later for the rule engine
    | 'vendorIds'
    | 'checks'
    | 'documentOrders'
    | 'vendorData'
    | 'customPropertyValues'
    | 'relations'
    | 'id'
    | 'isRelevant'
    | 'createdAt'
    | 'updatedAt'
    | 'deletedAt'
  >,
  Property<PropertyTypeEnum>
> = new DefaultPropertiesBuilder<CompanyModel>(
  PropertyMappingEntityEnum.company,
)
  .addProperty('name', PropertyTypeEnum.text, {
    alwaysRequired: true,
    settings: {
      isMultiLine: false,
      maxLength: COMPANY_NAME_MAX_LENGTH,
    },
  })
  .addProperty('commercialName', PropertyTypeEnum.text, {
    settings: {
      isMultiLine: false,
      maxLength: COMPANY_NAME_MAX_LENGTH,
    },
  })
  .addProperty('registrationNumber', PropertyTypeEnum.text, {
    alwaysRequired: false,
  })
  .addProperty('registrationDate', PropertyTypeEnum.date)

  .addProperty('country', PropertyTypeEnum.countries, {
    alwaysRequired: false,
    settings: { allowMultiple: false },
  })
  .addProperty('status', PropertyTypeEnum.choices, {
    settings: {
      allowMultiple: false,
      options: Object.values(CompanyStatusEnum).map((key) => ({
        key,
        label: humanCase(key),
      })),
    },
  })

  .addProperty('legalForm', PropertyTypeEnum.text)
  .addProperty('entityLegalForm', PropertyTypeEnum.entity_legal_form)

  .addProperty('bankingInformation', PropertyTypeEnum.banking_information)
  .addProperty('address', PropertyTypeEnum.address)
  .addProperty('taxIdentificationNumber', PropertyTypeEnum.text)
  .addProperty('employerIdentificationNumber', PropertyTypeEnum.text)
  .addProperty('classifications', PropertyTypeEnum.classifications)

  .addProperty('shareCapital', PropertyTypeEnum.text)
  .addProperty('websiteUrl', PropertyTypeEnum.url, {
    label: 'Website URL',
  })

  .getDefinition();

export const allCompanyDefaultPropertiesDefinition = Object.values(
  companyDefaultPropertiesDefinition,
);
