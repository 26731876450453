import { pick } from 'lodash';
import { FieldValues, FormProvider, UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  Button,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerProps,
  useIsSmallScreen,
  VStack,
} from '@dotfile/frontend/shared/design-system';

import { useCurrentStep } from '../context';
import {
  FIELDS_PREFIX,
  FieldsFormValues,
  formValuesToData,
  FormValuesToDataResult,
} from '../utils';

export type DrawerFieldsFormSubmit<
  TExtraValues extends FieldValues = Record<string, never>,
> = (param: {
  data: FormValuesToDataResult;
  formValues: FieldsFormValues<TExtraValues>;
  event: React.BaseSyntheticEvent | undefined;
}) => void | Promise<void>;

export type DrawerFieldsFormProps<
  TExtraValues extends FieldValues = Record<string, never>,
> = Pick<DrawerProps, 'isOpen' | 'onClose' | 'children'> & {
  header: React.ReactNode;

  methods: UseFormReturn<FieldsFormValues<TExtraValues>>;
  onSubmit: DrawerFieldsFormSubmit<TExtraValues>;
  submitLabel: string;
};

export const DrawerFieldsForm = <
  TExtraValues extends FieldValues = Record<string, never>,
>({
  isOpen,
  onClose,
  children,

  header,

  methods,
  onSubmit,
  submitLabel,
}: DrawerFieldsFormProps<TExtraValues>) => {
  const step = useCurrentStep();

  const isSmallScreen = useIsSmallScreen();
  const { t } = useTranslation();

  const handleSubmit = methods.handleSubmit(async (formValues, event) => {
    const isValid = await methods.trigger();

    if (isValid) {
      // Only submit the value were the form field has been modified
      const dirtyFieldsValues = pick(
        formValues[FIELDS_PREFIX],
        Object.keys(methods.formState.dirtyFields[FIELDS_PREFIX] ?? {}),
      );
      const data = formValuesToData(dirtyFieldsValues, { step });

      await onSubmit({ data, formValues, event });
    }
  });

  return (
    <Drawer
      isOpen={isOpen}
      onClose={onClose}
      placement={isSmallScreen ? 'bottom' : 'right'}
      size="xl"
      isFullHeight
      closeOnOverlayClick={false}
    >
      <DrawerOverlay />
      <DrawerContent>
        <DrawerHeader
          backgroundColor="unset"
          color="black"
          borderBottomWidth="1px"
          py={isSmallScreen ? '2' : '4'}
          px={isSmallScreen ? '3' : '6'}
        >
          {header}
        </DrawerHeader>

        <DrawerBody
          display="flex"
          flexDirection="column"
          py="0"
          my="0"
          height="full"
          width="full"
          as="form"
          noValidate
          onSubmit={handleSubmit}
        >
          <VStack
            flex="1"
            alignItems="stretch"
            py={isSmallScreen ? '2' : '3'}
            spacing={isSmallScreen ? '2' : '4'}
            px={isSmallScreen ? '3' : '6'}
          >
            <FormProvider {...methods}>{children}</FormProvider>
          </VStack>

          <DrawerFooter
            display="flex"
            justifyContent="center"
            flexShrink="0"
            backdropFilter="blur(6px)"
            position="sticky"
            bottom="0"
            zIndex="10"
            py={isSmallScreen ? '2' : '4'}
            gap={isSmallScreen ? '2' : '4'}
            px={isSmallScreen ? '3' : '6'}
          >
            <Button variant="ghost" onClick={onClose} size="md">
              {t('common.cancel', {
                ns: 'client-portal',
                defaultValue: 'Cancel',
              })}
            </Button>
            <Button size="md" type="submit">
              {submitLabel}
            </Button>
          </DrawerFooter>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};
