import { ComponentProps, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  GroupController,
  UploadDropzone,
  useUploadFile,
} from '@dotfile/frontend/shared/components';
import { Input } from '@dotfile/frontend/shared/design-system';
import { FileTargetEnum } from '@dotfile/shared/data-access-client-portal';
import { ID_DOCUMENT_MAX_FILE_SIZE } from '@dotfile/shared/domain';

import { UploadFormValues } from '../hooks';

const dropzoneProps: ComponentProps<typeof UploadDropzone> = {
  type: 'file',
  isFocused: false,
  overflow: 'hidden',
  maxSize: ID_DOCUMENT_MAX_FILE_SIZE,
};

export const FrontBackUploadControllers = () => {
  const { t } = useTranslation();
  const { control, setValue } = useFormContext<UploadFormValues>();

  const { reset: resetFrontUpload, ...frontUpload } = useUploadFile(
    FileTargetEnum.document_data_file,
  );
  const { reset: resetBackUpload, ...backUpload } = useUploadFile(
    FileTargetEnum.document_data_file,
  );

  const handleInputFrontChange = async (file: File): Promise<void> => {
    const frontUploadRef = await frontUpload.uploadFile(file);
    setValue('frontUploadRef', frontUploadRef, {
      shouldDirty: true,
      shouldValidate: true,
    });
  };

  const handleInputBackChange = async (file: File): Promise<void> => {
    const backUploadRef = await backUpload.uploadFile(file);
    setValue('backUploadRef', backUploadRef, {
      shouldDirty: true,
      shouldValidate: true,
    });
  };

  useEffect(() => {
    return () => {
      // Reset upload to clear the filename
      resetFrontUpload();
      resetBackUpload();
    };
  }, [resetFrontUpload, resetBackUpload]);

  return (
    <>
      <GroupController
        name="frontUploadRef"
        label={t('checks.id_document.add_files.front', {
          defaultValue: 'Front',
          ns: 'client-portal',
        })}
        isRequired
        control={control}
        render={(field) => (
          <>
            <Input type="hidden" {...field} value={field.value ?? ''} />
            <UploadDropzone
              fileName={frontUpload.fileName}
              uploadProgress={frontUpload.progress}
              onDrop={handleInputFrontChange}
              {...dropzoneProps}
            />
          </>
        )}
      />
      <GroupController
        name="backUploadRef"
        label={t('checks.id_document.add_files.back', {
          defaultValue: 'Back',
          ns: 'client-portal',
        })}
        control={control}
        render={(field) => (
          <>
            <UploadDropzone
              fileName={backUpload.fileName}
              uploadProgress={backUpload.progress}
              onDrop={handleInputBackChange}
              {...dropzoneProps}
            />
            <Input type="hidden" {...field} value={field.value ?? ''} />
          </>
        )}
      />
    </>
  );
};
