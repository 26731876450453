import {
  CheckStatusEnum,
  ClientPortalChecks_IdVerificationCheck,
  useRefreshUrlIdentityVerificationCheckMutation,
  useRestartIdentityVerificationCheckMutation,
} from '@dotfile/shared/data-access-client-portal';

/**
 * Refresh or restart identity verification
 * @param check - IDV check to restart
 */
export function useVerificationUrl(
  check: ClientPortalChecks_IdVerificationCheck,
) {
  const [refreshUrl, refreshResult] =
    useRefreshUrlIdentityVerificationCheckMutation({
      variables: { input: { checkId: check.id } },
    });

  const [restartIdentification, restartResult] =
    useRestartIdentityVerificationCheckMutation({
      variables: { input: { checkId: check.id } },
    });

  return {
    refresh:
      check.status === CheckStatusEnum.rejected
        ? restartIdentification
        : refreshUrl,
    loading: refreshResult.loading || restartResult.loading,
    error: refreshResult.error || restartResult.error,
    verificationUrl:
      refreshResult.data?.refreshUrlIdentityVerificationCheck.data.vendor
        .verificationUrl ??
      restartResult.data?.restartIdentityVerificationCheck.data.vendor
        .verificationUrl,
  };
}
