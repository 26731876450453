import { useTranslation } from 'react-i18next';

import { DrawerProps } from '@dotfile/frontend/shared/design-system';
import { ClientPortalForms_Step } from '@dotfile/shared/data-access-client-portal';

import {
  affiliatedCompaniesSelector,
  Blocks,
  DrawerFieldsForm,
  DrawerFieldsFormSubmit,
  useFieldsForm,
  useFormDatastore,
} from '../shared';

export type AffiliatedCompanyFormDrawerProps = Pick<
  DrawerProps,
  'isOpen' | 'onClose'
> & {
  onClose: () => void;

  step: ClientPortalForms_Step;
  selectedAffiliatedCompanyIndex: number | null;
};

export const AffiliatedCompanyFormDrawer = ({
  isOpen,
  onClose,
  selectedAffiliatedCompanyIndex,
  step,
}: AffiliatedCompanyFormDrawerProps) => {
  const affiliatedCompanies = useFormDatastore(affiliatedCompaniesSelector);
  const company =
    selectedAffiliatedCompanyIndex === null || !affiliatedCompanies
      ? {}
      : affiliatedCompanies[selectedAffiliatedCompanyIndex];
  const { patchAffiliatedCompanyData, addAffiliatedCompanyData } =
    useFormDatastore();

  const { methods, displayedBlocks } = useFieldsForm({
    step,
    data: { case: undefined, company },
    isHidden: !isOpen,
  });
  const { t } = useTranslation();

  const onSubmit: DrawerFieldsFormSubmit = ({ data }) => {
    if (data.company) {
      if (selectedAffiliatedCompanyIndex === null) {
        addAffiliatedCompanyData(data.company);
      } else {
        patchAffiliatedCompanyData(
          data.company,
          selectedAffiliatedCompanyIndex,
        );
      }
    }

    onClose();
  };

  const header = t('forms.affiliated_companies_edit.form_drawer.header', {
    ns: 'client-portal',
    defaultValue: 'Affiliated company information',
  });

  const submitLabel =
    selectedAffiliatedCompanyIndex === null
      ? t('forms.affiliated_companies_edit.add', {
          ns: 'client-portal',
          defaultValue: 'Add affiliated company',
        })
      : t('forms.affiliated_companies_edit.update', {
          ns: 'client-portal',
          defaultValue: 'Update affiliated company',
        });

  return (
    <DrawerFieldsForm
      isOpen={isOpen}
      onClose={onClose}
      methods={methods}
      onSubmit={onSubmit}
      header={header}
      submitLabel={submitLabel}
    >
      <Blocks blocks={displayedBlocks} />
    </DrawerFieldsForm>
  );
};
