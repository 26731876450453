import { TFunction } from 'i18next';

import { logAndAddError } from '@dotfile/frontend/shared/common';
import {
  ClientPortalBlockField,
  ClientPortalStep,
} from '@dotfile/shared/data-access-client-portal';
import { yup } from '@dotfile/shared/domain';

import { generatePropertyValidationSchema } from './generate-property-validation-schema';
import { getLocalizedValidation } from './localize-validation';

type GenerateBlockValidationSchemaParam = {
  step: Pick<ClientPortalStep, 'id' | 'key'>;
  displayedFields: Array<
    Pick<
      ClientPortalBlockField,
      'key' | 'label' | 'mapping' | 'property' | 'isRequired'
    >
  >;
  hiddenFields: Array<Pick<ClientPortalBlockField, 'key'>>;
  t: TFunction;
};

export const generateBlockValidationSchema = ({
  step,
  displayedFields,
  hiddenFields,
  t,
}: GenerateBlockValidationSchemaParam) => {
  const blockSchemas: { [key: string]: yup.AnySchema } = {};

  const localizedValidation = getLocalizedValidation(t);

  for (const displayedField of displayedFields) {
    const { isRequired, property, mapping, key, label } = displayedField;

    // Ignore field with unresolved property here, it should already been logged else-where
    if (!property) {
      continue;
    }

    // Build the property-specific validation schema
    try {
      const validationSchema = generatePropertyValidationSchema({
        isRequired,
        property,
        mapping,
        localizedValidation,
        t,
      })
        // Set localized label
        .label(
          t(`steps.${step.key}.blocks.${key}.label`, {
            defaultValue: label,
            ns: 'dynamic',
          }),
        );

      // Set schema for the key
      blockSchemas[key] = validationSchema;
    } catch (error) {
      logAndAddError(error, { key, field: displayedField, step });
    }
  }

  for (const hiddenField of hiddenFields) {
    if (!blockSchemas[hiddenField.key]) {
      // Allow value for hidden field, otherwise they would be invalid because of noExtraProperty
      blockSchemas[hiddenField.key] = yup.mixed().optional();
    }
  }

  // Build yup schema from schema shape
  const validationSchema: yup.AnyObjectSchema = yup
    .object()
    .shape(blockSchemas)
    .noExtraProperty()
    .defined();

  return validationSchema;
};
