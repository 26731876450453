import { match } from 'ts-pattern';

import { IdVerificationVendorEnum, VendorEnum } from '@dotfile/shared/domain';

import { VendorIframeProps } from './types';
import { UbbleCheckoutIframe } from './vendor/ubble-checkout-iframe';
import { VeriffIframe } from './vendor/veriff-iframe';

type IdVerificationVendorIframeProps = VendorIframeProps & {
  vendor: VendorEnum;
};

export const IdVerificationVendorIframe = ({
  vendor,
  ...iframeProps
}: IdVerificationVendorIframeProps) => {
  return match(vendor)
    .with(IdVerificationVendorEnum.veriff, () => (
      <VeriffIframe {...iframeProps} />
    ))
    .otherwise(() => <UbbleCheckoutIframe {...iframeProps} />);
};
