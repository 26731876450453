import { FC, useCallback, useMemo, useState } from 'react';

import { AuthContext } from '@dotfile/frontend/shared/common';

import { environment } from '../../../../environments/environment';
import { useClientPortalId } from '../client-portal-id.context';
import { ContactAuthService } from './auth.service';
import { ContactAuth, ContactAuthContext, ContactAuthToken } from './type';

// Contact Auth
const useContactAuthProvider = (): ContactAuthContext => {
  const clientPortalId = useClientPortalId();
  const contactAuthService = useMemo(() => {
    return new ContactAuthService(environment.baseAPI, clientPortalId);
  }, [clientPortalId]);

  const [auth, setAuth] = useState<ContactAuth>({
    isAuthenticated: null,
    logout: false,
    contactId: null,
    caseId: null,
  });

  // Request Magic Link
  const requestMagicLink = useCallback(
    (email: string) => contactAuthService.requestMagicLink(email),
    [contactAuthService],
  );

  const requestMagicLinkFromToken = useCallback(
    (token: string) => contactAuthService.requestMagicLinkFromToken(token),
    [contactAuthService],
  );

  // Redeem
  const redeemMagicToken = useCallback(
    async (token: string) => {
      const { contactId, caseId } =
        await contactAuthService.redeemMagicToken(token);
      setAuth({
        isAuthenticated: true,
        contactId,
        caseId,
        logout: false,
      });
      return contactId;
    },
    [contactAuthService],
  );

  // Token
  const getToken = useCallback(
    () => contactAuthService.getToken(),
    [contactAuthService],
  );
  const setAuthToken = useCallback(
    (authToken: ContactAuthToken) => {
      contactAuthService.setAuthToken(authToken);
      setAuth({
        isAuthenticated: true,
        contactId: authToken.contactId,
        caseId: authToken.caseId,
        logout: false,
      });
    },
    [contactAuthService],
  );

  // Logout
  const logout = useCallback(async () => {
    await contactAuthService.logout();
    setAuth({
      isAuthenticated: false,
      contactId: null,
      caseId: null,
      notificationToken: undefined,
      logout: true,
    });
    return true;
  }, [contactAuthService]);

  // Sync
  const sync = useCallback(async () => {
    if (!contactAuthService.checkAuth() && !auth.logout) {
      try {
        const { contactId, caseId } = await contactAuthService.silentRefresh();
        setAuth({
          isAuthenticated: true,
          contactId,
          caseId,
          logout: false,
        });
        console.debug(`🔓 Logged in as contact ${contactId}`);
      } catch (e) {
        // If errors, clear Contact and refresh without setting logout flag
        await contactAuthService.logout();
        setAuth({
          isAuthenticated: false,
          contactId: null,
          caseId: null,
          notificationToken: undefined,
          logout: false,
        });
      }
    }
  }, [contactAuthService, auth]);

  // Unlike console-app, auth is not automatically sync

  return {
    auth,
    sync,
    logout,
    getToken,
    setAuthToken,
    requestMagicLink,
    requestMagicLinkFromToken,
    redeemMagicToken,
  };
};

export const ContactAuthProvider: FC<{ children: React.ReactElement }> = ({
  children,
}) => {
  const auth = useContactAuthProvider();
  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
};
