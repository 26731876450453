import { match } from 'ts-pattern';

import {
  ClientPortalBlockFieldModel,
  ClientPortalBlockModel,
  ClientPortalStepModel,
  ClientPortalStepTypeEnum,
  ClientPortalTypeEnum,
  PropertyMappingEntityEnum,
  PropertyMappingTypeEnum,
} from '../../../shared/models';
import {
  companyDefaultPropertiesDefinition,
  generateKeyFromMapping,
  getCustomPropertyAdapter,
  individualDefaultPropertiesDefinition,
  Property,
  PropertyTypeEnum,
  QueryBuilderField,
  QueryBuilderFieldGroup,
  resolveDefaultPropertyFromMapping,
} from '../../../shared/properties';
import { matchFieldMapping } from '../client-portal-block';
import { UNSUPPORTED_PROPERTY_TYPES } from './constants';

type LogicBuilderFieldByEntity = {
  case: QueryBuilderField[];
  mainCompany: QueryBuilderField[];
  individual: QueryBuilderField[];
  affiliatedCompany: QueryBuilderField[];
};

type GetLogicBuilderFieldsParams = {
  clientPortalType: ClientPortalTypeEnum;
  customProperties: Parameters<typeof getCustomPropertyAdapter>[0][];

  /**
   * Omit to get the fields for any steps (logic at the **step** level)
   *
   * Set to get the fields available for the blocks of a specific step (logic at the **block** level)
   */
  step?: Pick<ClientPortalStepModel, 'type'> & {
    blocks: (
      | Partial<Omit<ClientPortalBlockModel, 'logics'>>
      | Pick<ClientPortalBlockFieldModel, 'mapping'>
    )[];
  };
};

export const getLogicBuilderFields = ({
  clientPortalType,
  step,
  customProperties: customPropertiesAsCustomProperties,
}: GetLogicBuilderFieldsParams): QueryBuilderFieldGroup[] => {
  const customPropertiesAsProperties = customPropertiesAsCustomProperties.map(
    getCustomPropertyAdapter,
  );

  let fields = getLogicBuilderGlobalFields(
    clientPortalType,
    customPropertiesAsProperties,
  );

  if (step) {
    const localFields = getLogicBuilderLocalFields(
      step,
      customPropertiesAsProperties,
    );

    fields = mergeGlobalAndLocalFieldsByEntities({
      globalFields: fields,
      localFields,
    });
  }

  const fieldGroups: QueryBuilderFieldGroup[] = [];

  if (fields.case.length > 0) {
    fieldGroups.push({ label: 'Case', options: fields.case });
  }

  if (fields.mainCompany.length > 0) {
    fieldGroups.push({ label: 'Main company', options: fields.mainCompany });
  }

  if (fields.individual.length > 0) {
    fieldGroups.push({ label: 'Individual', options: fields.individual });
  }

  if (fields.affiliatedCompany.length > 0) {
    fieldGroups.push({
      label: 'Affiliated company',
      options: fields.affiliatedCompany,
    });
  }

  return fieldGroups;
};

const getLogicBuilderGlobalFields = (
  clientPortalType: ClientPortalTypeEnum,
  customProperties: Property<PropertyTypeEnum>[],
): LogicBuilderFieldByEntity => {
  const fieldsByEntities: LogicBuilderFieldByEntity = {
    case: [],
    mainCompany: [],
    individual: [],
    affiliatedCompany: [],
  };

  // Case custom properties are always available for both client portal type
  // @NOTE There is no default case properties yet
  fieldsByEntities.case.push(
    ...customProperties
      .filter(
        (property) =>
          property.mapping.entity === PropertyMappingEntityEnum.case,
      )
      .map((property) => propertyToLogicBuilderField('global.case', property)),
  );

  if (clientPortalType === ClientPortalTypeEnum.KYB) {
    // Main company default/ custom properties are available for KYB client portal

    fieldsByEntities.mainCompany.push(
      ...Object.values(companyDefaultPropertiesDefinition)
        .filter(
          (property) => !UNSUPPORTED_PROPERTY_TYPES.includes(property.type),
        )
        .map((property) =>
          propertyToLogicBuilderField('global.main_company', property),
        ),
    );

    fieldsByEntities.mainCompany.push(
      ...customProperties
        .filter(
          (property) =>
            property.mapping.entity === PropertyMappingEntityEnum.company,
        )
        .map((property) =>
          propertyToLogicBuilderField('global.main_company', property),
        ),
    );
  }

  if (clientPortalType === ClientPortalTypeEnum.KYC) {
    // Individual default/ custom properties are available for KYB client portal

    fieldsByEntities.individual.push(
      ...Object.values(individualDefaultPropertiesDefinition)
        .filter(
          (property) => !UNSUPPORTED_PROPERTY_TYPES.includes(property.type),
        )
        .map((property) =>
          propertyToLogicBuilderField('global.current_individual', property),
        ),
    );

    fieldsByEntities.individual.push(
      ...customProperties
        .filter(
          (property) =>
            property.mapping.entity === PropertyMappingEntityEnum.individual,
        )
        .map((property) =>
          propertyToLogicBuilderField('global.current_individual', property),
        ),
    );
  }

  return fieldsByEntities;
};

const getLogicBuilderLocalFields = (
  step: Required<GetLogicBuilderFieldsParams>['step'],
  customProperties: Property<PropertyTypeEnum>[],
): LogicBuilderFieldByEntity => {
  const stepFields = step.blocks.filter(
    (b): b is Pick<ClientPortalBlockFieldModel, 'mapping'> => 'mapping' in b,
  );

  // Resolve properties from step fields
  const properties = stepFields
    .map((field) => {
      if (field.mapping.type === PropertyMappingTypeEnum.default) {
        return resolveDefaultPropertyFromMapping(field.mapping).property;
      }

      const matchField = matchFieldMapping(field);
      const resolvedCustomProperty = customProperties.find(matchField);

      return resolvedCustomProperty;
    })
    .filter((property): property is Property<PropertyTypeEnum> => !!property)
    .filter((property) => !UNSUPPORTED_PROPERTY_TYPES.includes(property.type));

  // Map property to logic fields
  const fieldsByEntities: LogicBuilderFieldByEntity = {
    case: [],
    mainCompany: [],
    individual: [],
    affiliatedCompany: [],
  };
  properties.forEach((property) => {
    const field = propertyToLogicBuilderField('local', property);

    const fields = match(property.mapping.entity)
      .with(PropertyMappingEntityEnum.case, () => fieldsByEntities.case)
      .with(PropertyMappingEntityEnum.company, () =>
        step.type === ClientPortalStepTypeEnum.affiliated_companies_edit
          ? fieldsByEntities.affiliatedCompany
          : fieldsByEntities.mainCompany,
      )
      .with(
        PropertyMappingEntityEnum.individual,
        () => fieldsByEntities.individual,
      )
      .exhaustive();

    fields.push(field);
  });

  return fieldsByEntities;
};

const propertyToLogicBuilderField = (
  context: `global.${'case' | 'main_company' | 'current_individual'}` | 'local',
  property: Property<PropertyTypeEnum>,
): QueryBuilderField => {
  return {
    name: `${context}.${generateKeyFromMapping(property.mapping)}`,
    label: property.label,

    type: property.type,
    values:
      property.settings &&
      'options' in property.settings &&
      property.settings.options
        ? property.settings.options.map((option) => ({
            label: option.label,
            value: option.key,
          }))
        : undefined,

    property,
  };
};

const mergeGlobalAndLocalFieldsByEntities = ({
  globalFields,
  localFields,
}: {
  globalFields: LogicBuilderFieldByEntity;
  localFields: LogicBuilderFieldByEntity;
}): LogicBuilderFieldByEntity => {
  return {
    case: mergeGlobalAndLocalFields({
      globalFields: globalFields.case,
      localFields: localFields.case,
    }),
    mainCompany: mergeGlobalAndLocalFields({
      globalFields: globalFields.mainCompany,
      localFields: localFields.mainCompany,
    }),
    individual: mergeGlobalAndLocalFields({
      globalFields: globalFields.individual,
      localFields: localFields.individual,
    }),
    affiliatedCompany: mergeGlobalAndLocalFields({
      globalFields: globalFields.affiliatedCompany,
      localFields: localFields.affiliatedCompany,
    }),
  };
};

const mergeGlobalAndLocalFields = ({
  globalFields,
  localFields,
}: {
  globalFields: QueryBuilderField[];
  localFields: QueryBuilderField[];
}): QueryBuilderField[] => {
  return [
    ...localFields,

    // Remove global fields that are also in local
    ...globalFields.filter((globalField) => {
      const localFieldName = globalField.name.split('.').at(-1);
      return !localFields.find(
        (localField) => localField.name.split('.').at(-1) === localFieldName,
      );
    }),
  ];
};
