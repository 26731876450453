import { Plus } from 'lucide-react';
import { useTranslation } from 'react-i18next';

import {
  Avatar,
  forwardRef,
  HStack,
  Icon,
  StackProps,
  Text,
} from '@dotfile/frontend/shared/design-system';
import { fullNameHelper } from '@dotfile/shared/domain';

import { FormDatastoreIndividual } from '../shared';

export type IndividualItemProps = {
  individual: Partial<FormDatastoreIndividual> | null | undefined;
} & StackProps;

export const IndividualItem = forwardRef(
  ({ individual, ...props }: IndividualItemProps, ref) => {
    const { t } = useTranslation();

    return (
      <HStack py="1" {...props} ref={ref}>
        {individual ? (
          <>
            <Avatar name={fullNameHelper(individual)} />
            <Text color="black" maxWidth="200px" noOfLines={1}>
              {fullNameHelper(individual)}
            </Text>
          </>
        ) : (
          <>
            <Icon as={Plus} />
            <Text color="black" maxWidth="200px" noOfLines={1}>
              {t(`forms.business_contact.select.add`, {
                ns: 'client-portal',
                defaultValue: 'Add new individual',
              })}
            </Text>
          </>
        )}
      </HStack>
    );
  },
);
