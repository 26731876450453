import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { GroupController } from '@dotfile/frontend/shared/components';
import {
  AbsoluteDatePicker,
  Grid,
  GridItem,
  Input,
  Text,
  useIsSmallScreen,
} from '@dotfile/frontend/shared/design-system';

import { UpdateReferenceDataFormValues } from '../hooks';

type UpdateReferenceDataControllersProps = {
  isBirthDateRequired: boolean;
};

/**
 * Similar to the component with the same name in id-document/
 */
export const UpdateReferenceDataControllers = ({
  isBirthDateRequired,
}: UpdateReferenceDataControllersProps) => {
  const { t } = useTranslation();
  const { control } = useFormContext<UpdateReferenceDataFormValues>();

  const isSmallScreen = useIsSmallScreen();
  return (
    <>
      <Text color="black">
        {t('checks.id_verification.update_reference_data.please_correct', {
          ns: 'client-portal',
          defaultValue: 'Please correct the individual information',
        })}
      </Text>

      <Grid templateColumns="repeat(2, 1fr)" gap={isSmallScreen ? '2' : '4'}>
        <GridItem colSpan={isSmallScreen ? 2 : 1}>
          <GroupController
            name="firstName"
            label={t(
              'checks.id_verification.update_reference_data.first_name',
              {
                defaultValue: 'First name',
                ns: 'client-portal',
              },
            )}
            isRequired
            control={control}
            render={(field) => <Input type="text" {...field} />}
          />
        </GridItem>

        <GridItem colSpan={isSmallScreen ? 2 : 1}>
          <GroupController
            name="lastName"
            label={t('checks.id_verification.update_reference_data.last_name', {
              defaultValue: 'Last name',
              ns: 'client-portal',
            })}
            isRequired
            control={control}
            render={(field) => <Input type="text" {...field} />}
          />
        </GridItem>

        <GridItem colSpan={isSmallScreen ? 2 : 1}>
          <GroupController
            name="birthDate"
            label={t(
              'checks.id_verification.update_reference_data.birth_date',
              {
                defaultValue: 'Birth date',
                ns: 'client-portal',
              },
            )}
            isRequired={isBirthDateRequired}
            control={control}
            render={(field) => <AbsoluteDatePicker width="100%" {...field} />}
          />
        </GridItem>
      </Grid>
    </>
  );
};
