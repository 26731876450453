import { PropsWithChildren } from 'react';

import { useInitDatadogRUM } from '@dotfile/frontend/shared/common';

export const InitDatadogRum = ({ children }: PropsWithChildren) => {
  // Should be done as soon as possible to not miss error but requires the EnvironmentContext & React Query Context (QueryClientProvider)
  useInitDatadogRUM('client-portal-app');

  return children;
};
