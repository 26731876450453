import { ArrowRight } from 'lucide-react';
import { useMemo } from 'react';
import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { match } from 'ts-pattern';

import { GroupController } from '@dotfile/frontend/shared/components';
import {
  Button,
  CountrySelect,
  Grid,
  GridItem,
  Icon,
  Input,
  useIsSmallScreen,
} from '@dotfile/frontend/shared/design-system';
import {
  ClientPortalForms_Step,
  CompanyTypeEnum,
} from '@dotfile/shared/data-access-client-portal';
import {
  companyDefaultPropertiesDefinition,
  COUNTRIES,
  generateKeyFromMapping,
} from '@dotfile/shared/domain';

import { FullContainer } from '../../../shared';
import { StepFooter, StepHeader, useFormDatastore } from '../shared';
import { useCompanySearchForm } from './hooks';

export const CompanySearchStepSearchForm = ({
  step,
  onSubmit,
}: {
  step: ClientPortalForms_Step;
  onSubmit: (param: {
    country: string;
    registrationNumber?: string;
    name?: string;
  }) => void;
}) => {
  const isSmallScreen = useIsSmallScreen();
  const { t } = useTranslation();
  const { patchMainCompanyData } = useFormDatastore();

  const { methods, searchType, setSearchType } = useCompanySearchForm();

  const { handleSubmit, control, setValue, trigger } = methods;

  const countryOptions = useMemo(
    () =>
      COUNTRIES.filter(
        ({ code }) =>
          !step.config.authorizedCountries ||
          step.config.authorizedCountries.includes(code),
      ).map(({ name, emoji, code }) => ({
        label: `${emoji} ${name}`,
        value: code,
      })),
    [step.config.authorizedCountries],
  );

  return (
    <FullContainer
      as="form"
      noValidate
      onSubmit={handleSubmit(async (formValues) => {
        const isValid = await trigger();

        const country = formValues.country;
        if (isValid && country && formValues[searchType]) {
          patchMainCompanyData({
            ...formValues,
            country,
            type: CompanyTypeEnum.main,
          });

          onSubmit({
            country,
            [searchType]: formValues[searchType],
          });
        }
      })}
      footer={
        <StepFooter
          nextLabel={t('forms.company_search.search_your_company', {
            defaultValue: 'Search your company',
            ns: 'client-portal',
          })}
          // No save for later on company_search
        />
      }
    >
      <StepHeader />

      <FormProvider {...methods}>
        <Grid
          templateColumns={isSmallScreen ? 'repeat(1, 1fr)' : 'repeat(3, 1fr)'}
          gap={isSmallScreen ? '2' : '4'}
        >
          <GridItem>
            <GroupController
              isRequired
              name="country"
              label={t(
                // @NOTE Use the same translation label as the company_edit step for now
                `steps.company_edit.blocks.${generateKeyFromMapping(companyDefaultPropertiesDefinition.country.mapping)}.label`,
                {
                  ns: 'dynamic',
                  defaultValue: 'Country',
                },
              )}
              control={control}
              render={({ value, ...field }) => (
                <CountrySelect
                  {...field}
                  isMulti={false}
                  required={false}
                  options={countryOptions}
                  placeholder={t('forms.field.countries.placeholder', {
                    ns: 'client-portal',
                    defaultValue: 'Select ...',
                  })}
                  onChange={(value) => {
                    setValue('country', value?.value ?? '', {
                      shouldDirty: true,
                      shouldTouch: true,
                      shouldValidate: true,
                    });
                  }}
                  defaultValue={(value as string) ?? undefined}
                  isClearable={false}
                />
              )}
            />
          </GridItem>
          <GridItem colSpan={2}>
            {match(searchType)
              .with('name', () => (
                <>
                  <GroupController
                    isRequired
                    key="name"
                    name="name"
                    label={t(
                      // @NOTE Use the same translation label as the company_edit step for now
                      `steps.company_edit.blocks.${generateKeyFromMapping(companyDefaultPropertiesDefinition.name.mapping)}.label`,
                      {
                        ns: 'dynamic',
                        defaultValue: 'Name',
                      },
                    )}
                    control={control}
                    render={(field) => <Input {...field} required={false} />}
                  />
                  <Button
                    mt="2"
                    variant="ghost"
                    rightIcon={<Icon as={ArrowRight} />}
                    onClick={() => {
                      setSearchType('registrationNumber');
                    }}
                  >
                    {t('forms.company_search.search_by_registration_number', {
                      defaultValue: `Search by registration number`,
                      ns: 'client-portal',
                    })}
                  </Button>
                </>
              ))
              .with('registrationNumber', () => (
                <>
                  <GroupController
                    key="registrationNumber"
                    isRequired
                    name="registrationNumber"
                    label={t(
                      // @NOTE Use the same translation label as the company_edit step for now
                      `steps.company_edit.blocks.${generateKeyFromMapping(companyDefaultPropertiesDefinition.registrationNumber.mapping)}.label`,
                      {
                        ns: 'dynamic',
                        defaultValue: 'Registration number',
                      },
                    )}
                    control={control}
                    render={(field) => <Input {...field} required={false} />}
                  />
                  <Button
                    mt="2"
                    variant="ghost"
                    rightIcon={<Icon as={ArrowRight} />}
                    onClick={() => {
                      setSearchType('name');
                    }}
                  >
                    {t('forms.company_search.search_by_name', {
                      defaultValue: `Search by company name`,
                      ns: 'client-portal',
                    })}
                  </Button>
                </>
              ))
              .exhaustive()}
          </GridItem>
        </Grid>
      </FormProvider>
    </FullContainer>
  );
};
