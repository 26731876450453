import 'react-datepicker/dist/react-datepicker.css';

import styled from '@emotion/styled';

export const OverrideStyle = styled.div/* css */ `
  .react-datepicker {
    border: 0px;
  }
  &.notInline {
    .react-datepicker {
      border: 1px solid var(--chakra-colors-gray-200);
      border-radius: var(--chakra-radii-md);
      .react-datepicker__triangle {
        transform: translate3d(40px, 0px, 0px) !important;
        &:after {
          border-bottom-color: white;
        }
        &:before {
          border-bottom-color: var(--chakra-colors-gray-200);
        }
      }
    }
  }
  .react-datepicker-wrapper,
  .react-datepicker__input-container {
    display: block;
  }

  .react-datepicker__navigation--next--with-time:not(
      .react-datepicker__navigation--next--with-today-button
    ) {
    right: 90px;
  }

  .react-datepicker__day--keyboard-selected {
    background-color: transparent;
  }

  .react-datepicker__day--today {
    background-color: var(--chakra-colors-gray-200);
    font-weight: normal;
  }

  .react-datepicker__navigation--previous,
  .react-datepicker__navigation--next {
    height: 40px;
  }

  .react-datepicker__navigation--previous {
    border-right-color: var(--chakra-colors-gray-800);
  }

  .react-datepicker__navigation--previous:hover {
    border-right-color: var(--chakra-colors-gray-900);
  }

  .react-datepicker__navigation--next {
    border-left-color: var(--chakra-colors-gray-800);
  }

  .react-datepicker__navigation--next:hover {
    border-left-color: var(--chakra-colors-gray-900);
  }

  .react-datepicker__header {
    background-color: transparent;
    border-bottom: transparent;
  }

  .react-datepicker__header,
  .react-datepicker__time-container {
    border-color: var(--chakra-colors-gray-700);
  }

  .react-datepicker__current-month,
  .react-datepicker-time__header,
  .react-datepicker-year-header {
    font-size: inherit;
    font-weight: 600;
    color: var(--text);
  }

  .react-datepicker__month {
    background-color: var(--chakra-colors-white);
    margin: 0;
    padding: 0.4rem;
  }

  .react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list
    li.react-datepicker__time-list-item {
    margin: 0 1px 0 0;
    height: auto;
    padding: 7px 10px;
  }

  .react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list
    li.react-datepicker__time-list-item:hover {
    background: var(--chakra-colors-gray-300);
  }

  .react-datepicker__day {
    color: var(--text);
  }

  .react-datepicker__day {
    border: 1px solid transparent;
    border-radius: 50%;
    width: 28px;
    height: 28px;
    &:hover {
      border: 1px solid var(--chakra-colors-gray-900);
      background-color: transparent;
    }
  }

  .react-datepicker__day-name {
    color: var(--chakra-colors-gray-300);
  }

  .react-datepicker__day--in-range,
  .react-datepicker__day--in-selecting-range:hover,
  .react-datepicker__day--in-selecting-range {
    background: var(--chakra-colors-gray-200);
    color: var(--chakra-colors-gray-900);
  }

  .react-datepicker__day--selected,
  .react-datepicker__month-text--selected,
  .react-datepicker__month-text--in-selecting-range,
  .react-datepicker__month-text--in-range,
  .react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list
    li.react-datepicker__time-list-item--selected,
  .react-datepicker__day--selecting-range-start.react-datepicker__day--range-start:hover,
  .react-datepicker__day.react-datepicker__day--in-selecting-range.react-datepicker__day--selecting-range-start {
    background: var(--chakra-colors-gray-900);
    font-weight: normal;
    color: var(--chakra-colors-white);
  }

  .react-datepicker__day.react-datepicker__day--keyboard-selected {
    border: 1px solid var(--chakra-colors-gray-900);
  }

  .react-datepicker__day--selected:hover,
  .react-datepicker__day--range-start,
  .react-datepicker__day--range-end,
  .react-datepicker__day--in-range:hover {
    color: var(--chakra-colors-white);
    background: var(--chakra-colors-gray-900);
  }

  .react-datepicker__day--excluded,
  .react-datepicker__day--excluded:hover {
    cursor: not-allowed;
    border: none;
    color: var(--chakra-colors-gray-300);
  }

  .react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list
    li.react-datepicker__time-list-item--selected:hover {
    background: var(--chakra-colors-gray-900);
  }

  /** @doc https://github.com/Hacker0x01/react-datepicker/blob/main/src/stylesheets/datepicker.scss#L629 */
  .react-datepicker__close-icon::after {
    background-color: unset;
    border-radius: unset;
    color: var(--chakra-colors-gray-600);
    width: 18px;
    height: 18px;
    /** @doc https://lucide.dev/icons/x Copy Data URL + remove hardcoded width and height */
    content: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgc3Ryb2tlPSJjdXJyZW50Q29sb3IiIHN0cm9rZS13aWR0aD0iMiIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIiBjbGFzcz0ibHVjaWRlIGx1Y2lkZS14Ij48cGF0aCBkPSJNMTggNiA2IDE4Ii8+PHBhdGggZD0ibTYgNiAxMiAxMiIvPjwvc3ZnPg==');
  }

  .react-datepicker__close-icon::after:hover {
    color: var(--chakra-colors-gray-600);
  }

  .react-datepicker__day--outside-month {
    color: var(--chakra-colors-gray-600);
  }
`;
