import { SendIcon } from 'lucide-react';
import { useTranslation } from 'react-i18next';

import {
  Alert,
  AlertIcon,
  Button,
  Icon,
  Text,
  VStack,
} from '@dotfile/frontend/shared/design-system';
import {
  CheckModel,
  CheckResultEnum,
  CheckStatusEnum,
  CheckTypeEnum,
  DocumentAnalysisEntity,
} from '@dotfile/shared/domain';

import { DocumentCheckAnalysisDetailedResults } from './analysis-detailed-results';
import { DocumentAnalysisData } from './types';

export const DocumentAnalysisReport = ({
  check,
  checkData,
  entity,
  onClose,
  onForceReview: _onForceReview,
  loadingSubmit = false,
  loadingForceReview = false,
}: {
  loadingSubmit?: boolean;
  check: Pick<CheckModel, 'id' | 'type' | 'status'>;
  checkData: DocumentAnalysisData;
  entity: Partial<
    DocumentAnalysisEntity['company'] | DocumentAnalysisEntity['individual']
  >;
  onClose: () => void;
  onForceReview: (check: Pick<CheckModel, 'id'>) => Promise<boolean>;
  loadingForceReview: boolean;
}) => {
  const { t } = useTranslation();

  const onForceReview = async () => {
    const success = await _onForceReview({ id: check.id });
    if (success) {
      onClose();
    }
  };

  if (
    check.type !== CheckTypeEnum.document ||
    !(
      [
        CheckStatusEnum.in_progress,
        CheckStatusEnum.rejected,
        // @TODO - E-2270 - Add @total-typescript/ts-reset
        // `as` should not be necessary anymore
      ] as CheckStatusEnum[]
    ).includes(check.status) ||
    !checkData?.detailedResults?.documentAnalysis
  ) {
    return null;
  }

  const includeErrorOrRejected = Object.values(
    checkData.detailedResults.documentAnalysis,
  ).some(({ result }) =>
    [CheckResultEnum.error, CheckResultEnum.rejected].includes(result),
  );

  if (!includeErrorOrRejected) return null;

  return (
    <VStack mb="4" spacing="4" align="flex-start">
      <Alert status="error">
        <AlertIcon />
        <Text color="red.700">
          {t('document_analysis.invalid_alert', {
            ns: 'components',
            defaultValue:
              'Your document may not fully align with our guidelines',
          })}
        </Text>
      </Alert>
      <DocumentCheckAnalysisDetailedResults
        documentData={checkData}
        entity={entity}
        hideApproved
      />
      {check.status === CheckStatusEnum.in_progress && (
        <Button
          size="md"
          isLoading={loadingForceReview || loadingSubmit}
          onClick={() => onForceReview()}
          leftIcon={<Icon as={SendIcon} />}
          variant="outline"
        >
          {t('document_analysis.force_review', {
            ns: 'components',
            defaultValue: 'Send (risk of rejection)',
          })}
        </Button>
      )}
    </VStack>
  );
};
