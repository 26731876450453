import { GroupBase, SelectInstance } from 'chakra-react-select';
import { has } from 'lodash';
import { Ref, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Text } from '@chakra-ui/react';

import {
  COUNTRIES,
  generateEntityLegalFormOptions,
  GENERIC_DEFAULT_COUNTRY_CODE,
} from '@dotfile/shared/domain';

import { forwardRefWithGeneric } from '../../utils/forward-ref-with-generic';
import { GenericSelect, Option, SelectProps } from '../select';
import { EntityLegalFormOption } from './entity-legal-form-option';

export type EntityLegalFormSelectProps<IsMulti extends boolean = false> = Omit<
  SelectProps<IsMulti>,
  'defaultValue' | 'value'
> & { defaultValue?: string | string[]; value?: string | string[] } & {
  country?: string | null;
  subdivision?: string | null;
};

export const EntityLegalFormSelect = forwardRefWithGeneric(
  function EntityLegalFormSelectWithRef<IsMulti extends boolean = false>(
    { ...props }: EntityLegalFormSelectProps<IsMulti>,
    ref: Ref<SelectInstance<Option, IsMulti>>,
  ): JSX.Element {
    const { t } = useTranslation();
    const {
      placeholder,
      options,
      defaultValue,
      value,
      country,
      subdivision,
      ...rest
    } = props;

    const resolvedOptions = useMemo(
      () =>
        options && options.length
          ? options
          : generateEntityLegalFormOptions(country, subdivision, { t }).options,
      [options, country, subdivision, t],
    );

    const hasGroup = useMemo(
      () =>
        resolvedOptions &&
        resolvedOptions[0] &&
        has(resolvedOptions[0], 'options'),
      [resolvedOptions],
    );

    const resolvedDefaultValue = useMemo(() => {
      const options = hasGroup
        ? // @NOTE We know from hasGroup that it contains options
          (
            resolvedOptions as unknown as {
              label: string;
              options: { label: string; value: string };
            }[]
          ).flatMap((o) => o.options)
        : resolvedOptions;

      if (typeof defaultValue === 'string') {
        if (defaultValue === '') return null;
        return options.find(
          (o): o is Option => 'value' in o && o.value === defaultValue,
        );
      } else if (Array.isArray(defaultValue)) {
        return options.filter(
          (o): o is Option => 'value' in o && defaultValue.includes(o.value),
        );
      } else {
        return defaultValue;
      }
    }, [defaultValue, resolvedOptions, hasGroup]);

    const resolvedValue = useMemo(() => {
      const options = hasGroup
        ? // @NOTE We know from hasGroup that it contains options
          (
            resolvedOptions as unknown as {
              label: string;
              options: { label: string; value: string };
            }[]
          ).flatMap((o) => o.options)
        : resolvedOptions;
      if (typeof value === 'string') {
        if (value === '') return null;
        return options.find(
          (o): o is Option => 'value' in o && o.value === value,
        );
      } else if (Array.isArray(value)) {
        return options.filter(
          (o): o is Option => 'value' in o && value.includes(o.value),
        );
      } else {
        return value;
      }
    }, [value, resolvedOptions, hasGroup]);

    return (
      <GenericSelect<Option, IsMulti, GroupBase<Option>>
        components={{
          // This really improve performance as option don't rerender on hover anymore
          Option: EntityLegalFormOption,
        }}
        isClearable
        isSearchable
        options={resolvedOptions}
        filterOption={
          !country && !subdivision
            ? (option, inputValue) => {
                if (!inputValue) return false;
                if (inputValue.length < 2) return false;
                return option.label
                  .toLowerCase()
                  .includes(inputValue.toLowerCase());
              }
            : undefined
        }
        formatGroupLabel={({ label }) => {
          const countryDefinition = COUNTRIES.find(
            ({ code }) => code === label,
          );

          return (
            <Text color="black" fontWeight="bold">
              {countryDefinition
                ? `${countryDefinition.emoji} ${countryDefinition.name}`
                : label === GENERIC_DEFAULT_COUNTRY_CODE
                  ? t('entity_legal_form.other', {
                      ns: 'domain',
                      defaultValue: 'Other',
                    })
                  : label}
              {
                // @TODO - E-4192 - Country subdivision / Entity legal form
                // Properly format the subdivision
              }
            </Text>
          );
        }}
        defaultValue={resolvedDefaultValue}
        value={resolvedValue}
        {...rest}
      />
    );
  },
);
