import { PlusIcon } from 'lucide-react';
import { useCallback, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Icon } from '@dotfile/frontend/shared/design-system';

import { FullContainer } from '../../../shared';
import {
  affiliatedCompaniesSelector,
  StepFooter,
  StepHeader,
  StepProps,
  useFormDatastore,
  useFormDrawerState,
  useHandleSaveForLater,
  useIsFieldsFormValid,
} from '../shared';
import { AffiliatedCompanyFormDrawer } from './affiliated-company-form-drawer';
import { AffiliatedCompanyItem } from './affiliated-company-item';

export const AffiliatedCompaniesEditStep = ({
  step,
  onSubmitStep,
}: StepProps) => {
  const { t } = useTranslation();

  const affiliatedCompanies = useFormDatastore(affiliatedCompaniesSelector);
  const drawerState = useFormDrawerState();

  const [submitAttempted, setSubmitAttempted] = useState(false);
  const firstInvalidAffiliatedCompanyRef = useRef<HTMLDivElement>(null);

  const isFieldsFormValidForAffiliated = useIsFieldsFormValid(step);
  const invalidIndexes = useMemo(() => {
    const invalidIndexes: number[] = [];
    affiliatedCompanies?.forEach((affiliatedCompany, index) => {
      const isValid = isFieldsFormValidForAffiliated({
        case: undefined,
        company: affiliatedCompany, // Only company fields can be added on this step
      });
      if (!isValid) {
        invalidIndexes.push(index);
      }
    });
    return invalidIndexes;
  }, [affiliatedCompanies, isFieldsFormValidForAffiliated]);

  const validateSubmit = useCallback(() => {
    setSubmitAttempted(true);

    if (invalidIndexes.length > 0) {
      firstInvalidAffiliatedCompanyRef.current?.scrollIntoView?.({
        behavior: 'smooth',
        block: 'start',
      });
      return false;
    } else {
      return true;
    }
  }, [invalidIndexes.length]);
  const handleNext = useCallback(() => {
    if (validateSubmit()) {
      onSubmitStep();
    }
  }, [validateSubmit, onSubmitStep]);

  const [handleSaveForLater, saveForLaterState] = useHandleSaveForLater();
  const handleSave = useCallback(() => {
    if (validateSubmit()) {
      handleSaveForLater();
    }
  }, [validateSubmit, handleSaveForLater]);

  return (
    <FullContainer
      footer={
        <StepFooter
          onNext={handleNext}
          saveForLaterState={saveForLaterState}
          onSaveForLater={handleSave}
        />
      }
    >
      <StepHeader />

      {affiliatedCompanies?.map((affiliatedCompany, index) => {
        const indexInInvalidIndexes = invalidIndexes.indexOf(index);
        const hasMissingInformation = indexInInvalidIndexes !== -1;
        return (
          <AffiliatedCompanyItem
            key={index}
            index={index}
            affiliatedCompany={affiliatedCompany}
            onEdit={() => drawerState.handleEdit(index)}
            hasMissingInformation={hasMissingInformation}
            borderColor={
              submitAttempted && hasMissingInformation
                ? 'orange.700'
                : undefined
            }
            ref={
              indexInInvalidIndexes === 0
                ? firstInvalidAffiliatedCompanyRef
                : undefined
            }
          />
        );
      })}

      <AffiliatedCompanyFormDrawer
        isOpen={drawerState.isOpen}
        onClose={drawerState.handleClose}
        step={step}
        selectedAffiliatedCompanyIndex={drawerState.selectedIndex}
      />

      <Button
        alignSelf="start"
        leftIcon={<Icon as={PlusIcon} />}
        variant="outline"
        colorScheme="coal"
        onClick={drawerState.handleAdd}
      >
        {t('forms.affiliated_companies_edit.add_affiliated_company', {
          ns: 'client-portal',
          defaultValue: 'Add affiliated company',
        })}
      </Button>
    </FullContainer>
  );
};
