import { useEffect, useRef, useState } from 'react';

import {
  removeTypenameFromData,
  useAuth,
} from '@dotfile/frontend/shared/common';
import { CompanyTypeEnum } from '@dotfile/shared/data-access-client-portal';

import { ContactAuthContext } from '../../../../shared';
import { useFormDatastoreApi } from '../context';
import { initialCustomPropertiesValue } from '../helpers';
import { useCaseForms } from './use-case-forms';

export const useCaseDataInit = () => {
  const {
    auth: { caseId },
  } = useAuth<ContactAuthContext>();
  const caseQuery = useCaseForms();

  /**
   * @NOTE:
   * Relying on caseQuery.loading only create a race condition when displaying form values
   * At some point caseQuery is no more loading, so the form render before the useEffect bellow is actually setting the state from the query.
   * We manually control the loading state to avoid that.
   */
  const [loading, setLoading] = useState(!!caseId);

  const storeApi = useFormDatastoreApi();

  const isInitialized = useRef(false);
  useEffect(() => {
    if (caseQuery.data?.case) {
      if (isInitialized.current) {
        // Avoid re-initializing the store if it was already done because
        // this clear the local storage and reset the progress current step
        return;
      } else {
        isInitialized.current = true;
      }

      const {
        patchMainCompanyData,
        patchCaseData,
        patchIndividualData,
        patchAffiliatedCompanyData,
        reset,
      } = storeApi.getState();

      const {
        companies,
        individuals,
        customPropertyValues: caseCustomPropertyValues,
        ...caseData
      } = removeTypenameFromData(caseQuery.data.case);

      // Reset store state (progress & data)
      // We will always start from the first step when resume form (even if you have local storage session)
      // If you refresh, it will reload the case and also start from the first step (helpful when something has changed form anywhere else: external API, console)
      reset();

      // Case
      patchCaseData({
        ...caseData,
        customProperties: initialCustomPropertiesValue(
          caseCustomPropertyValues,
        ),
      });

      // Company
      const mainCompany = companies.find(
        (c) => c.type === CompanyTypeEnum.main,
      );
      if (mainCompany) {
        const { customPropertyValues, ...mainCompanyData } = mainCompany;
        patchMainCompanyData({
          ...mainCompanyData,
          customProperties: initialCustomPropertiesValue(customPropertyValues),
        });
      }
      companies
        .filter((c) => c.type === CompanyTypeEnum.affiliated)
        .forEach((affiliatedCompany, index) => {
          const { customPropertyValues, ...affiliatedCompanyData } =
            affiliatedCompany;
          patchAffiliatedCompanyData(
            {
              ...affiliatedCompanyData,
              customProperties:
                initialCustomPropertiesValue(customPropertyValues),
            },
            index,
          );
        });

      // Individuals
      individuals.forEach((individual, index) => {
        const { customPropertyValues, ...individualData } = individual;
        patchIndividualData(
          {
            ...individualData,
            customProperties:
              initialCustomPropertiesValue(customPropertyValues),
          },
          index,
        );
      });

      setLoading(false);
    }
  }, [caseQuery.data?.case, storeApi]);

  return { loading };
};
