import { useTranslation } from 'react-i18next';

import { HStack } from '@dotfile/frontend/shared/design-system';
import { FileFragment } from '@dotfile/shared/data-access-client-portal';

import { DownloadButton } from '../../shared/components/download-button';

type DownloadFilesProps = {
  frontFile?: FileFragment | null;
  backFile?: FileFragment | null;
};

export const DownloadFiles = ({ frontFile, backFile }: DownloadFilesProps) => {
  const { t } = useTranslation();

  if (!frontFile) {
    // Back is always optional
    return null;
  }

  // For ID Verification, the file names are hard-coded to be the snake-case version of their file target
  // se we expose a more user-friendly name instead

  const front = {
    ...frontFile,
    name: `${t('checks.id_verification.update_reference_data.front_file_name', {
      ns: 'client-portal',
      defaultValue: 'front.{{extension}}',
      extension: frontFile.name.split('.').at(-1),
    })}`,
  };
  const back = backFile
    ? {
        ...backFile,
        name: `${t(
          'checks.id_verification.update_reference_data.back_file_name',
          {
            ns: 'client-portal',
            defaultValue: 'back.{{extension}}',
            extension: backFile.name.split('.').at(-1),
          },
        )}`,
      }
    : backFile;

  return (
    <HStack spacing="4">
      {front && <DownloadButton file={front} />}

      {back && <DownloadButton file={back} />}
    </HStack>
  );
};
